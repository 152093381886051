import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Races, RacesDealers, RacesFiles, RacesPagination} from '@models/races';
import {map} from 'rxjs/operators';

const endpoint = 'races';

@Injectable({
    providedIn: 'root'
})
export class RacesService {

    constructor(private request: RequestService, private alertService: AlertService) {
    }

    getOverview(params?: object): Observable<any> {
        return this.request.get('races/progress', params);
    }
    getListOfItems(params?: object): Observable<RacesPagination> {
        return this.request.get(endpoint, params);
    }

    getTypes(params?: object): Observable<any> {
        return this.request.get(endpoint + '/type', params);
    }

    public putRaces(body: Races, id): Observable<Races> {
        return this.request.put(endpoint + '/' + id, body).pipe(
            map((response: any) => {
                return new Races(response);
            })
        );
    }

    public postRacesFiles(body: RacesFiles, id): Observable<RacesFiles> {
        return this.request.post(endpoint + '/' + id + '/add-file', body);
    }

    public postRacesDealers(body: RacesDealers): Observable<RacesDealers> {
        return this.request.post(endpoint + '/' + 'add-dealer', body);
    }

    public postRaces(body: Races): Observable<Races> {
        return this.request.post(endpoint, body).pipe(
            map((response: any) => {
                return new Races(response);
            })
        );
    }

    public uploadFormFile(body: Races): Observable<any> {
        return this.request.post(endpoint + '/upload-file', body.postPayload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteRaces(id: number): Observable<any> {
        return this.request.delete(endpoint + '/' + id).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public deleteRacesFiles(id: number): Observable<any> {
        return this.request.delete(endpoint + '/files/' + id);
    }
    public deleteRacesDealers(id: number) {
        return this.request.delete(`${endpoint}/dealers/${id}`);
    }

    public successAlert(text) {
        this.alertService.show(
            '',
            'Successfully ' + text,
            {classname: 'bg-success text-light', delay: 2000}
        );
    }

    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }

    downloadDetail(params: {}) {
        return this.request.get(`${endpoint}/excel-detail`, params, { responseType: 'blob' });
    }
}
