<div class="row">
    <div class="col-12 col-lg-6 col-xl-4">
        <app-master-card [height]="null" title="Vendite" image="assets/img/flats/diagram.png"
                         bodyClass="{{this.background}} px-1">
            <app-autoload-chartjs type="line" [brand]="brand" [url]="urlVendite"></app-autoload-chartjs>
        </app-master-card>
        <app-master-card [height]="null" title="Targets" image="assets/img/flats/target.png">
            <app-autoload-target [brand]="brand" [urlTarget]="urlTarget"></app-autoload-target>
        </app-master-card>
    </div>
    <div class="col-12 col-lg-6 col-xl-4">
        <app-master-card [height]="null" title="Riepilogo" image="assets/img/flats/folder.png">
            <app-autoload-riepilogo [brand]="brand" [urlRiepilogo]="urlRiepilogo"></app-autoload-riepilogo>
        </app-master-card>

        <app-master-card [height]="null" title="Riepilogo Cluster Comuni" image="assets/img/flats/folder.png">
            <app-autoload-cluster-comuni [brand]="brand" [urlClusterComuni]="urlClusterComuni"></app-autoload-cluster-comuni>
        </app-master-card>

<!--        <app-master-card [height]="null" title="Fatturato" image="assets/img/flats/profit.png" *show="['master']">-->
<!--            <app-autoload-fatturato [brand]="brand" [urlFatturato]="urlFatturato"></app-autoload-fatturato>-->
<!--        </app-master-card>-->
    </div>
    <div class="col-12 col-lg-6 col-xl-4">
        <app-master-card [height]="null" title="Punti Vendita" image="assets/img/flats/global-network.png">
            <app-autoload-punti-vendita [brand]="brand" [urlPuntiVendita]="urlPuntiVendita"></app-autoload-punti-vendita>
        </app-master-card>

        <app-master-card [height]="null" title="Offerte" image="assets/img/flats/pie-chart-2.png">
            <app-autoload-offerte [brand]="brand" [urlOfferte]="urlOfferte"></app-autoload-offerte>
        </app-master-card>
    </div>
</div>
