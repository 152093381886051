import { Injectable } from '@angular/core';
import {RequestService} from '@services/request.service';

@Injectable({
  providedIn: 'root'
})
export class KpiService {
  ENDPOINT = 'kpi';

  constructor(public request: RequestService) {

  }

  overview(payload) {
      return this.request.post(`${this.ENDPOINT}/overview`, payload);
  }

  salesAgentTable(id, operatore, periodo, type) {
    return this.request.get(`${this.ENDPOINT}/agente`, {
      id, operatore, periodo, type
    });
  }

  saveTarget(value: any) {
    return this.request.post(`${this.ENDPOINT}/save-target`, value);
  }
}
