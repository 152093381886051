<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 src="assets/img/flats/placeholder.png"
                 width="160px">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>Analisi Aperture</h1>
                <hr>
                <div class="form-row align-items-end">
                    <div class="col-lg-3 col-12"><label translate>Operatore</label>
                        <app-vendor-select [outputLabel]="true" [pickFirst]="true" (onChange)="setVendor($event)"></app-vendor-select>
                    </div>
                    <div class="col-12 col-xl-3">
                        <label>Seleziona periodo</label>
                        <div class="form-group form-group-icon form-group-dark mb-0">
                            <i class="far fa-calendar-alt"></i>
                            <select class="form-control" [(ngModel)]="this.filter.period">
                                <ng-container>
                                    <option *ngFor="let per of listaPeriodi" [value]="per.value">{{per.label}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                    <ng-container *show="['master', 'operator', 'submaster']">
                        <div class="col-lg-3 col-12" *ngIf="currentTab == 4"><label translate>Master Agent</label>
                            <app-master-agent-select (onChange)="setMasterAgent($event)"></app-master-agent-select>
                        </div>
                    </ng-container>
                    <ng-container *show="['master', 'operator', 'submaster', 'masteragent']">
                        <div class="col-lg-3 col-12" *ngIf="currentTab == 4"><label translate>Agent</label>
                            <app-agent-select [masteragentId]="filter.masteragent_id" #agentSelect (onChange)="filter.agent_id = $event"></app-agent-select>
                        </div>
                    </ng-container>
                    <div class="col-lg-3 col-12"><label translate>Sottocanale</label>
                        <app-channel-select (onChange)="this.filter.channel = $event;"></app-channel-select>
                    </div>
                    <div class="col-lg-3 col-12 mt-2">
                        <button class="btn btn-light btn-block"
                                translate
                                (click)="load()"
                                type="submit">Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-master-subheader cardClass="bg-gradient-1" [free]="true" [url]="urlSubheader"></app-master-subheader>

<ul ngbNav #nav="ngbNav" class="nav-tabs mg-tabs" [(activeId)]="currentTab">
    <li ngbNavItem [ngbNavItem]="1">
        <a ngbNavLink translate="">Nuove Affiliazioni</a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    title="Nuove Affiliazioni"
                    image="assets/img/flats/ribbon.png"
            >
                <data-builder-table [url]="urlNegoziTabella"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li ngbNavItem *show="['master', 'masteragent', 'operator', 'agent']" [ngbNavItem]="2">
        <a ngbNavLink>Classifica per <span translate>Key Account</span></a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    title="Classifica Key Account"
                    image="assets/img/flats/employee.png"
            >
                <data-builder-table [url]="urlAgenti"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li ngbNavItem *show="['master', 'masteragent', 'operator']" [ngbNavItem]="3">
        <a ngbNavLink>Classifica per <span translate>Area Manager</span></a>
        <ng-template ngbNavContent>
            <app-master-card
                    bodyClass="p-0"
                    title="Classifica Area Manager"
                    image="assets/img/flats/global-network.png"
            >
                <data-builder-table [url]="urlRegioni"></data-builder-table>
            </app-master-card>
        </ng-template>
    </li>
    <li ngbNavItem *show="['master', 'operator', 'masteragent']" [ngbNavItem]="4">
        <a ngbNavLink>Trend</a>
        <ng-template ngbNavContent>
            <div class="mb-2">
                <button [ladda]="laddaTrendDaily" class="btn btn-sm btn-success" (click)="downloadDailyTrend()"><i class="fas fa-download"></i> Trend Giornaliero</button>
                <button [ladda]="laddaTrendMonthly" class="btn btn-sm btn-success ml-2" (click)="downloadMonthlyTrend()"><i class="fas fa-download"></i> Trend Mensile</button>
            </div>

            <app-master-card
                    bodyClass="p-0"
                    [height]="null"
                    title="Trend Aperture"
                    image="assets/img/flats/global-network.png"
            >
                <app-autoload-chartjs type="line" brand="v2"
                                      [url]="urlTrend"></app-autoload-chartjs>
            </app-master-card>

            <div class="card bg-gradient-light">
                <div class="card-header">
                    <app-month-range-picker
                            [defaultMonthRange]="[chartPeriod.from, chartPeriod.to]"
                            (selected)="
                                this.updateChart($event);
                                this.eoloChart.setBrand('v2');
                                this.load()"
                            id="date"></app-month-range-picker>
                </div>
                <div class="card-body p-1">
                    <app-autoload-chartjs #eoloChart
                                          [url]="'/openings/trend-range?vendor='+this.filter.vendor+'&from='+chartPeriod.from+'&to='+chartPeriod.to+'&channel='+this.filter.channel"
                                          brand="v2" type="line"></app-autoload-chartjs>
                </div>
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>