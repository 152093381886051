import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "@app/services/dashboard.service";
import { DealerService } from "@app/services/dealer.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
  selector: "app-district-detail",
  templateUrl: "./district-detail.component.html",
  styleUrls: ["./district-detail.component.css"],
})
export class DistrictDetailComponent implements OnInit {
  @ViewChild("provinceTemplate") private provinceTemplate;
  @ViewChild("confirmDeleteModal") confirmDeleteModal: TemplateRef<any>;
  modalRef: NgbModalRef;

  data: any;
  busy: Subscription;
  id: string;
  regionals: any;

  edit = {
    username: false,
    telefono: false,
    cellulare: false,
    email: false,
    regional: false,
  };

  loading = {
    username: false,
    telefono: false,
    cellulare: false,
    email: false,
    regional: false,
  };

  private modal: NgbModalRef;
  selectedProvincia: any;
  deletingProvincia: any;

  province: any = null;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private router: Router,
    private dealerService: DealerService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.reload();
    });
  }

  get district() {
    if (this.data) {
      return this.data.district;
    }
    return {
      ragsociale: "",
    };
  }

  ngOnInit(): void {
    this.loadProvince();
  }

  openModal() {
    this.modalService.open(this.provinceTemplate, {
      size: "md",
    });
  }

  closeModal() {
    this.modalService.dismissAll();
    this.selectedProvincia = null;
    this.deletingProvincia = null;
  }

  saveProvince() {
    this.spinner.show("addProvincia");

    const payload = {
      id_provincia: this.selectedProvincia,
      id_agente: this.id,
    };

    this.dealerService.setProvincia(payload).subscribe(
      (res) => {
        this.spinner.hide("addProvincia");
        this.toastr.success(
          "Provincia aggiunta con successo!",
          "Operazione completata",
          { positionClass: "toast-top-right" }
        );
        this.loadProvince();
      },
      (error) => {
        this.spinner.hide("addProvincia");
        this.toastr.error(
          "Impossibile aggiungere la provincia, contattare il supporto",
          "Errore",
          { positionClass: "toast-top-right" }
        );
      }
    );

    this.closeModal();
  }

  changeProvince(event) {
    this.selectedProvincia = event;
  }

  saveDistrictInfo(params: any, key: string) {
    this.loading[key] = true;
    this.dealerService.updateDealerAnagrafica(this.id, params).subscribe(
      (data) => {
        this.toastr.success(
          "Dati modificati con successo!",
          "Operazione completata",
          { positionClass: "toast-top-right" }
        );
        this.loading[key] = false;
        this.edit[key] = false;
        this.reload();
      },
      (error) => {
        this.toastr.error(
          "Impossibile modificare i dati, contattare il supporto",
          "Errore",
          { positionClass: "toast-top-right" }
        );
        this.loading[key] = false;
        this.edit[key] = false;
        this.reload();
      }
    );
  }

  openConfirmDeleteModal(item) {
    this.modalRef = this.modalService.open(this.confirmDeleteModal, {
      centered: true,
      size: "sm",
    });
    this.deletingProvincia = item;
  }

  confirmDelete() {
    this.spinner.show("deleteModalSpinner");
    this.dealerService.deleteProvincia(this.deletingProvincia.id).subscribe(
      (res) => {
        this.toastr.success(
          "Provincia eliminata con successo!",
          "Operazione completata",
          { positionClass: "toast-top-right" }
        );
        this.spinner.hide("deleteModalSpinner");
        this.loadProvince();
        this.deletingProvincia = null;
      },
      (err) => {
        this.toastr.error(
          "Impossibile modificare i dati, contattare il supporto",
          "Errore",
          { positionClass: "toast-top-right" }
        );
      }
    );
    this.modalRef.close();
  }

  reload() {
    this.spinner.show("districtDetails");
    this.busy = this.dashboardService
      .get(`/district-dettaglio?id=${this.id}`)
      .subscribe(
        (data) => {
          this.data = data.result;
          this.spinner.hide("districtDetails");
        },
        (error) => {
          this.router.navigate(["/retailers/list"]);
        }
      );
  }

  loadProvince() {
    this.busy = this.dashboardService
      .get(`/district-province?id=${this.id}`)
      .subscribe(
        (data) => {
          this.province = data.result.length > 0 ? data.result : null;
        },
        (error) => {
          this.router.navigate(["/retailers/list"]);
        }
      );
  }
}
