import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "@app/services/dashboard.service";
import { DealerService } from "@app/services/dealer.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
  selector: "app-regional-detail",
  templateUrl: "./regional-detail.component.html",
  styleUrls: ["./regional-detail.component.css"],
})
export class RegionalDetailComponent implements OnInit {
  data: any;
  busy: Subscription;
  id: string;

  edit = {
    username: false,
    telefono: false,
    cellulare: false,
    email: false,
  };

  loading = {
    username: false,
    telefono: false,
    cellulare: false,
    email: false,
  };

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private router: Router,
    private dealerService: DealerService,
    private toastr: ToastrService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.reload(); // reset and set based on new parameter this time
    });
  }

  get regional() {
    if (this.data) {
      return this.data.regional;
    }
    return {
      ragsociale: "",
    };
  }

  ngOnInit(): void {}

  saveRegionalInfo(params: any, key: string) {
    this.loading[key] = true;
    this.dealerService.updateDealerAnagrafica(this.id, params).subscribe(
      (data) => {
        this.toastr.success(
          "Dati modificati con successo!",
          "Operazione completata",
          { positionClass: "toast-top-right" }
        );
        this.loading[key] = false;
        this.edit[key] = false;
        this.reload();
      },
      (error) => {
        this.toastr.error(
          "Impossibile modificare i dati, contattare il supporto",
          "Errore",
          { positionClass: "toast-top-right" }
        );
        this.loading[key] = false;
        this.edit[key] = false;
        this.reload();
      }
    );
  }

  reload() {
    this.spinner.show();
    this.busy = this.dashboardService
      .get(`/regional-dettaglio?id=${this.id}`)
      .subscribe(
        (data) => {
          this.data = data.result;
          this.spinner.hide();
        },
        (error) => {
          this.router.navigate(["/retailers/list"]);
        }
      );
  }
}
