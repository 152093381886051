import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "@app/services/dashboard.service";
import { DealerService } from "@app/services/dealer.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";

@Component({
  selector: "app-formazione-region",
  templateUrl: "./formazione-region.component.html",
  styleUrls: ["./formazione-region.component.css"],
})
export class FormazioneRegionComponent implements OnInit {
  id: string;
  busy: Subscription;
  data: any;
  modalRef: NgbModalRef;

  @ViewChild("regioneTemplate") private regioneTemplate;
  @ViewChild("confirmDeleteModal") confirmDeleteModal: TemplateRef<any>;

  regioni: any = null;
  deletingRegione: any;
  selectedRegione: any;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private modalService: NgbModal,
    private dealerService: DealerService,
    private toastr: ToastrService
  ) {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.reload();
    });
  }

  get operator() {
    if (this.data) {
      return this.data.operator;
    }
    return {
      ragsociale: "",
    };
  }

  ngOnInit(): void {
    this.loadRegioni();
  }

  reload() {
    this.spinner.show("userrDetails");
    this.busy = this.dashboardService
      .get(`/formazione-regioni?id=${this.id}`)
      .subscribe(
        (data) => {
          this.data = data.result;
          this.spinner.hide("userDetails");
        },
        (error) => {
          this.router.navigate(["/retailers/list"]);
        }
      );
  }

  openModal() {
    this.modalService.open(this.regioneTemplate, {
      size: "md",
    });
  }

  loadRegioni() {
    this.busy = this.dashboardService
      .get(`/formazione-regioni?id=${this.id}`)
      .subscribe(
        (data) => {
          this.regioni =
            data.result.operator.region_data.length > 0
              ? data.result.operator.region_data
              : null;
        },
        (error) => {
          this.router.navigate(["/retailers/list"]);
        }
      );
  }

  openConfirmDeleteModal(item) {
    this.modalRef = this.modalService.open(this.confirmDeleteModal, {
      centered: true,
      size: "sm",
    });
    this.deletingRegione = item;
  }

  closeModal() {
    this.modalService.dismissAll();
    this.selectedRegione = null;
    this.deletingRegione = null;
  }

  confirmDelete() {
    this.spinner.show("deleteModalSpinner");
    this.dealerService.deleteRegione(this.deletingRegione.id).subscribe(
      (res) => {
        this.toastr.success(
          "Regione eliminata con successo!",
          "Operazione completata",
          { positionClass: "toast-top-right" }
        );
        this.spinner.hide("deleteModalSpinner");
        this.loadRegioni();
        this.deletingRegione = null;
      },
      (err) => {
        this.toastr.error(
          "Impossibile modificare i dati, contattare il supporto",
          "Errore",
          { positionClass: "toast-top-right" }
        );
      }
    );
    this.modalRef.close();
  }

  changeRegione(event) {
    this.selectedRegione = event;
  }

  saveRegione() {
    this.spinner.show("addRegione");

    const payload = {
      id_regione: this.selectedRegione,
      id_dealer: this.id,
    };

    this.dealerService.setRegione(payload).subscribe(
      (res) => {
        this.spinner.hide("addRegione");
        this.toastr.success(
          "Regione aggiunta con successo!",
          "Operazione completata",
          { positionClass: "toast-top-right" }
        );
        this.loadRegioni();
      },
      (error) => {
        this.spinner.hide("addRegione");
        this.toastr.error(
          "Impossibile aggiungere la regione, contattare il supporto",
          "Errore",
          { positionClass: "toast-top-right" }
        );
      }
    );

    this.closeModal();
  }
}
