import {Component, OnInit, ViewChild} from '@angular/core';
import {AgentVisitsService} from '@services/agent-visits.service';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {MapTypeStyle} from '@agm/core/services/google-maps-types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '@environments/environment';

@Component({
    selector: 'app-agent-gps-tracking',
    templateUrl: './agent-gps-tracking.component.html',
    styleUrls: ['./agent-gps-tracking.component.css']
})
export class AgentGpsTrackingComponent implements OnInit {
    markers = [];
    shops = [];
    direction;
    filter = {
        vendor_id: '',
        date: '',
        dateOutput: '',
        from: '',
        to: '',
        masteragent_id: '',
        agent_id: '',
        shop: '',
        province_id: '',
        region_id: '',
        city_id: '',
        channel: '',
        cluster: '',
        type: 'Partner',
        functional: 'No'
    };
    rangeOptions = {
        singleDatePicker: true,
        ranges: []
    };
    apiDate = '';
    mapPinOptions = {
        url: 'assets/img/flats/pin.png',
        scaledSize: {
            width: 30,
            height: 45
        }
    };
    keys = [];
    defaultStyles: MapTypeStyle[] = [
        {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
                {visibility: 'off'}
            ]
        }
    ];
    public renderOptions = {
        suppressMarkers: true,
    };
    localFormat = 'DD/MM/YYYY HH:mm:ss';
    public markerOptions = {
        origin: {
            label: '',
            icon: ''
        },
        destination: {
            label: '',
            icon: ''
        },
        waypoints: []
    };
    localDatetimeFormat = 'DD/MM/YYYY HH:mm:ss';
    report;
    agentStops = [];
    agentStopsPagination;
    agentVisits = [];
    agentVisitsPagination;
    isSelectedAgent = false;
    lunchTime = 'lunch-time';
    nightTime = 'night-time';
    mode = 0;
    selectedVisit;
    baseAwsUrl = environment.baseAwsUrl;
    visitsData = [];
    tableUrl = null;
    mapAgentPinOptions = {
        url: 'assets/img/flats/pin-parking.png',
        scaledSize: {
            width: 40,
            height: 40
        }
    };
    mapPotentialPinOptions = {
        url: 'assets/img/flats/pin-yellow.png',
        scaledSize: {
            width: 40,
            height: 40
        }
    };
    @ViewChild('fileTemplate') private fileTemplate;

    constructor(
        private agentVisitsService: AgentVisitsService,
        private spinner: NgxSpinnerService,
        private dateRangePickerOptions: DaterangepickerConfig,
        private modalService: NgbModal) {
    }

    ngOnInit(): void {
        // this.selectedDate(moment());
        this.setSelectedDateRange(moment(), moment());
    }

    loadData() {
        this.isSelectedAgent = !!this.filter.agent_id;
        this.loadDashboard();
        this.loadAgentVisits();
    }

    loadDashboard() {
        this.spinner.show('dashboard');
        const params = {};
        if (this.filter.from) {
            params['from'] = this.filter.from;
        }
        if (this.filter.to) {
            params['to'] = this.filter.to;
        }
        if (this.filter.masteragent_id) {
            params['masteragent_id'] = this.filter.masteragent_id;
        }
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        if (this.filter.channel) {
            params['channel'] = this.filter.channel;
        }
        if (this.filter.cluster) {
            params['cluster'] = this.filter.cluster;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        if (this.filter.functional) {
            params['functional'] = this.filter.functional;
        }
        this.agentVisitsService.getDashboardReport(params).subscribe((response) => {
            this.report = response;
            this.spinner.hide('dashboard');
        }, err => {
            this.spinner.hide('dashboard');
        });
    }

    loadAgentStops() {
        this.spinner.show('table-stops');
        const params = {};
        if (this.agentStopsPagination?.currentPage) {
            params['page'] = this.agentStopsPagination?.currentPage;
        }
        if (this.filter.date && this.apiDate) {
            params['date'] = this.apiDate;
        }
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        this.agentVisitsService.getAgentStops(params).subscribe((response) => {
            this.agentStops = response.data;
            this.agentStopsPagination = response._meta;
            this.spinner.hide('table-stops');
        }, err => {
            this.spinner.hide('table-stops');
        });
    }

    loadAgentVisits() {
        this.spinner.show('table-visits');

        const params = {};
        if (this.agentVisitsPagination?.currentPage) {
            params['page'] = this.agentVisitsPagination?.currentPage;
        }
        // if (this.filter.date && this.apiDate) {
        //     params['date'] = this.apiDate;
        // }
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        if (this.filter.masteragent_id) {
            params['masteragent_id'] = this.filter.masteragent_id;
        }
        if (this.filter.shop) {
            params['shop'] = this.filter.shop;
        }
        if (this.filter.province_id) {
            params['province_id'] = this.filter.province_id;
        }
        if (this.filter.city_id) {
            params['city_id'] = this.filter.city_id;
        }

        let url = `agent/table?vendor=${this.filter.vendor_id}&date[default]=${this.filter.date}`;

        if (this.filter.channel) {
            url += `&filter[default_dealer_channel]=${this.filter.channel}`;
        }
        if (this.filter.cluster) {
            url += `&filter[default_priority]=${this.filter.cluster}`;
        }
        if (this.filter.agent_id) {
            url += `&filter[default_agent_id]=${this.filter.agent_id}`;
        }
        if (this.filter.masteragent_id) {
            url += `&filter[default_masteragent_id]=${this.filter.masteragent_id}`;
        }
        if (this.filter.city_id) {
            url += `&filter[default_city_id]=${this.filter.city_id}`;
        }
        if (this.filter.province_id) {
            url += `&filter[default_province_id]=${this.filter.province_id}`;
        }
        if (this.filter.type) {
            url += `&filter[default_type]=${this.filter.type}`;
        }
        if (this.filter.functional) {
            url += `&filter[default_functional]=${this.filter.functional}`;
        }

        this.visitsData = [];
        // this.tableUrl = `agent/table`;
        this.tableUrl = url;
    }

    selectedDate(date: Moment) {
        this.filter.date = date.format(this.dateRangePickerOptions.settings.locale.format);
        this.apiDate = date.format(this.dateRangePickerOptions.settings.locale.apiFormat);
    }

    changeRegion(event) {
        this.filter.region_id = event;
        if (!event) {
            this.filter.province_id = '';
        }
    }

    changeProvince(event) {
        this.filter.province_id = event;
        if (!event) {
            this.filter.region_id = '';
            this.filter.city_id = '';
        }
    }

    loadShops() {
        const params = {};
        if (this.filter.agent_id) {
            params['agent_id'] = this.filter.agent_id;
        }
        if (this.filter.vendor_id) {
            params['vendor_id'] = this.filter.vendor_id;
        }
        this.agentVisitsService.getShops(params).subscribe((response) => {
            this.shops = response;
        });
    }

    setMapPins(data: any) {
        this.visitsData = data;
    }

    changeVendor($event: any) {
        this.filter.vendor_id=$event;
        this.loadData();
    }

    getIcon(visit: any) {
        if(visit.default_dealer_id === null) {
            return this.mapPotentialPinOptions;
        }
        return this.mapAgentPinOptions;
    }

    setSelectedDateRange(start: Moment, end: Moment) {
        this.filter.date = start.format('YYYY-MM-DD') + ';' + end.format('YYYY-MM-DD');
        this.filter.dateOutput = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
        this.filter.from = start.format('YYYY-MM-DD');
        this.filter.to = end.format('YYYY-MM-DD');
        this.loadData();
    }
}
