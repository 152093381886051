import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dealer} from '@models/dealer';
import {MasterAgentService} from '@services/master-agent.service';

@Component({
    selector: 'app-master-agent-select',
    templateUrl: './master-agent-select.component.html',
    styleUrls: ['./master-agent-select.component.css']
})
export class MasterAgentSelectComponent implements OnInit {

    @Input()
    small = false;
    @Input()
    disabled = false;

    @Input()
    masterAgent: any;

    @Input()
    vendors: [];

    data: Dealer[] = [];

    @Output() onChange = new EventEmitter();

    loading = false;

    constructor(
        private service: MasterAgentService
    ) {
    }

    ngOnInit(): void {
        if (this.masterAgent) {
            this.masterAgent = parseInt(this.masterAgent);
        }
        this.loading = true;
        this.service.selectOptions().subscribe((data) => {
            this.data = data;
            this.loading = false;
        });
    }

    public reset() {
        this.masterAgent = null;
    }
}
