<app-master-header
        [ngBusy]="busy"
        cardClass="bg-gradient-info"
        image="assets/img/flats/destination.png"
        title="Report visite"
>
    <div class="row align-items-end">
        <div class="col-lg-4 col-12">
            <label translate>Seleziona periodo</label>
            <input
                    (selected)="setSelectedDateRange($event.start, $event.end)"
                    [(ngModel)]="filterForm.dateRange"
                    class="form-control"
                    daterangepicker
                    type="text"
            />
        </div>
    </div>
</app-master-header>
<div class="container-fluid px-0">
    <!-- [(activeId)]="active" -->
    <ngb-tabset (tabChange)="onTabSelect($event)">
        <ngb-tab title="Visite a Partner affiliati" id="tabAffiliati">
            <ng-template ngbTabContent>
                <div class="card">
                    <div class="card-header d-flex justify-content-end">
                        <button (click)="exportReport()" class="ml-auto btn btn-info">Scarica Report</button>
                    </div>
                    <div class="card-body p-0">
                        <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name="table-orders"
                                [fullScreen]="false"
                        >
                            <p style="color: white">Caricamento...</p>
                        </ngx-spinner>
                        <table class="table">
                            <!-- Intestazioni della tabella -->
                            <thead>
                            <tr>
                                <th style="width: 10%;">Login Code</th>
                                <th style="width: 15%;">Negozio</th>
                                <th style="width: 10%;">District</th>
                                <th style="width: 10%;">Sottocanale</th>
                                <th style="width: 10%;">Regione</th>
                                <th style="width: 10%;">Provincia</th>
                                <th style="width: 10%;">Città</th>
                                <th style="width: 10%;">Data</th>
                                <th style="width: 15%;">Attività chiusa</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let visitItem of visit">
                                <td>{{ visitItem.login_code !== null ? visitItem.login_code : '-' }}</td>
                                <td>{{ visitItem.shop !== null ? visitItem.shop : '-' }}</td>
                                <td>{{ visitItem.agent_name !== null ? visitItem.agent_name : '-' }}</td>
                                <td>{{ visitItem.sottocanale !== null ? visitItem.sottocanale : '-' }}</td>
                                <td>{{ visitItem.region_name !== null ? visitItem.region_name : '-' }}</td>
                                <td>{{ visitItem.province_name !== null ? visitItem.province_name : '-' }}</td>
                                <td>{{ visitItem.city_name !== null ? visitItem.city_name : '-' }}</td>
                                <td>{{ visitItem.date !== null ? visitItem.date : '-' }}</td>
                                <td class="text-center">{{ activityStatusMapping[visitItem.is_activity_closed] }}</td>
                            </tr>
                            <tr *ngIf="visit?.length<=0">
                                <td class="text-muted text-center" colspan="9" translate>Non ci sono visite</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <ngb-pagination *ngIf="ordersPagination && ordersPagination.pageCount>1"
                                        size="sm"
                                        [pageSize]="ordersPagination.perPage"
                                        [maxSize]="10"
                                        [(page)]="ordersPagination.currentPage"
                                        [collectionSize]="ordersPagination.totalCount"
                                        (pageChange)="loadVisitList()"
                                        [boundaryLinks]="true"
                        ></ngb-pagination>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>

        <ngb-tab title="Visite a Potenziali Partner" id="tabPotenziali">
            <ng-template ngbTabContent>
                <div class="card">
                    <div class="card-header d-flex justify-content-end">
                        <button (click)="exportReport()" class="ml-auto btn btn-info">Scarica Report</button>
                    </div>
                    <div class="card-body p-0">
                        <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name="table-orders"
                                [fullScreen]="false"
                        >
                            <p style="color: white">Caricamento...</p>
                        </ngx-spinner>
                        <table class="table">
                            <!-- Intestazioni della tabella -->
                            <thead>
                            <tr>
                                <th style="width: 15%;">Login Code</th>
                                <th style="width: 15%;">Negozio</th>
                                <th style="width: 15%;">District</th>
                                <th style="width: 15%;">Sottocanale</th>
                                <th style="width: 10%;">Regione</th>
                                <th style="width: 10%;">Provincia</th>
                                <th style="width: 10%;">Città</th>
                                <th style="width: 10%;">Data</th>
                                <th style="width: 15%;">Attività chiusa</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let visitItem of visit">
                                <td>Potenziale Partner</td>
                                <td>{{ visitItem.shop !== null ? visitItem.shop : '-' }}</td>
                                <td>{{ visitItem.agent_name !== null ? visitItem.agent_name : '-' }}</td>
                                <td>{{ visitItem.sottocanale !== null ? visitItem.sottocanale : '-' }}</td>
                                <td>{{ visitItem.region_name !== null ? visitItem.region_name : '-' }}</td>
                                <td>{{ visitItem.province_name !== null ? visitItem.province_name : '-' }}</td>
                                <td>{{ visitItem.city_name !== null ? visitItem.city_name : '-' }}</td>
                                <td>{{ visitItem.date !== null ? visitItem.date : '-' }}</td>
                                <td class="text-center">{{ activityStatusMapping[visitItem.is_activity_closed] }}</td>
                            </tr>
                            <tr *ngIf="visit?.length<=0">
                                <td class="text-muted text-center" colspan="8" translate>Non ci sono visite</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <ngb-pagination *ngIf="ordersPagination && ordersPagination.pageCount>1"
                                        size="sm"
                                        [pageSize]="ordersPagination.perPage"
                                        [maxSize]="10"
                                        [(page)]="ordersPagination.currentPage"
                                        [collectionSize]="ordersPagination.totalCount"
                                        (pageChange)="loadVisitList()"
                                        [boundaryLinks]="true"
                        ></ngb-pagination>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>

        <ngb-tab title="Foto" id="tabFoto" *show="['master']">
            <ng-template ngbTabContent>
                <div class="card">
                    <div class="card-body p-0">
                        <ngx-spinner
                                bdColor="rgba(0, 0, 0, 0.8)"
                                size="default"
                                color="#fff"
                                type="square-jelly-box"
                                name="table-orders"
                                [fullScreen]="false"
                        >
                            <p style="color: white">Caricamento...</p>
                        </ngx-spinner>
                        <table class="table">
                            <!-- Intestazioni della tabella -->
                            <thead>
                            <tr>
                                <th style="width: 20%;">Data</th>
                                <th style="width: 15%;">Foto</th>
                                <th style="width: 10%;">Scarica</th>
                                <th style="width: 10%;">Creato il</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let photo of photos">
                                <td>{{photo.date | amDateFormat: 'DD/MM/YYYY' }}</td>
                                <td *ngIf="photo.photos === 0" colspan="2">Non ci sono foto caricate</td>
                                <td *ngIf="photo.photos > 0">{{ photo.photos }} foto</td>
                                <td *ngIf="photo.photos > 0"><a [href]="photo.zip" class="btn btn-sm btn-success">Scarica</a></td>
                                <td>{{photo.created_at | amDateFormat: 'DD/MM/YYYY' }}</td>
                            </tr>
                            <tr *ngIf="photos?.length<=0">
                                <td class="text-muted text-center" colspan="4" translate>Non ci sono archivi di foto da scaricare</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <ngb-pagination *ngIf="ordersPagination"
                                        size="sm"
                                        [pageSize]="ordersPagination.perPage"
                                        [maxSize]="10"
                                        [(page)]="ordersPagination.currentPage"
                                        [collectionSize]="ordersPagination.totalCount"
                                        (pageChange)="loadFotoList($event)"
                                        [boundaryLinks]="true"
                        ></ngb-pagination>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>
