<div class="modal-body" [ngBusy]="busy">

    <form [formGroup]="formGroup" (ngSubmit)="save()" class="form-height">
        <div class="form-row">
            <div class="col-12 form-group" *ngIf="!data && !isUploadFile">
                <label translate>Dealer</label>
                <app-retailer-typeahead ngDefaultControl formControlName="dealer_id"
                                        (onChange)="setDealerId($event)"></app-retailer-typeahead>
                <val-errors controlName="dealers">
                    <ng-template valError="required">
                        <span translate>Dealer is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <div class="form-group col-12"  *ngIf="!data && !isUploadFile">
                <label>Operator</label>
                <app-vendor-select   (onChange)="setIdOperator($event)" formControlName="id_operator"
                ></app-vendor-select>
                <val-errors controlName="operators">
                    <ng-template valError="required">
                        <span translate>Operator is required</span>
                    </ng-template>
                </val-errors>
            </div>

            <div class="col-12" *ngIf="!isUploadFile">
                <label translate> Type</label>
            </div>
            <div class="col-11 form-group" *ngIf="!isUploadFile">
                <input formControlName="type" name="type" class="form-control" *ngIf="isNewType"/>
                <ng-select formControlName="type" [items]="types" bindLabel="type" *ngIf="!isNewType"
                           id="type" class="text-capitalize"
                           bindValue="type"
                ></ng-select>
                <val-errors controlName="type">
                    <ng-template valError="required">
                        <span translate>Type is required</span>
                    </ng-template>
                </val-errors>
            </div>
            <div class="col-1 form-group" *ngIf="!isUploadFile">
                <button type="button" class="btn btn-link pr-0" translate (click)="isNewType = !isNewType"
                        data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                    <i class="fa fa-plus text-success" *ngIf="!isNewType"></i>
                    <i class="fa fa-search text-primary" *ngIf="isNewType"></i>
                </button>
            </div>

            <div class="col-12 form-group" *ngIf="isUploadFile">
                <label for="pdfFile" translate>Excel file</label>
                <div class="custom-file" *ngIf="!submittedFileName">
                    <input id="pdfFile" type="file" class="custom-file-input"
                           (change)="handleFileInput($event?.target?.files[0])" accept=".xlsx, .xls">
                    <label class="custom-file-label" for="pdfFile" translate>
                        Upload file...
                    </label>
                </div>
                <div *ngIf="submittedFileName">
                    <span class="text-break">{{submittedFileName}}</span>
                    <button class="btn btn-xs btn-warning float-right" type="button"
                            (click)="handleFileRemove()">
                        <i class="fa fa-times m-0 px-1"></i>
                    </button>
                </div>
            </div>
            <div class="col-12 mt-4 mb-4 row mr-0 pr-0" *ngIf="data">
                <h4 translate class="col-sm-9">New Dealer:</h4>
                <button  id="dealer" type="button" class="btn btn-secondary col-sm-3 mr-0"
                         data-toggle="modal" data-target="#delaresModal" data-mdb-dismiss="modal"
                         (click)="showDealersModal()"
                >Add Dealer
                </button>
            </div>
            <div class="col-12 mb-4">
                <div class="variation-container">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-0" *ngFor="let race_dealer of races_dealers">
                            {{race_dealer['company_name']}}
                            <span class="float-right">
                                    <button type="button" class="btn btn-link text-danger mr-1 m-0 p-0"
                                            [swal]="deleteRaceFiles"
                                            (confirm)="deleteRacesDealers(race_dealer.id)">
                                            <i class="fas fa-trash"></i>
                                    </button>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 mt-4 mb-4 row mr-0 pr-0" *ngIf="data">
                <h4 translate class="col-sm-9">Upload File:</h4>
                <button  id="file" type="button" class="btn btn-secondary col-sm-3 mr-0"
                         data-toggle="modal" data-target="#delaresModal" data-mdb-dismiss="modal"
                         (click)="showModal()"
                >New File
                </button>
            </div>
            <div class="col-12 mb-4">
                <div class="variation-container">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item px-0" *ngFor="let race_file of races_files">
                            {{race_file['title']}}
                            <span class="float-right">
                                    <button type="button" class="btn btn-link text-danger mr-1 m-0 p-0"
                                            [swal]="deleteRaceFiles"
                                            (confirm)="deleteRacesFiles(race_file.id)">
                                            <i class="fas fa-trash"></i>
                                    </button>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12">
                <button type="submit" class="btn btn-success btn-block">
                    <i class="fa fa-check-circle mr-2"></i> <span translate>Salva</span>
                </button>
            </div>
        </div>
    </form>
</div>



<ng-template #uploadRacesFiles let-modal let-d="dismiss">
    <div class="modal-header" tabindex="2000">
        <h4 class="modal-title clamp-title" translate>New File</h4>
        <button (click)="d('Cross click')"
                type="button"
                class="close"
                aria-label="Close"

        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-races-files-modal [modal]="uploadFileModal" [data]="data" (addFile)="addFile($event)"
    ></app-races-files-modal>
</ng-template>
<ng-template #raceDealerTemplate let-modal let-d="dismiss">
    <div class="modal-header" tabindex="2000">
        <h4 class="modal-title clamp-title" translate>Aggiungi Dealer</h4>
        <button (click)="d('Cross click')"
                type="button"
                class="close"
                aria-label="Close"

        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-races-dealers [raceId]="data.id" (addDealer)="addDealer($event)"
                            [modal]="dealerModal"></app-races-dealers>
</ng-template>
