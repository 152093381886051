import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConcorrenzaPagination } from "@app/models/concorrenza";
import { MetaPagination } from "@models/meta-pagination";
import { ConcorrenzaService } from "@app/services/concorrenza.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import * as XLSX from "xlsx";
import * as moment from "moment";
import { DaterangepickerConfig } from "ng2-daterangepicker";
import { AgentSelectComponent } from "@app/widgets/agent-select/agent-select.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-concorrenza",
  templateUrl: "./concorrenza.component.html",
  styleUrls: ["./concorrenza.component.css"],
})
export class ConcorrenzaComponent implements OnInit {
  busy: any;

  form: FormGroup;

  concorrenzaList: any[];
  selectedItem: any;
  idSelectedItem: any;
  pagination: MetaPagination;

  today: Date = new Date();
  comuni = [];
  provinceId: string = "";
  activeTab: string = "tabDealers";
  apiDate = {
    from: "",
    to: "",
  };

  filter = {
    dealer_id: "",
    agent_id: "",
    masteragent_id: "",
    data: "",
    province_id: "",
    region_id: "",
    city_id: "",
  };

  @ViewChild("agentSelect") private agentSelect: AgentSelectComponent;

  pickerOptions = {
    singleDatePicker: false,
    maxDate: moment(),
    ranges: {
      "Last Month": [moment().subtract(1, "month"), moment()],
      "Last 6 Months": [moment().subtract(6, "month"), moment()],
      "Last Year": [moment().subtract(12, "month"), moment()],
    },
  };

  isChangingControls: boolean = false;

  @ViewChild("insertModal") private insertModal;
  @ViewChild("deleteModal") private deleteModal;

  private modal: NgbModalRef;

  @ViewChild("popupNote") private popupNote;

  popupConfig = {
    key: null,
    id: null,
    popover: null,
    inputValue: null,
  };

  isUpdate: boolean = false;
  selectedFile: File;
  selectedFileName: string = "";

  canoneRegex = /^[0-9]+([,.][0-9]+)?/;

  constructor(
    private service: ConcorrenzaService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private dateRangePickerOptions: DaterangepickerConfig
  ) {}

  ngOnInit(): void {
    this.refreshList();

    this.form = this.fb.group({
      data: ["", Validators.required],
      operatori: ["", Validators.required],
      tecnologia: ["", Validators.required],
      canone: [""],
      note: [""],
      img_file: [null],
    });

    this.form.valueChanges.subscribe((val) => {
      if (this.isChangingControls) {
        return;
      }

      this.isChangingControls = true;

      const canoneControl = this.form.controls["canone"];
      const noteControl = this.form.controls["note"];

      if (
        canoneControl &&
        canoneControl.value !== null &&
        canoneControl.value !== undefined &&
        canoneControl.value !== ""
      ) {
        noteControl.clearValidators();
        canoneControl.setValidators([Validators.required]);
      } else {
        canoneControl.clearValidators();
        noteControl.setValidators([Validators.required]);
      }

      canoneControl.updateValueAndValidity();
      noteControl.updateValueAndValidity();
      this.isChangingControls = false;
    });

    this.form.get("canone").setValidators((control) => {
      const value = control.value;
      if (!value) return { required: true };
      return this.canoneRegex.test(value) ? null : { pattern: true };
    });
  }

  refreshList() {
    this.spinner.show("card");
    const params = {};

    if (this.filter.data) {
      params["from_date"] = this.apiDate.from;
      params["to_date"] = this.apiDate.to;
    }

    if (this.filter.dealer_id) {
      params["dealer_id"] = this.filter.dealer_id;
    }

    if (this.filter.agent_id) {
      params["agent_id"] = this.filter.agent_id;
    }

    if (this.filter.masteragent_id) {
      params["masteragent_id"] = this.filter.masteragent_id;
    }

    if (this.activeTab == "tabDealers") {
      this.service.getListWithDealer(params).subscribe(
        (response: ConcorrenzaPagination) => {
          this.concorrenzaList = response.data;
          this.pagination = response._meta;
          this.spinner.hide("card");
        },
        (err) => {
          this.spinner.hide("card");
        }
      );
    } else {
      this.service
        .getListWithoutDealer(params)
        .subscribe((response: ConcorrenzaPagination) => {
          this.concorrenzaList = response.data;
          this.pagination = response._meta;
          this.spinner.hide("card");
        });
    }
  }

  public generateDateRangeString(
    startDate: moment.Moment,
    endDate: moment.Moment
  ) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(
      this.dateRangePickerOptions.settings.locale.apiFormat
    );
    this.apiDate.to = endDate.format(
      this.dateRangePickerOptions.settings.locale.apiFormat
    );
    this.filter.data =
      startDate.format(localDateFormat) +
      this.dateRangePickerOptions.settings.locale.separator +
      endDate.format(localDateFormat);
  }

  reloadList(event?) {
    this.spinner.show("card");
    if (this.activeTab == "tabDealers") {
      this.busy = this.service.getListWithDealer(event).subscribe(
        (response: ConcorrenzaPagination) => {
          this.concorrenzaList = response.data;
          this.pagination = response["_meta"];
          this.spinner.hide("card");
        },
        (err) => {
          this.spinner.hide("card");
        }
      );
    } else {
      this.service.getListWithoutDealer(event).subscribe(
        (response: ConcorrenzaPagination) => {
          this.concorrenzaList = response.data;
          this.pagination = response._meta;
          this.spinner.hide("card");
        },
        (err) => {
          this.spinner.hide("card");
        }
      );
    }
  }

  onTabSelect(event) {
    this.activeTab = event.nextId;
    this.refreshList();
  }

  openModal(isUpdate: boolean, item?: any) {
    this.selectedItem = item;
    this.modal = this.modalService.open(this.insertModal, {
      size: "xl",
    });

    this.isUpdate = isUpdate;
    if (item) {
      if (item.operatori) {
        item.operatori = item.operatori.split(",");
      }
      this.form.patchValue(item);
    }

    if (this.activeTab === "tabDealers") {
      if (!this.form.get("dealer_id")) {
        this.form.addControl("dealer_id", this.fb.control(""));
      }
    } else {
      if (this.form.get("dealer_id")) {
        this.form.removeControl("dealer_id");
      }
    }
  }

  selectNewImage() {
    const inputElement = document.getElementById("img") as HTMLInputElement;
    inputElement.click();
  }

  openPopover(popOver: any, orderId, item, key) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    popOver.open();
  }

  closePopover() {
    this.popupConfig.popover.close();
  }

  submitForm() {
    const formData = new FormData();

    if (this.activeTab === "tabDealers") {
      formData.append("dealer_id", this.form.getRawValue()["dealer_id"]);
    }

    formData.append("operatori", this.form.getRawValue()["operatori"]);
    formData.append("data", this.form.getRawValue()["data"]);
    formData.append("tecnologia", this.form.getRawValue()["tecnologia"]);
    formData.append("canone", this.form.getRawValue()["canone"]);
    formData.append("comune", this.filter.city_id);
    formData.append("note", this.form.getRawValue()["note"]);

    if (this.selectedFile) {
      formData.append("img_file", this.selectedFile);
    } else {
      formData.append("img_file", null);
    }

    if (this.isUpdate) {
      return this.updateConcorrenza(this.selectedItem.id, formData);
    } else if (!this.isUpdate) {
      this.spinner.show("modalSpinner");
      this.service.create(formData).subscribe((res) => {
        console.log("response", res);
        this.spinner.hide("modalSpinner");
        this.modalService.dismissAll();
        this.form.reset();
        this.refreshList();
        this.selectedItem = null;
        this.toastr.success(
          `Creazione effettuata con successo`,
          "Concorrenza",
          { positionClass: "toast-top-right" }
        );
      }),
        (err) => {
          this.toastr.error(
            `Errore durante la creazione, contattare il supporto!`,
            "Errore",
            { positionClass: "toast-top-right" }
          );
        };
    }
  }

  closeModal() {
    this.form.reset();
    this.modalService.dismissAll();
    this.selectedFile = null;
    this.selectedFileName = null;
    this.selectedItem = null;
    this.idSelectedItem = null;
  }

  openDeleteModal(id) {
    this.idSelectedItem = id;
    this.modal = this.modalService.open(this.deleteModal, {
      size: "sm",
    });
  }

  deleteRecord() {
    this.spinner.show("deleteModalSpinner");
    this.service.delete(this.idSelectedItem).subscribe(
      (res) => {
        this.spinner.hide("deleteModalSpinner");
        this.modalService.dismissAll();
        this.refreshList();
        this.idSelectedItem = null;
        this.toastr.success(`Record eliminato correttamente`, "Concorrenza", {
          positionClass: "toast-top-right",
        });
      },
      (err) => {
        this.toastr.error(
          `Errore durante l'eliminazione del record, contattare il supporto!`,
          "Concorrenza",
          { positionClass: "toast-top-right" }
        );
      }
    );
  }

  updateConcorrenza(id, data) {
    this.spinner.show("modalSpinner");
    this.service.update(id, data).subscribe(
      (res) => {
        this.spinner.hide("modalSpinner");
        this.modalService.dismissAll();
        this.form.reset();
        this.refreshList();
        this.selectedItem = null;
        this.toastr.success(`Modifica effettuata con successo`, "Concorrenza", {
          positionClass: "toast-top-right",
        });
      },
      (err) => {
        this.toastr.error(
          `Errore durante la modifica, contattare il supporto!`,
          "Concorrenza",
          { positionClass: "toast-top-right" }
        );
      }
    );
  }

  save(value) {
    const body = {};
    body[this.popupConfig["key"]] = value;
    this.spinner.show("popupSpinner");
    this.updateConcorrenza(this.popupConfig.id, body);
  }

  hasImgError(): boolean {
    const imgPathControl = this.form.get("img_file");
    return (
      imgPathControl?.dirty && imgPathControl.hasError("invalidImageFormat")
    );
  }

  areFieldsPopulated(): boolean {
    return this.form.get("note").value || this.form.get("canone").value;
  }

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    this.selectedFile = file;
    this.selectedFileName = file.name;
    this.form.get("img_file").setValue(file);
  }

  export() {
    let rows = this.concorrenzaList;

    let output = rows.map((row) => {
      return {
        operatori: row.operatori,
        Tecnologia: row.tecnologia,
        "Login Partner": row.login_partner,
        "Ragione Sociale Partner": row.ragsociale_partner,
        Regione: row.regione_nome,
        Provincia: row.provincia_nome,
        Comune: row.comune_nome,
        "Regional Manager": row.ragsociale_regional,
        "District Manager": row.ragsociale_district,
        "Canone / Mese": row.canone,
        Foto: row.img,
        Note: row.note,
      };
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "export-concorrenza.xlsx");
  }

  setMasterAgent($event: any) {
    if ($event !== null) {
      this.agentSelect.reset();
    }
    this.filter.masteragent_id = $event;
  }

  changeRegion(event) {
    this.filter.region_id = event;
    if (!event) {
      this.filter.province_id = "";
    }
  }

  changeProvince(event) {
    this.filter.province_id = event;
    if (!event) {
      this.filter.region_id = "";
    }
  }

  onCityChange(event) {
    this.filter.city_id = event;

    if (!event) {
      this.filter.province_id = "";
    }
  }
}
