import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import {AlertService} from '@services/alert.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const agent = 'agent';
const visits = 'visites';
const schedule = 'schedule';
const response = 'response';

@Injectable({
    providedIn: 'root'
})
export class AgentVisitsService {

    constructor(
        public request: RequestService,
        private alertService: AlertService) {
    }

    postVisit(body) {
        return this.request.post(visits, body);
    }

    putVisit(id, body) {
        return this.request.put(visits + '/' + id, body);
    }

    deleteVisit(id) {
        return this.request.delete(visits + '/' + id);
    }

    postSchedule(body) {
        return this.request.post(visits + '/' + schedule, body);
    }

    putSchedule(id, body) {
        return this.request.put(visits + '/' + schedule + '/' + id, body);
    }

    deleteSchedule(id) {
        return this.request.delete(visits + '/' + schedule + '/' + id);
    }

    postResponse(body) {
        return this.request.post(visits + '/' + response, body);
    }

    putResponse(id, body) {
        return this.request.put(visits + '/' + response + '/' + id, body);
    }

    deleteResponse(id) {
        return this.request.delete(visits + '/' + response + '/' + id);
    }

    getRoutes(param?): Observable<any> {
        return this.request.get(agent + '/routes', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getDashboardReport(param?): Observable<any> {
        return this.request.get(agent + '/report', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    getAgentStops(param?): Observable<any> {
        return this.request.get(agent + '/stops', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    getAgentVisits(param?): Observable<any> {
        return this.request.get(agent + '/visits', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getVisitFormStructure(param?): Observable<any> {
        return this.request.get(visits + '/form-structure', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getQuestionsDetail(param?): Observable<any>{
        return this.request.get(visits + '/questions-detail', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getShops(param?): Observable<any> {
        return this.request.get(agent + '/shops', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    // getVisitList(from: any, to:any): Observable<any> {
    //     return this.request.get(visits + `/visit-list/${from}/${to}`).pipe(
    //         map((response: any) => {
    //             return response;
    //         })
    //     );
    // }

    getVisitList(param): Observable<any> {
        return this.request.get(visits + '/visit-list', param).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    getZipList(param = {}): Observable<any> {
        return this.request.get('agent/zip', param);
    }



    public successAlert(text) {
        this.alertService.show(
            '',
            'Visita ' + text,
            {classname: 'bg-success text-light', delay: 4000}
        );
    }
    public errorAlert(message?) {
        this.alertService.show(
            'An error occurred!',
            message ? message : 'Try again!',
            {classname: 'bg-danger text-light', delay: 2000}
        );
    }


}
