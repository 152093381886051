import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from '@services/dashboard.service';
import {DataBuilderModalService} from '@services/data-builder-modal.service';
import {RequestService} from '@services/request.service';

@Component({
    selector: 'app-autoload-cluster-comuni',
    templateUrl: './autoload-cluster-comuni.component.html',
    styleUrls: ['./autoload-cluster-comuni.component.css']
})
export class AutoloadClusterComuniComponent implements OnInit {
    data: any;
    autoloadTableUrl: string;
    status = 'hidden';

    @Input() brand: string
    constructor(
        private requestService: RequestService,
        public dbm: DataBuilderModalService
    ) {
    }

    _urlClusterComuni: string;

    @Input() set urlClusterComuni(value: string) {
        this._urlClusterComuni = value;

        if (this._urlClusterComuni !== undefined) {
            this.loadRiepilogo();
        }
    }

    ngOnInit() {

    }

    public setUrl(url: string) {
        this._urlClusterComuni = url;
    }

    private loadRiepilogo() {
        this.status = 'loading';
        this.requestService.get(this.brand + this._urlClusterComuni)
            .subscribe((data) => {
                this.data = data.result;
                this.status = 'hidden';
            }, (error) => {
                this.status = 'error';
            });
    }
}
