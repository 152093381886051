<!--HEADER-->
<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 src="assets/img/flats/commerce-and-shopping.png"
                 width="160px">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4">Gare</h1>
                <hr>
                <form>
                    <div class="row align-items-end">
<!--                        <div class="col-lg col-12"><label translate>Dealer</label>-->
<!--                            <app-retailer-typeahead (onChange)="filter.dealer=$event"></app-retailer-typeahead>-->
<!--                        </div>-->
<!--                        <div class="col-lg col-12"><label translate>Operator</label>-->
<!--                            &lt;!&ndash;                            <ng-select  bindLabel="operator"&ndash;&gt;-->
<!--                            &lt;!&ndash;                                       name="operator" id="operator" class="text-capitalize"&ndash;&gt;-->
<!--                            &lt;!&ndash;                                       bindValue="type"&ndash;&gt;-->
<!--                            &lt;!&ndash;                            ></ng-select>&ndash;&gt;-->
<!--                            <input [(ngModel)]="filter.operator" class="form-control" name="name" type="text"/>-->
<!--                        </div>-->
                        <div class="col-lg col-12"><label>Tipo</label>
                            <ng-select [(ngModel)]="filter.type" [items]="types" bindLabel="type"
                                       bindValue="type" class="text-capitalize" id="type"
                                       name="type"
                            ></ng-select>
                        </div>

                        <div class="col-lg col-12">
                            <label>Periodo</label>
                            <app-month-range-picker [singleMonthPicker]="true"
                                                    (selected)="setSelectedDate($event)"></app-month-range-picker>
                        </div>
                        <div class="col-lg-2 col-12 mt-2 mt-lg-auto">
                            <button class="btn btn-info btn-block" translate
                                    (click)="refreshTable()">Filter
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-header">
<!--        <div class="row">-->
<!--            <div class="col-lg-8 col-xl-10 col-12">-->
<!--                <h4 class="mb-0" translate>Gare</h4>-->
<!--            </div>-->
<!--            <div class="col-lg-2 col-xl-1 col-6">-->
<!--                <button (click)="showModal(null,true)" class="btn btn-primary btn-sm float-right" translate-->
<!--                        type="button">-->
<!--                    Carica file-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="col-lg-2 col-xl-1 col-6">-->
<!--                <button (click)="showModal()" class="btn btn-success btn-sm float-right" translate-->
<!--                        type="button">-->
<!--                    Nuova Gara-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
        <button (click)="downloadDetail()" class="btn btn-success btn-sm float-right"
                type="button">
            Scarica Dettaglio
        </button>
    </div>
    <div class="card-body p-0 rounded-bottom table-responsive">
        <table class="table table-sm table-striped">
            <thead>
            <tr>
                <th>Codice</th>
                <th>Tipo</th>
                <th>Descrizione</th>
                <th>Valida da</th>
                <th>a</th>
                <th>Operatore</th>
                <th class="text-center" translate>Dealers</th>
                <th class="text-center">Chiusa</th>
                <th class="text-center">Provvigioni</th>
                <th>Inserito il</th>
                <th class="text-right"></th>
            </tr>
            </thead>
            <tbody class="bg-gradient-white">
            <ngx-spinner
                    [fullScreen]="false"
                    bdColor="rgba(0, 0, 0, 0.8)"
                    color="#fff"
                    name="table-races"
                    size="default"
                    type="square-jelly-box"
            >
                <p style="color: white">Caricamento...</p>
            </ngx-spinner>

            <tr *ngFor="let item of items">
                <td class="text-capitalize" translate>{{(item.code)}}</td>
                <td class="text-capitalize" translate>{{(item.type)}}</td>
                <td class="text-capitalize" translate>{{(item.description)}}</td>
                <td>{{ item.from_date | amDateFormat: 'DD/MM/YYYY' }}</td>
                <td>{{ item.to_date | amDateFormat: 'DD/MM/YYYY' }}</td>
                <td>{{ item.operator_name }}</td>
                <td class="text-center">{{ item.dealers }}</td>
                <td class="text-center">{{ item.closed == 1 ? 'Si' : '' }}</td>
                <td class="text-center">{{ item.revenues | currency: '€ ' }}</td>
                <td>{{ item.created_at  | amFromUnix | amDateFormat: 'DD/MM/YYYY' }}</td>
                <td class="text-right">
                    <button (click)="showModal(item)" class="btn btn-link">
                        <i class=" fa fa-edit text-muted"></i>
                    </button>

                    <button (confirm)="delete(item.id)" [swal]="deleteOptions"
                            class="btn btn-link">
                        <i class="fa fa-trash text-danger"></i>
                    </button>

                </td>
            </tr>
            <tr *ngIf="items?.length<=0">
                <td colspan="100%" translate>List is empty</td>
            </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="items?.length>0" class="card-footer">
   <span><span translate>Ci sono </span><b> {{ this.pagination?.totalCount }}</b>
       {{this.pagination?.totalCount <= 1 ? 'item' : 'items'|translate}}.</span>
        <ngb-pagination (pageChange)="loadTable($event)"
                        *ngIf="pagination?.pageCount>1"
                        [(page)]="pagination.currentPage"
                        [boundaryLinks]="true"
                        [collectionSize]="pagination.totalCount"
                        [maxSize]="10"

                        [pageSize]="pagination.perPage"
                        size="sm"
        ></ngb-pagination>
    </div>
</div>

<ng-template #formTemplate let-modal>
    <div class="modal-header">
        <h4 *ngIf="!isUploadFileModal" class="modal-title clamp-title"
            translate>{{selectedData ? 'Edit Races' : 'Create Races'}}</h4>
        <h4 *ngIf="isUploadFileModal" class="modal-title clamp-title" translate>Upload file</h4>
        <button (click)="modalService.dismissAll()"
                aria-label="Close"
                class="close"
                type="button"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <app-races-form (refresh)="refreshTable()" [data]="selectedData" [isUploadFile]="isUploadFileModal"
                    [types]="types"></app-races-form>
</ng-template>

