import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment/moment';
import {Moment} from 'moment/moment';
import {RequestService} from '@services/request.service';

@Component({
    selector: 'app-master-confronto',
    templateUrl: './master-confronto.component.html',
    styleUrls: ['./master-confronto.component.css']
})
export class MasterConfrontoComponent implements OnInit {
    busy: Subscription;

    regioni = [];

    filter = {
        vendor_id: '',
        date: '',
        dateOutput: '',
        from: '',
        to: '',
        masteragent_id: '',
        agent_id: '',
        region_id: '',
        city_id: '',
        channel: '',
        cluster: '',
    };

    isLoading = false;

    changeRegion(event) {
        this.filter.region_id = event;
    }

    setSelectedDateRange(start: Moment, end: Moment) {
        this.filter.date = start.format('YYYY-MM-DD') + ';' + end.format('YYYY-MM-DD');
        this.filter.dateOutput = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
        this.filter.from = start.format('YYYY-MM-DD');
        this.filter.to = end.format('YYYY-MM-DD');
        this.loadData();
    }

    constructor(private requestService: RequestService) {
    }

    ngOnInit() {
        this.setSelectedDateRange(moment().startOf('month'), moment());
    }

    changeVendor($event: any) {
        this.filter.vendor_id = $event;
        this.loadData();
    }

    loadData() {
        let url = `sales/confronto-italia?operatore=${this.filter.vendor_id}&date=${this.filter.date}`;

        if (this.filter.channel) {
            url += `&channel=${this.filter.channel}`;
        }
        if (this.filter.cluster) {
            url += `&priority=${this.filter.cluster}`;
        }
        if (this.filter.agent_id) {
            url += `&agent_id=${this.filter.agent_id}`;
        }
        if (this.filter.masteragent_id) {
            url += `&masteragent_id=${this.filter.masteragent_id}`;
        }
        if (this.filter.region_id) {
            url += `&region_id=${this.filter.region_id}`;
        }

        this.isLoading = true;
        this.requestService.get(url)
            .subscribe((data) => {
                    this.regioni = data;
                    this.isLoading = false;
                },
                (error) => {
                    this.isLoading = false;
                });
    }
}
