<app-documents-modal #documentModal></app-documents-modal>

<ng-template #autoloadTable let-modal size="xl">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Dettaglio</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <app-autoload-table [url]="autoloadTableUrl"></app-autoload-table>
    </div>
</ng-template>

<app-modal-questions #questionModal></app-modal-questions>


<div class="card bg-transparent mb-0">
    <div class="card-header" *ngIf="!small && !hideHeader && tableTitle" >
        <div class="card-title">
            {{tableTitle}}
        </div>
    </div>
    <div class="card-header">
        <div class="row">
            <div class="col-12 col-xl-4">
                <div ngbDropdown class="d-inline-block w-50 mb-2" [autoClose]="false" *ngIf="this.additionalData && this.additionalData?.length > 0">
                    <button class="btn btn-outline-primary btn-sm btn-block" ngbDropdownToggle>Mostra più dati</button>
                    <ul ngbDropdownMenu>
                        <li ngbDropdownItem *ngFor="let item of this.additionalData">
                            <label style="min-width: 100%;" class="mb-0">
                                <input type="checkbox" [(ngModel)]="item.checked"> {{item?.label}}
                            </label>
                        </li>
                        <div class="px-4 mt-2">
                            <button class="btn btn-primary btn-sm btn-block" (click)="updateAdditionalData()">Aggiorna</button>
                        </div>
                    </ul>
                </div> <div ngbDropdown class="d-inline-block w-50 mb-2 pl-2" [autoClose]="false" *ngIf="this.additionalData && this.additionalData.length > 0">
                <button class="btn btn-outline-dark btn-sm btn-block" ngbDropdownToggle>Mostra percentuali</button>
                <ul ngbDropdownMenu>
                    <ng-container *ngFor="let item of this.additionalData">
                        <li ngbDropdownItem *ngIf="item?.percentage_sub">
                            <label style="min-width: 100%;" class="mb-0">
                                <input type="checkbox" [(ngModel)]="item.percentage_checked"> {{item.label}}
                            </label>
                        </li>
                    </ng-container>
                    <div class="px-4 mt-2">
                        <button class="btn btn-primary btn-sm btn-block" (click)="updateAdditionalData()">Aggiorna</button>
                    </div>
                </ul>
            </div>
            </div>
            <div class="col-12 col-xl-4 text-center">
                <div class="input-group input-group-s mb-3" *ngIf="this.filters">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                    </div>
                    <input
                            class="form-control form-control-sm"
                            type="text"
                            [(ngModel)]="dateRange"
                            daterangepicker
                            (selected)="setSelectedDateRange($event.start, $event.end)"
                    />
                </div>
            </div>
            <div class="col-12 col-xl-4 text-right">
                <div class="input-group input-group-sm w-100">
                    <input type="text" name="table_search" (input)="filterTable($event)"
                           class="form-control float-right" placeholder="Cerca ...">

                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body  table-responsive p-0">
        <ng-content></ng-content>
        <ng-container *ngIf="!hideTable">
            <table class="table" [ngClass]="{'table-sm mb-0': small, 'table-striped': this.striped, 'table-hover': hover}">
                <thead>
                <tr>
                    <th *ngFor="let column of columnsWithOrdering"
                        [ngClass]="column['class']"
                        [ngStyle]="{'cursor': column.type == getColumnTypes().BUTTON ? '' : 'pointer', 'text-align': getTableColumnAlignment(column.alignment)}"
                        (click)="switchColumnOrdering(column)">
                        <i class="fa fa-caret-down"
                           [ngStyle]="{opacity: column.order === getColumnOrdering().ASC ? 1 : 0}"></i>
                        <i class="fa fa-caret-up"
                           [ngStyle]="{opacity: column.order === getColumnOrdering().DESC ? 1 : 0}"></i>
                        <span translate>{{column.text}}</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of filteredPaginatedRows();" [ngClass]="row['_class']">
                    <ng-container>
                        <td *ngFor="let column of columnsWithOrdering; let i = index;"
                            [ngClass]="getRowClass(row, column.key)"
                            [ngStyle]="{'text-align': getTableColumnAlignment(column.alignment)}"
                            [class.interactable]="isInteractable()" (click)="onSelect.emit(row.id)"
                            [ngSwitch]="column.type"
                        >
                            <!-- DOCUMENT -->
                            <ng-container *ngSwitchCase="getColumnTypes().DOCUMENT">
                                <button *ngIf="row[column.key]" class="btn btn-sm btn-info"
                                        (click)="documentModal.showDocument(row[column.key])"><i class="fa fa-eye"></i>
                                    Mostra
                                </button>
                            </ng-container>

                            <!-- CHECKBOX -->
                            <ng-container *ngSwitchCase="getColumnTypes().CHECKBOX">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" [checked]="row[column.key]">
                                    <label class="custom-control-label"></label>
                                </div>
                            </ng-container>

                            <!-- IMAGE -->
                            <ng-container *ngSwitchCase="getColumnTypes().IMAGE">
                                <img [src]="row[column.key]" height="25px"/>
                            </ng-container>

                            <!-- DATE -->
                            <ng-container *ngSwitchCase="getColumnTypes().DATE">
                                {{row[column.key] | amDateFormat: 'DD/MM/YYYY'}}
                            </ng-container>

                            <!-- CURRENCY -->
                            <ng-container *ngSwitchCase="getColumnTypes().CURRENCY">
                                <ng-container *ngIf="isNumber(row[column.key])">
                                    {{row[column.key] | currency: '€ '}}
                                </ng-container>
                                <ng-container *ngIf="!isNumber(row[column.key])">
                                    {{row[column.key]}}
                                </ng-container>
                            </ng-container>

                            <!-- BUTTON -->
                            <ng-container *ngSwitchCase="getColumnTypes().BUTTON">
                                <ng-container *ngIf="column.buttonText && row[column.key]">
                                    <button class="btn btn-sm btn-info" [routerLink]="[baseUrl + row[column.key]]"><i
                                            [ngClass]="column.icon"></i> {{column.buttonText}}
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="!column.buttonText && row[column.key]">

                                    <button class="btn btn-sm btn-info" [routerLink]="[baseUrl + row[column.key]]"><i
                                            class="fa fa-eye"></i> Visualizza
                                    </button>
                                </ng-container>
                            </ng-container>

                            <!-- TRIGGER -->
                            <ng-container *ngSwitchCase="getColumnTypes().TRIGGER">
                                <button *ngIf="row[column.key]" (click)="onButtonTrigger(column.key, row[column.key])" class="btn btn-sm btn-info">
                                    <i [ngClass]="getIcon(column)"></i>
                                    <ng-container *ngIf="column.text.length > 0">
                                        {{column.text}}
                                    </ng-container>
                                </button>
                            </ng-container>

                            <!-- EMAIL -->
                            <ng-container *ngSwitchCase="getColumnTypes().EMAIL">
                                <a *ngIf="row[column.key]" href="mailto:{{row[column.key]}}" target="_blank"><i class="fas fa-envelope text-dark"></i></a>
                            </ng-container>

                            <!-- WHATSAPP -->
                            <ng-container *ngSwitchCase="getColumnTypes().WHATSAPP">
                                <a *ngIf="row[column.key]" href="https://wa.me/39{{row[column.key]}}" target="_blank"><i class="fab fa-whatsapp text-success"></i></a>
                            </ng-container>

                            <!-- ICON -->
                            <ng-container *ngSwitchCase="getColumnTypes().ICON">
                                <i [ngClass]="row[column.key]"></i>
                            </ng-container>

                            <!-- AUDIO -->
                            <ng-container *ngSwitchCase="getColumnTypes().AUDIO">
                                <audio controls>
                                    <source [src]="row[column.key]" type="audio/wav">
                                    Your browser does not support the audio element.
                                </audio>
                            </ng-container>

                            <!-- PERCENT -->
                            <ng-container *ngSwitchCase="getColumnTypes().PERCENT">
                                <ng-container *ngIf="isNumber(row[column.key])">
                                    {{row[column.key] | percent}}
                                </ng-container>
                                <ng-container *ngIf="!isNumber(row[column.key])">
                                    {{row[column.key]}}
                                </ng-container>
                            </ng-container>

                            <!-- DEFAULT -->
                            <ng-container *ngSwitchDefault>
                                <ng-container *ngIf="column.modal">
                                    <a class="link" (click)="openAutoloadTable(autoloadTable, column.modal, column.params, row)">{{row[column.key]}}</a>
                                </ng-container>
                                <ng-container *ngIf="column.url">
                                    <a class="link" [routerLink]="getLink(column.url, column.params, row)">{{row[column.key]}}</a>
                                </ng-container>
                                <ng-container *ngIf="!column.modal && !column.url">
                                    <ng-container *ngIf="getQuestion(row, column)">
                                        <span class="link zoom" (click)="questionModal.startQuestion(row['questions'][column.key])">{{row[column.key]}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="!getQuestion(row, column)">
                                        {{row[column.key]}}
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                            <!-- BADGE -->
                            <ng-container *ngSwitchCase="getColumnTypes().BADGE">
                                <ng-container [ngSwitch]="row[column.key]">
                                        <span *ngSwitchCase="'Siamo in attesa di riceverla tramite SDI'"
                                              class="badge badge-warning">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Da Gestire'"
                                          class="badge badge-warning">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'In Revisione'"
                                          class="badge badge-warning">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Completata'"
                                          class="badge badge-success">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Gestito'"
                                          class="badge badge-success">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Aperto'"
                                          class="badge badge-success">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Rifiutata'"
                                          class="badge badge-danger">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Chiuso'" class="badge badge-danger">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Annullato'"
                                          class="badge badge-danger">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Da Approvare'"
                                          class="badge badge-warning">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Approvata'"
                                          class="badge badge-success">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Inviata'"
                                          class="badge badge-success">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'In attesa di PDA'"
                                          class="badge badge-warning">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'In Attesa'"
                                          class="badge badge-warning">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'In revisione'"
                                          class="badge badge-info">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Attivo'"
                                          class="badge badge-success">{{row[column.key]}}</span>
                                    <span *ngSwitchCase="'Rifiutato'"
                                          class="badge badge-danger">{{row[column.key]}}</span>
                                    <span *ngSwitchDefault
                                          class="badge badge-info">{{row[column.key]}}</span>
                                </ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
            <span *ngIf="_filteredPaginatedRows && _filteredPaginatedRows.length === 0" class="no-data">
                    Nessun dato da mostrare
                </span>
        </ng-container>
    </div>
    <div class="card-footer" *ngIf="!hideFooter">
        <div class="row align-items-center">
            <div class="col-12 col-xl-4 mb-xl-0 mb-2" *ngIf="!hideFooterDescription">
                Stai visualizzando pagina <b>{{page}}</b> di <b>{{getTotalPages()}}</b>, per un totale di
                <b>{{ _filteredRows?.length }}</b> elementi.
            </div>
            <div class="col-12 col-xl-4 mb-xl-0 mb-2"  [ngClass]="{'text-center': !hideFooterDescription, 'text-left': hideFooterDescription}">
                <button class="btn btn-success btn-sm btn-block d-xl-none" (click)="exportTable()">Esporta</button>
                <button class="btn btn-success btn-sm d-none d-xl-block" (click)="exportTable()">Esporta</button>
            </div>
            <div class="col-12 col-xl-4" *ngIf="small">
                <div class="input-group input-group-sm" style="width: 200px;">
                    <input type="text" name="table_search" (input)="filterTable($event)"
                           class="form-control float-right" placeholder="Cerca ...">
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-4 text-center text-xl-right">
                <ngb-pagination
                        *ngIf="pagination"
                        size="sm"
                        [boundaryLinks]="!small"
                        [(page)]="page"
                        [pageSize]="pageSize"
                        [collectionSize]="filteredRows()?.length"
                        [maxSize]="10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>
