import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment/moment';
import {RequestService} from '@services/request.service';
import {Helpers} from '@helpers/helpers';
import {AutoloadChartjsComponent} from '@widgets/autoload-chartjs/autoload-chartjs.component';
import {AgentSelectComponent} from '@widgets/agent-select/agent-select.component';

@Component({
    selector: 'app-master-nuove-affiliazioni',
    templateUrl: './master-nuove-affiliazioni.component.html',
    styleUrls: ['./master-nuove-affiliazioni.component.css']
})
export class MasterNuoveAffiliazioniComponent implements OnInit {
    @ViewChild('agentSelect') private agentSelect: AgentSelectComponent;
    @ViewChild('eoloChart') eoloChart: AutoloadChartjsComponent;

    busy: Subscription;
    laddaTrendDaily = null;
    laddaTrendMonthly = null;

    urlSubheader: string;
    urlAgenti: string;
    urlRegioni: string;
    urlNegoziTabella: string;
    urlTrend: string;
    urlTrendRange: string;

    chartPeriod = {
        from: moment().subtract(12, 'month').startOf('month'),
        to: moment().endOf('month'),
    };

    operatore = null;

    period = {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().endOf('month').format('YYYY-MM-DD'),
    };

    listaPeriodi = Helpers.getPeriods();

    filter = {
        period: '',
        date: '',
        dateOutput: '',
        from: '',
        to: '',
        agent_id: '',
        masteragent_id: '',
        channel: '',
        vendor: '',
    };
    currentTab = 1;

    constructor(
        public requestService: RequestService
    ) {
    }

    ngOnInit() {
        this.filter.period = this.listaPeriodi[0]['value'];
    }

    setVendor($event: any) {
        this.filter.vendor = $event;
        this.load();
    }

    setMasterAgent($event: any) {
        if ($event !== null) {
            this.agentSelect.reset();
        }
        this.filter.masteragent_id = $event;
    }

    downloadDailyTrend() {
        const periodMoment = moment(this.filter.period);

        let period = {
            start: periodMoment.startOf('month').format('YYYY-MM-DD'),
            end: periodMoment.endOf('month').format('YYYY-MM-DD'),
        };

        if (moment(this.filter.period).format('YYYY-MM') === moment().format('YYYY-MM')) {
            period.end = moment().format('YYYY-MM-DD');
        }

        let url = 'v2/openings/trend?vendor=' + this.filter.vendor + '&from=' + period.start + '&to=' + period.end + '&download=1';

        if (this.filter.channel) {
            url += '&channel=' + this.filter.channel;
        }
        if(this.filter.masteragent_id) {
            url += `&masteragent_id=${this.filter.masteragent_id}`;
        }
        if(this.filter.agent_id) {
            url += `&agent_id=${this.filter.agent_id}`;
        }

        this.laddaTrendDaily = this.requestService
            .get(url, {}, {responseType: 'blob'})
            .subscribe((res: any) => {
                this.requestService.downloadFile(res, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                this.laddaTrendDaily = null;
            });
    }

    downloadMonthlyTrend() {
        let url = 'v2/openings/trend-range?vendor=' + this.filter.vendor + '&from=' + this.chartPeriod.from + '&to=' + this.chartPeriod.to + '&download=1';

        if (this.filter.channel) {
            url += '&channel=' + this.filter.channel;
        }
        if(this.filter.masteragent_id) {
            url += `&masteragent_id=${this.filter.masteragent_id}`;
        }
        if(this.filter.agent_id) {
            url += `&agent_id=${this.filter.agent_id}`;
        }

        this.laddaTrendMonthly = this.requestService
            .get(url, {}, {responseType: 'blob'})
            .subscribe((res: any) => {
                this.requestService.downloadFile(res, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                this.laddaTrendMonthly = null;
            });
    }

    updateChart($event: any) {
        this.chartPeriod = {
            from: $event.start,
            to: $event.end,
        };
    }

    load() {
        const periodMoment = moment(this.filter.period);

        let period = {
            start: periodMoment.startOf('month').format('YYYY-MM-DD'),
            end: periodMoment.endOf('month').format('YYYY-MM-DD'),
        };

        if (moment(this.filter.period).format('YYYY-MM') === moment().format('YYYY-MM')) {
            period.end = moment().format('YYYY-MM-DD');
        }

        this.filter.date = `${period.start};${period.end}`;

        let urlSubheader = `v2/openings/overview-header?vendor=${this.filter.vendor}&period=${this.filter.date}`;
        let urlAgenti = `v2/openings/new-list-by-agent?vendor=${this.filter.vendor}&date[current_month]=${this.filter.date}`;
        let urlNegoziTabella = `v2/openings/new-list?vendor=${this.filter.vendor}&date[current_month]=${this.filter.date}`;
        let urlRegioni = `v2/openings/new-list-by-am?vendor=${this.filter.vendor}&date[current_month]=${this.filter.date}`;
        let urlTrend = `/openings/trend?vendor=${this.filter.vendor}&from=${period.start}&to=${period.end}`;
        let urlTrendRange = `/openings/trend-range?vendor=${this.filter.vendor}&from=${this.chartPeriod.from}&to=${this.chartPeriod.to}`;

        if (this.filter.channel) {
            urlAgenti += `&filter[default_position]=${this.filter.channel}`;
            urlNegoziTabella += `&filter[default_position]=${this.filter.channel}`;
            urlRegioni += `&filter[default_position]=${this.filter.channel}`;
            urlTrend += `&channel=${this.filter.channel}`;
            urlTrendRange += `&channel=${this.filter.channel}`;
            urlSubheader += `&channel=${this.filter.channel}`;
        }

        if(this.filter.masteragent_id) {
            urlTrend += `&masteragent_id=${this.filter.masteragent_id}`;
            urlTrendRange += `&masteragent_id=${this.filter.masteragent_id}`;
        }

        if(this.filter.agent_id) {
            urlTrend += `&agent_id=${this.filter.agent_id}`;
            urlTrendRange += `&agent_id=${this.filter.agent_id}`;
        }

        this.urlSubheader = urlSubheader;
        this.urlAgenti = urlAgenti;
        this.urlNegoziTabella = urlNegoziTabella;
        this.urlRegioni = urlRegioni;
        this.urlTrend = urlTrend;
        this.urlTrendRange = urlTrendRange;
        this.eoloChart.setUrl(this.urlTrendRange);
    }
}
