import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { VisiteService } from '@services/visite.service';
import { RequestService } from '@services/request.service';
import { AgentSelectComponent } from '@app/widgets/agent-select/agent-select.component';

@Component({
  selector: 'app-master-visite-index',
  templateUrl: './master-visite-index.component.html',
  styleUrls: ['./master-visite-index.component.css'],
})
export class MasterVisiteIndexComponent implements OnInit {
  @ViewChild('agentSelect') private agentSelect: AgentSelectComponent;


  sortColumn: string | null = null;  // Nome della colonna per l'ordinamento
  sortDirection: string = 'asc';  // Direzione dell'ordinamento

  columns: string[] = [
    'period_visits',
    'total_shops',
    'period_our_retailers',
    'period_other_retailers',
    'covered_cities',
    'period_cities',
  ];

  rows: {
    id_agente: number,
    agente: string,
    period_visits: number,
    total_shops: number,
    period_our_retailers: number,
    period_other_retailers: number,
    covered_cities: number,
    period_cities: number
  }[] = [];


  selectedDateRange = {
    from: '',
    to: '',
  };

  filterForm = {
    dateRange: '',
  };

  filter = {
    masteragent_id: '',
    role: '',
    channel: '',
    functional: '0',
  };

  // masteragent_id = null;
  // channel = null;

  // rows: [];
  global: any;

  defaultView = true;

  loading = false;
  laddaExcel = false;

  constructor(
    private spinner: NgxSpinnerService,
    private dateRangePickerOptions: DaterangepickerConfig,
    private service: VisiteService,
    private requestService: RequestService,
  ) {
    this.dateRangePickerOptions.settings.locale.format = 'DD/MM/YYYY';
    this.setDefaultRange();
  }

  ngOnInit() {
  }

  refresh() {
    this.loading = true;
    const params = {};

    if (this.filter.masteragent_id) {
      params['masteragent_id'] = this.filter.masteragent_id;
    }
    if (this.filter.role) {
      params['role'] = this.filter.role;
    }
    if (this.filter.channel) {
      params['channel'] = this.filter.channel;
    }
    if (this.filter.functional) {
      params['functional'] = this.filter.functional;
    }

    this.service.globalSituation(this.selectedDateRange.from, this.selectedDateRange.to, params).subscribe((data) => {
        this.rows = data.rows;
        this.global = data.global;

        this.loading = false;

      },
      (error) => {
        this.loading = false;
      });

  }

  sortData(column: string) {
    switch (column) {
      case 'period_visits':
      case 'total_shops':
      case 'period_our_retailers':
      case 'period_other_retailers':
      case 'covered_cities':
      case 'period_cities':
        this.handleSort(column);
        break;

      default:
        console.warn(`Colonna non supportata: ${column}`);
    }
  }

  handleSort(column: string) {
    if (!this.sortColumn || this.sortColumn !== column) {

      this.sortColumn = column;
      this.sortDirection = 'asc';
    } else {

      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    }

    this.sortDataByColumn(this.sortColumn, this.sortDirection);
  }

  sortDataByColumn(column: string, direction: string) {

    switch (column) {
      case 'period_visits':
        this.rows.sort((a, b) => this.compareValues(a.period_visits, b.period_visits, direction));
        break;

      case 'total_shops':
        this.rows.sort((a, b) => this.compareValues(a.total_shops, b.total_shops, direction));
        break;

      case 'period_our_retailers':
        this.rows.sort((a, b) => this.compareValues(a.period_our_retailers, b.period_our_retailers, direction));
        break;

      case 'period_other_retailers':
        this.rows.sort((a, b) => this.compareValues(a.period_other_retailers, b.period_other_retailers, direction));
        break;

      case 'covered_cities':
        this.rows.sort((a, b) => this.compareValues(a.covered_cities, b.covered_cities, direction));
        break;

      case 'period_cities':
        this.rows.sort((a, b) => this.compareValues(a.period_cities, b.period_cities, direction));
        break;

      default:

        console.warn(`Colonna non supportata: ${column}`);
    }
  }

  compareValues(valueA: number, valueB: number, direction: string): number {
    if (direction === 'asc') {
      return valueA - valueB;
    } else {
      return valueB - valueA;
    }
  }

  setDefaultRange() {
    this.setSelectedDateRange(moment().startOf('month'), moment());
  }

  setSelectedDateRange(from: Moment, to: Moment) {
    if (from.isSame(moment().startOf('month')) && to.isSame(moment())) {
      this.defaultView = true;
    } else {
      this.defaultView = false;
    }

    const selectedDateRange = this.getDateString(from, to);
    this.filterForm.dateRange = selectedDateRange;
    this.refresh();
  }

  exportList() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rows);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'export.xlsx');
  }


  downloadExcel() {
    this.laddaExcel = true;
    const params = {};

    if (this.filter.masteragent_id) {
      params['masteragent_id'] = this.filter.masteragent_id;
    }
    if (this.filter.channel) {
      params['channel'] = this.filter.channel;
    }
    this.service
      .globalSituation(this.selectedDateRange.from, this.selectedDateRange.to, params, true)
      .subscribe((data) => {
          this.requestService.downloadFile(data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          this.laddaExcel = false;
        },
        (error) => {
          this.laddaExcel = false;
        });
  }

  setMasterAgent($event: any) {
    this.filter.masteragent_id = $event;
    this.refresh();
  }

  setChannel($event: any) {
    this.filter.channel = $event;
    this.refresh();
  }

  private getDateString(startDate: any, endDate: any) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    const start = moment(startDate);
    const end = moment(endDate);
    this.selectedDateRange.from = start.format('YYYY-MM-DD');
    this.selectedDateRange.to = end.format('YYYY-MM-DD');
    return (
      start.format(localDateFormat) +
      this.dateRangePickerOptions.settings.locale.separator +
      end.format(localDateFormat)
    );
  }
}
