<app-spinner [status]="status"></app-spinner>

<ng-container *ngIf="this.status === 'hidden'">
    <div class="row" *ngIf="data">
        <div *ngFor="let col of data.firstRow; let i=index;" class="text-center {{col.size}}"
             [ngClass]="{'border-right': i < data.firstRow.length-1}">
            <div class="py-2" [ngClass]="{'box-hover-dark rounded': col.hasOwnProperty('url')}"
                 (click)="dbm.show(col.url, 'Dettaglio')">
                <img [src]="col.image" height="70px"/>
                <p class="lead" [innerHTML]="col.title"></p>
                <p [innerHTML]="col.subtitle"></p>
            </div>
        </div>
    </div>
</ng-container>