<div [ngBusy]="busy" class="row" style="padding: 40px">
  <div class="container-fluid">
    <div class="card w-100 pb-0">
      <div
        class="card-header border-bottom pb-0 d-flex justify-content-center align-items-center"
      >
        <h2 class="lead display-5 pb-2 pt-2" style="font-weight: bold">
          Scheda Anagrafica Regional
        </h2>
      </div>
      <div class="card-body bg-gradient-info">
        <div class="container-fluid">
          <div class="info-text">
            <p><i class="fa fa-user-circle"></i> Ragione Sociale</p>
            <span class="display-6" style="font-weight: bold">{{
              regional.ragsociale
            }}</span>
          </div>
          <div class="row">
            <div class="col-6 p-4">
              <div class="info-text">
                <p><i class="fa fa-user"></i> Login Code</p>
                <span class="lead" *ngIf="!edit.username"
                  >{{ regional.username }}
                  <i
                    (click)="edit.username = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                  ></i
                ></span>

                <i
                  class="fas fa-circle-notch fa-spin"
                  *ngIf="this.loading.username"
                ></i>

                <div
                  *ngIf="edit.username && !this.loading.username"
                  class="input-group input-group-sm"
                >
                  <input
                    [(ngModel)]="regional.username"
                    class="form-control"
                    type="text"
                  />
                  <div class="input-group-append">
                    <button
                      (click)="edit.username = false"
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-times mr-0"></i>
                    </button>
                    <button
                      (click)="
                        saveRegionalInfo(
                          { username: regional.username },
                          'username'
                        )
                      "
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-check mr-0"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 p-4">
              <div class="info-text">
                <p><i class="fa fa-mobile"></i> Cellulare</p>
                <span class="lead" *ngIf="!edit.cellulare"
                  >{{ regional.cellulare }}
                  <i
                    (click)="edit.cellulare = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                  ></i
                ></span>

                <i
                  class="fas fa-circle-notch fa-spin"
                  *ngIf="this.loading.cellulare"
                ></i>

                <div
                  *ngIf="edit.cellulare && !this.loading.cellulare"
                  class="input-group input-group-sm"
                >
                  <input
                    [(ngModel)]="regional.cellulare"
                    class="form-control"
                    type="text"
                  />
                  <div class="input-group-append">
                    <button
                      (click)="edit.cellulare = false"
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-times mr-0"></i>
                    </button>
                    <button
                      (click)="
                        saveRegionalInfo(
                          { cellulare: regional.cellulare },
                          'cellulare'
                        )
                      "
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-check mr-0"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 p-4">
              <div class="info-text">
                <p><i class="fa fa-envelope"></i> E-Mail</p>
                <span class="lead" *ngIf="!edit.email"
                  >{{ regional.email }}
                  <i
                    (click)="edit.email = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                  ></i
                ></span>
                <i
                  class="fas fa-circle-notch fa-spin"
                  *ngIf="this.loading.email"
                ></i>

                <div
                  *ngIf="edit.email && !this.loading.email"
                  class="input-group input-group-sm"
                >
                  <input
                    [(ngModel)]="regional.email"
                    class="form-control"
                    type="text"
                  />
                  <div class="input-group-append">
                    <button
                      (click)="edit.email = false"
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-times mr-0"></i>
                    </button>
                    <button
                      (click)="
                        saveRegionalInfo({ email: regional.email }, 'email')
                      "
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-check mr-0"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
