import {Component, OnInit, ViewChild} from '@angular/core';
import {Races, RacesPagination} from '@models/races';
import {MetaPagination} from '@models/meta-pagination';
import {RacesService} from '@services/races.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {RequestService} from '@services/request.service';

@Component({
    selector: 'app-races',
    templateUrl: './races.component.html',
    styleUrls: ['./races.component.css']
})
export class RacesComponent implements OnInit {
    busy: any;
    items: Races[] = [];
    pagination: MetaPagination;
    filter = {
        dealer: '',
        operator: '',
        type: '',
        month: '',
        year: ''
    };
    types = [];
    deleteOptions: ConfirmOptionsService;
    selectedData: Races;
    isUploadFileModal = false;
    @ViewChild('formTemplate') private formTemplate;

    constructor(private service: RacesService,
                private requestService: RequestService,
                private spinner: NgxSpinnerService,
                public modalService: NgbModal) {
        this.deleteOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });

    }

    ngOnInit(): void {
    }


    setSelectedDate(event: any) {
        this.filter.month = moment(event).format('MM');
        this.filter.year = moment(event).format('YYYY');
        this.loadData();
    }

    loadData() {
        this.loadTable();
        this.loadTypes();
    }

    loadTable(page = null) {
        this.spinner.show('table-races');
        const params = {};

        if (page) {
            params['page'] = page;
        }
        if (this.filter.dealer) {
            params['dealer_id'] = this.filter.dealer;
        }
        if (this.filter.operator) {
            params['operator_name'] = this.filter.operator;
        }
        if (this.filter.type) {
            params['type'] = this.filter.type;
        }
        if (this.filter.month) {
            params['month'] = this.filter.month;
        }
        if (this.filter.year) {
            params['year'] = this.filter.year;
        }

        this.service.getListOfItems(params).subscribe((response: RacesPagination) => {
            this.items = response.data;
            this.pagination = response._meta;
            this.spinner.hide('table-races');
        }, err => {
            this.spinner.hide('table-races');
        });
        // this.loadTypes();
    }

    showModal(data?: Races, isUploadFileModal = false) {
        this.selectedData = data;
        this.isUploadFileModal = isUploadFileModal;
        this.modalService.open(this.formTemplate, {size: 'md'});
    }

    delete(id: number) {
        this.service.deleteRaces(id).subscribe(() => {
            this.loadTable();
            this.service.successAlert('Deleted Service!');
        }, (err) => {
            this.service.errorAlert(err.message);
        });
    }

    loadTypes() {
        const params = {};
        // if (this.filter.type) {
        //     params['type'] = this.filter.type;
        // }
        // if (this.filter.operator) {
        //     params['operator_name'] = this.filter.operator;
        // }
        if (this.filter.month) {
            params['month'] = this.filter.month;
        }
        if (this.filter.year) {
            params['year'] = this.filter.year;
        }
        this.spinner.show('table-types');

        this.service.getTypes(params).subscribe((response) => {
            this.types = response;
            this.spinner.hide('table-types');
        }, err => {
            this.spinner.hide('table-types');
        });
    }

    refreshTable() {
        this.modalService.dismissAll();
        this.loadTable();
        this.loadTypes();
    }

    downloadDetail() {
        const params = {};
        // if (this.filter.dealer) {
        //     params['dealer_id'] = this.filter.dealer;
        // }
        // if (this.filter.operator) {
        //     params['operator_name'] = this.filter.operator;
        // }
        // if (this.filter.type) {
        //     params['type'] = this.filter.type;
        // }
        if (this.filter.month) {
            params['month'] = this.filter.month;
        }
        if (this.filter.year) {
            params['year'] = this.filter.year;
        }

        this.spinner.show('table-races');
        this.service.downloadDetail(params)
            .subscribe((response) => {
                this.spinner.hide('table-races');
                this.requestService.downloadFile(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            });
    }
}
