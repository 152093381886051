import {Component, OnInit} from '@angular/core';
import {KpiService} from '@services/kpi.service';
import {AuthenticationService} from '@services/authentication.service';
import * as moment from 'moment/moment';
import {FormControl, FormGroup} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {MasterPlan, Plan} from '@models/master-plan';
import {MasterPlanningService} from '@services/master-planning.service';

@Component({
    selector: 'app-master-kpi',
    templateUrl: './master-kpi.component.html',
    styleUrls: ['./master-kpi.component.css']
})
export class MasterKpiComponent implements OnInit {
    status = 'hidden';

    filterForm = {
        month: null,
        year: null,
        scope: 'general',
        scopeId: '',
        vendorId: ''
    };

    editPlan = new FormGroup({
        planValue: new FormControl(''),
    });

    popupConfig: any;

    overview = [];

    currentPlanScope = 'general';

    constructor(private kpiService: KpiService,
                private spinner: NgxSpinnerService,
                private masterPlanningService: MasterPlanningService,
                public authService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.filterForm.month = moment().format('MM');
        this.filterForm.year = moment().format('YYYY');
        this.filterForm.scope = 'general';
    }

    load() {
        if(!this.filterForm.vendorId) {
            return;
        }

        this.status = 'loading';
        this.kpiService.overview(this.filterForm)
            .subscribe((data) => {
                this.overview = data;
                this.currentPlanScope = this.filterForm.scopeId !== null ? this.filterForm.scope : 'general';
                this.status = 'hidden';
            }, (error) => {
                this.status = 'error';
            });
    }

    setScopeId(event) {
        this.filterForm.scopeId = event;
        this.load();
    }

    setSelectedDate($event: any) {
        this.filterForm.month = moment($event).format('MM');
        this.filterForm.year = moment($event).format('YYYY');
        this.load();
    }

    isValidFilterForm() {
        if (this.filterForm.scope !== 'general' && this.filterForm.scope && !this.filterForm.scopeId) {
            return true;
        }
        return false;
    }

    setVendorId($event: any) {
        this.filterForm.vendorId = $event;
        this.load();
    }

    focusInput() {
        document.getElementById('input').focus();
    }

    showPopupNote() {
        const item = this.popupConfig.selectedItem;
        // if (item.up_to_general && item.up_to_general >= 0) {
        //     return 'There are ' + item.up_to_general + ' left to achieve general value.';
        // } else if (item.up_to_general && item.up_to_general < 0) {
        //     return 'You are in line with the schedule.';
        // }
        return '';
    }

    closeEditTable() {
        this.editPlan.controls.planValue.setValue(0);
        this.popupConfig.popup.close();
        this.spinner.hide('planSpinner');
    }

    openEditTable(popOver: any, item, newPlanValue, rowIndex) {
        if (item.plan) {
            this.editPlan.controls.planValue.setValue(parseFloat(item.plan.value));
        } else {
            this.editPlan.controls.planValue.setValue('');
        }

        this.popupConfig = {
            selectedItem: item,
            month: this.filterForm.month,
            year: this.filterForm.year,
            targetType: name,
            popup: popOver,
            rowIndex
        };
        popOver.open();
    }

    changePlan() {
        this.spinner.show('planSpinner');

        const newPlanValue = this.editPlan.controls.planValue.value;
        if (this.popupConfig.selectedItem.id) {
            return this.masterPlanningService.updatePlan(this.popupConfig.selectedItem, newPlanValue).subscribe((response: Plan) => {
                this.spinner.hide('planSpinner');
                this.popupConfig.popup.close();
                this.overview[this.popupConfig.rowIndex].target = response.value;
            }, err => {
                this.handleUpdateError(this.popupConfig.popup, err);
            });
        }
        return this.masterPlanningService.createPlanFromKpi(this.filterForm.vendorId, this.popupConfig.selectedItem, newPlanValue).subscribe((response: Plan) => {
            this.spinner.hide('planSpinner');
            this.popupConfig.popup.close();
            this.overview[this.popupConfig.rowIndex].target = response.value;
        }, err => {
            this.handleUpdateError(this.popupConfig.popup, err);
        });
    }

    handleUpdateError(popOver: any, err?) {
        this.spinner.hide('planSpinner');
        this.popupConfig = {};
        popOver.close();
        this.masterPlanningService.errorAlert(err);
    }
}
