import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Vendor} from '@app/models/vendor';
import {VendorService} from '@app/services/vendor.service';

@Component({
    selector: 'app-vendor-select',
    templateUrl: './vendor-select.component.html',
    styleUrls: ['./vendor-select.component.scss'],
})
export class VendorSelectComponent implements OnInit {
    @Input()
    pickFirst = false;
    @Input()
    segments = false;
    @Input()
    multiple = false;
    @Input()
    small = false;
    @Input()
    outputLabel = false;
    model = null;
    data: Vendor[] = [];

    @Output() onChange = new EventEmitter();

    constructor(private service: VendorService) {
    }

    ngOnInit() {
        if(this.segments) {
            this.service.getSegments({fields: 'id,name'}).subscribe((data) => {
                this.data = new Vendor().fromArray(data);

                if(this.pickFirst && this.data.length > 0) {
                    this.model = this.data[0].id;
                    this.onChange.emit(this.model);
                }
            });
            return;
        }
        else {
            this.service.getList({fields: 'id,name'}).subscribe((data) => {
                this.data = new Vendor().fromArray(data);

                if (this.pickFirst && this.data.length > 0) {
                    this.model = this.data[0].id;
                    this.emit(this.model);
                }
            });
        }
    }

    emit($event: any) {
        let value = $event;

        if(this.outputLabel) {
            value = this.data.find((item) => {
                return item.id == $event;
            }).name
        }
        this.onChange.emit(value);
    }
}
