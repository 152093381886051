import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AgentVisitsService} from '@services/agent-visits.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import {Subscription} from 'rxjs';
import {MetaPagination} from '@app/models/meta-pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import * as XLSX from 'xlsx';


@Component({
    selector: 'app-report-visite',
    templateUrl: './report-visite.component.html',
    styleUrls: ['./report-visite.component.css']
})
export class ReportVisiteComponent implements OnInit {

    ordersPagination: MetaPagination;
    visit: any = [];
    visitAffiliati: any = [];
    visitPotenziali: any = [];
    activeTab: string;
    activityStatusMapping = {
        0: 'No',
        1: 'Si'
    };

    maxDate;
    id: any;
    defaultView = true;
    busy: Subscription;

    filter = {
        date: '',
        prospect: '',
    };

    selectedDateRange = {
        from: '',
        to: ''
    };

    filterForm = {
        dateRange: '',
    };
    photos = [];

    constructor(
        private agentVisitsService: AgentVisitsService,
        public route: ActivatedRoute,
        private dateRangePickerOptions: DaterangepickerConfig,
        private spinner: NgxSpinnerService,
    ) {
        this.maxDate = {
            year: parseInt(moment().format('YYYY')),
            month: parseInt(moment().format('MM')),
            day: parseInt(moment().format('DD'))
        };
        this.id = this.route.snapshot.paramMap.get('id');
        this.dateRangePickerOptions.settings.locale.format = 'DD/MM/YYYY';

    }

    ngOnInit(): void {
        this.setDefaultRange();
    }

    loadVisitList() {
        this.spinner.show('table-orders');
        const params = {};
        if (this.ordersPagination?.currentPage) {
            params['page'] = this.ordersPagination?.currentPage;
        }

        if (this.filterForm.dateRange) {
            params['from'] = this.selectedDateRange.from;
            params['to'] = this.selectedDateRange.to;
        }

        // Aggiungi il filtro per la tab 'tabPotenziali'
        if (this.activeTab === 'tabPotenziali') {
            params['prospect'] = null; // Filtro per dealer_id uguale a null
        } else if (this.filter.prospect) {
            params['prospect'] = this.filter.prospect;
        }

        this.agentVisitsService.getVisitList(params).subscribe((res) => {
            this.visit = res.data;
            this.ordersPagination = res._meta;
            this.spinner.hide('table-orders');

            console.log('Lista completa', res.data);


        }, err => {
            this.spinner.hide('table-orders');
            console.error('Errore durante la chiamata API:', err);

        });
    }


    onTabSelect(event) {
        this.activeTab = event.nextId;
        console.log('Tab', this.activeTab);

        this.reload();
    }

    reload () {
        if(this.activeTab === 'tabFoto') {
            this.loadFotoList();
        }
        else {
            this.loadVisitList();
        }
    }

    setDefaultRange() {
        this.setSelectedDateRange(moment().startOf('month'), moment());
    }

    setSelectedDateRange(from: Moment, to: Moment) {
        if (from.isSame(moment().startOf('month')) && to.isSame(moment())) {
            this.defaultView = true;
        } else {
            this.defaultView = false;
        }

        const selectedDateRange = this.getDateString(from, to);
        this.filterForm.dateRange = selectedDateRange;

        this.reload();
    }

    private getDateString(startDate: any, endDate: any) {
        const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
        const start = moment(startDate);
        const end = moment(endDate);
        this.selectedDateRange.from = start.format('YYYY-MM-DD');
        this.selectedDateRange.to = end.format('YYYY-MM-DD');
        return (
            start.format(localDateFormat) +
            this.dateRangePickerOptions.settings.locale.separator +
            end.format(localDateFormat)
        );
    }

    exportReport() {
        const params = {
            'per-page': 0,
        };

        if (this.filterForm.dateRange) {
            params['from'] = this.selectedDateRange.from;
            params['to'] = this.selectedDateRange.to;
        }

        // Aggiungi il filtro per la tab 'tabPotenziali'
        if (this.activeTab === 'tabPotenziali') {
            params['prospect'] = null; // Filtro per dealer_id uguale a null
        } else if (this.filter.prospect) {
            params['prospect'] = this.filter.prospect;
        }

        this.agentVisitsService.getVisitList(params).subscribe((data) => {
            let rows = data.data;
            let output = rows.map((row) => {
                return {
                    'Login Code': row.dealer_id == null ? 'Potenziale Partner' : row.login_code,
                    'Negozio': row.shop,
                    'District': row.agent_name,
                    'Sottocanale': row.sottocanale,
                    'Regione': row.region_name,
                    'Provincia': row.province_name,
                    'Città': row.city_name,
                    'Data': row.date,
                    'Attività chiusa': row.is_activity_closed == 0 ? 'No' : 'Si',
                };
            });

            const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'export-report-visite.xlsx');
        });
    }


    loadFotoList(page = 1) {
        const params = {};

        params['page'] = page;

        if (this.filterForm.dateRange) {
            params['from'] = this.selectedDateRange.from;
            params['to'] = this.selectedDateRange.to;
        }

        this.spinner.show('table-orders');
        this.agentVisitsService
            .getZipList(params)
            .subscribe((res) => {
                this.photos = res.data;
                if(this.ordersPagination.currentPage !== page) {
                    this.ordersPagination = res._meta;
                }
                this.spinner.hide('table-orders');
        }, err => {
            this.spinner.hide('table-orders');
            console.error('Errore durante la chiamata API:', err);
        });
    }
}

