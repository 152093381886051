<form class="form-inline">
    <div class="form-group position-absolute">
        <div class="input-group">
            <input name="datepicker"
                   class="form-control form-control-sm"
                   ngbDatepicker
                   #datepicker="ngbDatepicker"
                   [autoClose]="'outside'"
                   [footerTemplate]="footerTemplate"
                   (dateSelect)="onDateSelection($event)"
                   [displayMonths]="2"
                   [dayTemplate]="t"
                   outsideDays="hidden"
                   [startDate]="fromDate!">
            <ng-template #t let-date let-focused="focused">
                <span class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
            </ng-template>
        </div>
    </div>
    <ng-template #footerTemplate>
        <hr class="my-0">
        <button class="btn btn-primary btn-sm m-2 float-left" (click)="setDate('MTD')">MTD</button>
        <button class="btn btn-primary btn-sm m-2 float-left" (click)="setDate('YTD')">YTD</button>
        <button class="btn btn-primary btn-sm m-2 float-left" (click)="setDate('Trimestre 1')">Trimestre 1</button>
        <button class="btn btn-primary btn-sm m-2 float-left" (click)="setDate('Trimestre 2')">Trimestre 2</button>
        <button class="btn btn-primary btn-sm m-2 float-left" (click)="setDate('Trimestre 3')">Trimestre 3</button>
        <button class="btn btn-primary btn-sm m-2 float-left" (click)="setDate('Trimestre 4')">Trimestre 4</button>
    </ng-template>
    <div class="row">
        <div class="col-12 col-xl-6">
            <div class="form-group form-group-sm">
                <div class="input-group">
                    <input #dpFromDate
                           class="form-control-sm form-control" placeholder="yyyy-mm-dd"
                           name="dpFromDate"
                           [value]="formatter.format(fromDate)"
                           (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                    <div class="input-group-append">
                        <button class="btn btn-secondary btn-sm" (click)="datepicker.toggle()" type="button"><i
                                class="fas fa-calendar-alt text-white mr-0"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-6">
            <div class="form-group">
                <div class="input-group">
                    <input #dpToDate
                           class="form-control form-control-sm" placeholder="yyyy-mm-dd"
                           name="dpToDate"
                           [value]="formatter.format(toDate)"
                           (input)="toDate = validateInput(toDate, dpToDate.value)">
                    <div class="input-group-append">
                        <button class="btn btn-secondary btn-sm" (click)="datepicker.toggle()" type="button"><i
                                class="fas fa-calendar-alt text-white mr-0"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
