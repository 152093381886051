<div class="row">
    <div class="col-8">
        <h1 class="display-6"><img src="../../../assets/img/flats/email_2.png" height="40" /> Mail automatiche per i negozi</h1>
    </div>
    <div class="col-4">
        <input type="text" class="form-control form-control-sm" (dateSelect)="refresh()" [(ngModel)]="date" (focus)="d.toggle()" ngbDatepicker #d="ngbDatepicker"/>
    </div>
    <div class="col-12">
        <hr class="mt-1">
    </div>

    <div class="col-3">
        <app-master-email-card
                title="Prima Attivazione"
                description="Benvenuto ai negozi che inseriscono la loro prima attivazione"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/prima_attivazione.png"
                [details]="data.nuovi_attivatori"
                [loading]="loading"
                [date]="date"
                slug="prima-attivazione"
        >
        </app-master-email-card>
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Improduttivi"
                description="Negozi che erano produttivi la settimana scorsa ma non questa"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/improduttivi.png"
                [details]="data.improduttivi"
                [loading]="loading"
                [date]="date"
                slug="improduttivi"
        >
        </app-master-email-card>
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Ultima soglia"
                description="Mail di complimenti ai negozi che hanno raggiunto l'ultima soglia"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/soglia_raggiunta.png"
                [details]="data.soglia_raggiunta"
                [loading]="loading"
                [date]="date"
                slug="ultima-soglia"
        >
        </app-master-email-card>
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Prossimo alla soglia"
                description="Mail di avviso ai negozi che sono prossimi alla soglia successiva"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/prossimo_soglia.png"
                [details]="data.prossimo_soglia"
                [loading]="loading"
                [date]="date"
                slug="prossima-soglia"
        >
        </app-master-email-card>
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Invito a fatturare"
                description="Mail di avviso ai negozi che è disponibile l'invito a fatturare"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/invito_a_fatt.png"
                [details]="data.invito_a_fatturare"
                [loading]="loading"
                [date]="date"
                slug="invito-a-fatturare"
        >
        </app-master-email-card>
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Plafond in esaurimento"
                description="Negozi con plafond sotto la soglia di 20€"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/plafond-min.png"
                [details]="data.plafond"
                [loading]="loading"
                [date]="date"
                slug="plafond"
        >
        </app-master-email-card>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <h1 class="display-6"><img src="../../../assets/img/flats/email_2.png" height="40" /> Mail automatiche per la Direzione & Key Account</h1>
    </div>
    <div class="col-12">
        <hr class="mt-1">
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Analisi Intelligente: 1a mail"
                description="Analisi di zona parlante con interventi da effettuare"
                image="https://kdistribution.it/wp-content/uploads/2021/10/analisi_1.png"
                [details]="data.analisi_iniziale"
                [loading]="loading"
                [date]="date"
                slug="analisi-iniziale"
        >
        </app-master-email-card>
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Analisi Intelligente: 2a mail"
                description="Analisi cambiamenti e interventi effettuati"
                image="https://kdistribution.it/wp-content/uploads/2021/10/analisi_2.png"
                [details]="data.analisi_avanzamento"
                [loading]="loading"
                [date]="date"
                slug="analisi-avanzamento"
        >
        </app-master-email-card>
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Analisi Intelligente: Report"
                description="Report di fine mese per valutare l'operato del key account"
                image="https://kdistribution.it/wp-content/uploads/2021/10/analisi_3.png"
                [details]="data.analisi_report"
                [loading]="loading"
                [date]="date"
                slug="analisi-report"
        >
        </app-master-email-card>
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Avanzamento"
                description="Produzione aggiornata ogni ora con proiezione vs il target"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/avanzamento.png"
                [details]="data.avanzamento"
                [loading]="loading"
                [date]="date"
                slug="avanzamento"
        >
        </app-master-email-card>
    </div>
    <div class="col-3">
        <app-master-email-card
                title="Confronto oggi vs ieri"
                description="Classifica per Area Manager e Key account oggi vs ieri"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/versus.png"
                [details]="data.avanzamento_versus"
                [loading]="loading"
                slug="avanzamento-versus"
                [date]="date"
        >
        </app-master-email-card>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <h1 class="display-6"><img src="../../../assets/img/flats/whatsapp.png" height="40" /> Whatsapp automatici per i Key Account</h1>
    </div>
    <div class="col-12">
        <hr class="mt-1">
    </div>

    <div class="col-3">
        <app-master-wapp-card
                title="Negozi prossimi alla soglia"
                description="Il key account riceve ogni ora i negozi a cui mancano poche sim per la soglia successiva"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/prima_attivazione.png"
                [details]="data.soglie_kena"
                [loading]="loading"
                [date]="date"
                slug="soglie_kena"
        >
        </app-master-wapp-card>
    </div>
    <div class="col-3">
        <app-master-wapp-card
                title="Negozi che stanno terminando il plafond"
                description="Il key account riceve ogni ora i negozi il cui plafond è sceso sotto i 20€"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/improduttivi.png"
                [details]="data.no_plafond"
                [loading]="loading"
                slug="no_plafond"
                [date]="date"
        >
        </app-master-wapp-card>
    </div>
    <div class="col-3">
        <app-master-wapp-card
                title="Ordini spediti"
                description="Appena un'ordine viene gestito il key account riceve la conferma con il tracking"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/improduttivi.png"
                [details]="data.ordini_sim"
                [loading]="loading"
                slug="ordini_sim"
                [date]="date"
        >
        </app-master-wapp-card>
    </div>
    <div class="col-3">
        <app-master-wapp-card
                title="Plafond gestiti"
                description="Appena accreditiamo il plafond il key account riceve un messagio di conferma"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/improduttivi.png"
                [details]="data.ordini_plafond"
                [loading]="loading"
                slug="ordini_plafond"
                [date]="date"
        >
        </app-master-wapp-card>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <h1 class="display-6"><img src="../../../assets/img/flats/whatsapp.png" height="40" /> Whatsapp automatici per il Backoffice</h1>
    </div>
    <div class="col-12">
        <hr class="mt-1">
    </div>

    <div class="col-3">
        <app-master-wapp-card
                title="Nuovo ticket"
                description="Quando riceviamo un nuovo ticket, inviamo l'anteprima sul gruppo dell'ufficio"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/prima_attivazione.png"
                [details]="data.tickets"
                [loading]="loading"
                [date]="date"
                slug="tickets"
        >
        </app-master-wapp-card>
    </div>
    <div class="col-3">
        <app-master-wapp-card
                title="Nuovo messaggio su Whatsapp"
                description="Quando un cliente ci contatta su Whatsapp inviamo una notifica sul gruppo dell'ufficio"
                image="https://ewr1.vultrobjects.com/mgroup/images/mails/kena/improduttivi.png"
                [details]="data.nuovi_messaggi"
                [loading]="loading"
                slug="nuovi_messaggi"
                [date]="date"
        >
        </app-master-wapp-card>
    </div>
</div>
