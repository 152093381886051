import {Injectable} from '@angular/core';
import {RequestService} from '@services/request.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VisiteService {
    ENDPOINT = 'visites';

    constructor(public request: RequestService) {
    }

    public globalSituation(from, to, params?,  download = false): Observable<any> {
        
        if (download) {
            const downloadUrl = `${this.ENDPOINT}/global-situation/${from}/${to}/1`;
            return this.request.get(downloadUrl, params, { responseType: 'blob' });
        }

        return this.request.get( this.ENDPOINT + `/global-situation/${from}/${to}`, params);
    }
    
    resultsDetail(from, to, id, event) {
        return this.request.get(`${this.ENDPOINT}/results-detail/${from}/${to}/${id}/${event}`);
    }

    salesAgentOverview(from, to, id = null, download = false) {
        if (id) {
            if(download) {
                return this.request.get(`${this.ENDPOINT}/sales-agent-overview/${from}/${to}/${id}/1`, {}, {responseType: 'blob'});
            }

            return this.request.get(`${this.ENDPOINT}/sales-agent-overview/${from}/${to}/${id}`);
        }
        return this.request.get(`${this.ENDPOINT}/sales-agent-overview/${from}/${to}`);
    }

    salesPhotoAgentOverview( id = null, download = false) {
        if (id) {
            if(download) {
                return this.request.get(`${this.ENDPOINT}/sales-agent-overview/${id}/1`, {}, {responseType: 'blob'});
            }

            return this.request.get(`${this.ENDPOINT}/sales-agent-overview/${id}`);
        }
        return this.request.get(`${this.ENDPOINT}/sales-agent-overview/`);
    }

    salesAgentCalendar(period, id = null) {
        if (id) {
            return this.request.get(`${this.ENDPOINT}/sales-agent-calendar/${period}/${id}`);
        }
        return this.request.get(`${this.ENDPOINT}/sales-agent-calendar/${period}`);
    }

    retailersNeverVisited(id) {
        return this.request.get(`${this.ENDPOINT}/retailers-never-visited/${id}`);
    }

    salesAgentPlanning(id = null, params?) {
        if (id) {
            return this.request.get(`${this.ENDPOINT}/sales-agent-planning`, params);
        }
        return this.request.get(`${this.ENDPOINT}/sales-agent-planning/${id}`, params);
    }
}
