<div class="card bg-gradient-dark">
    <div class="card-body">
        <div class="d-flex align-items-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1" src="assets/img/flats/target.png"
                 width="160">
            <div class="flex-1 px-4">
                <h1 class="display-4">KPI &amp; Targets</h1>
                <hr>
                <div class="row align-items-end">
                    <div class="col-lg-2 col-12">
                        <label translate>Periodo</label>
                        <app-month-range-picker (selected)="setSelectedDate($event)"
                                                [singleMonthPicker]="true"></app-month-range-picker>
                    </div>

                    <div class="col-lg-2 col-12">
                        <label>Operatore</label>
                        <app-vendor-select [segments]="true" (onChange)="setVendorId($event)" [pickFirst]="true"></app-vendor-select>
                    </div>
                    <div class="col-lg-2 col-12" *show="['operator','master', 'masteragent']">
                        <label translate>Planning Center</label>
                        <ng-select (ngModelChange)="filterForm.scopeId=''" [(ngModel)]="filterForm.scope"
                                   [markFirst]="true" [searchable]="false" [clearable]="false"
                                   bindLabel="label" bindValue="key">
                            <ng-container>
                                <ng-option *show="['operator','master', 'masteragent']" translate value="general">
                                    General
                                </ng-option>
                                <ng-option *show="['operator','master']" translate value="masteragent">Master Agent
                                </ng-option>
                                <ng-option *show="['operator','master', 'masteragent']" translate value="agent">Agent
                                </ng-option>
                            </ng-container>
                        </ng-select>
                    </div>
                    <div class="col-lg-4 col-12" *show="['operator','master', 'masteragent']">
                        <div *ngIf="filterForm.scope=='masteragent'">
                            <label translate>Master Agent</label>
                            <app-master-agent-select (onChange)="setScopeId($event)">
                            </app-master-agent-select>
                        </div>
                        <div *ngIf="filterForm.scope=='agent'">
                            <label translate>Agent</label>
                            <app-agent-select (onChange)="setScopeId($event)">
                            </app-agent-select>
                        </div>
                    </div>
                    <div class="col-lg-2 mt-2 mt-lg-auto mb-0 col-12">
                        <button (click)="load()" [disabled]="isValidFilterForm()"
                                class="btn btn-info btn-block"
                                translate>Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="card">
    <div class="card-body p-0">
        <app-spinner [status]="status"></app-spinner>

        <table class="table" *ngIf="status === 'hidden'">
            <tr>
                <th>KPI</th>
                <th>Segmento</th>
                <th class="text-right" translate>Inserito</th>
                <th class="text-right">Target</th>
                <th>Progresso</th>
                <th class="text-center">Raggiunto</th>
            </tr>
            <tr *ngFor="let row of overview; let i=index;">
                <td>{{row.title}}</td>
                <td>{{row.label}}</td>
                <td class="text-right">{{row.current}}</td>
                <td class="text-right">
                    <ng-container *ngIf="currentPlanScope === 'agent'">
                        <span *show="['operator','agent']">{{row.target}}</span>
                        <button type="button" class="btn btn-link p-0 d-block w-100 h-100 text-right"
                                *show="['master', 'masteragent']"
                                (click)="openEditTable(editPopover, row.plan, row.target > 0, i)"
                                [ngbPopover]="popContent" popoverTitle="Cambia valore" autofocus
                                (shown)="focusInput()"
                                autoClose="outside" triggers="manual"
                                #editPopover="ngbPopover">
                            <span>{{row.target}}</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="currentPlanScope != 'agent'">
                        <span *show="['operator','agent','masteragent']">{{row.target}}</span>
                        <button type="button" class="btn btn-link p-0 d-block w-100 h-100 text-right"
                                *show="['master']"
                                (click)="openEditTable(editPopover, row.plan, row.target > 0, i)"
                                [ngbPopover]="popContent" popoverTitle="Cambia valore" autofocus
                                (shown)="focusInput()"
                                autoClose="outside" triggers="manual"
                                #editPopover="ngbPopover">
                            <span>{{row.target}}</span>
                        </button>
                    </ng-container>
                </td>
                <td>
                    <div class="progress border rounded">
                        <div class="progress-bar bg-gradient-info" aria-valuenow="100" aria-valuemin="0"
                             aria-valuemax="100" [style.width.%]="row.progress">{{row.progress}}%
                        </div>
                    </div>
                </td>
                <td class="text-center">
                    <i *ngIf="row.complete === true" class="fas fa-circle text-success"></i>
                    <i *ngIf="row.complete === false" class="fas fa-circle text-danger"></i>
                </td>
            </tr>
        </table>
    </div>
</div>

<ng-template #popContent>
    <ngx-spinner [fullScreen]="false" bdColor="rgba(255, 255, 255, .7)"
                 color="#333333" name="planSpinner"
                 class="h-100 w-100"
                 type="ball-clip-rotate-multiple"
                 size="small">
    </ngx-spinner>
    <p class="text-muted" translate>{{showPopupNote()}}</p>
    <form class="d-flex align-items-center my-2" (ngSubmit)="changePlan()" [formGroup]="editPlan">
        <div class="form-group mr-1  my-auto ">
            <input type="number" min="0" max="10000000"
                   class="pl-2 form-control" id="input"
                   oninput="validity.valid|| (value=value.slice(0, -1))"
                   formControlName="planValue">
        </div>
        <button class="btn btn-info mr-1" type="submit">
            <i class="fa fa-check m-0"></i></button>
        <button class="btn btn-light"
                (click)="closeEditTable()"><i
                class="fa fa-times m-0"></i>
        </button>
    </form>
</ng-template>