<div class="row" [ngBusy]="busy">
    <div class="col-12">
        <div class="card card-main-header bg-gradient-1">
            <div class="card-body">
                <img class="bg-gradient-light" src="/assets/img/flats/files.png">
                <div class="card-content row align-items-center">
                    <div class="col">
                        <h1>Documenti</h1>
                        <hr>
                        <h2 class="mb-0">Qui puoi consultare tutti i documenti utili per il tuo lavoro.</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 col-lg-3">
        <div class="card mg-theme full">
            <div class="card-header">
                <h1>
                    Cartelle
                </h1>
                <img class="float-right" src="/assets/img/flats/folder.png">
            </div>
            <div class="card-body p-0">
                <ul class="nav flex-column menu">
                    <li class="nav-item" >
                        <a class="nav-link"><i class="fa fa-caret-right"></i> Tutti i documenti</a>
                    </li>
                    <li class="nav-item" *ngFor="let categoria of currentCategories">
                        <a class="nav-link"><i class="fa fa-caret-right"></i> {{ categoria.nome }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-9">
        <div class="card mg-theme">
            <div class="card-header">
                <h1>
                    Documenti
                </h1>
                <p class="link" (click)="showLogs()">Mostra log invii</p>
                <img class="float-right" src="/assets/img/flats/files.png">
            </div>
            <div class="card-body">
                <app-documents-modal #showModal></app-documents-modal>
                <div class="row">
                    <ol class="breadcrumb bg-white lead">
                        <li class="breadcrumb-item"><a class="link" (click)="currentCategory = null;">Home</a>
                        </li>
                        <li class="breadcrumb-item"
                            *ngFor="let crumb of breadcrumbs" (click)="selectCategory(crumb)">
                           <a class="link">{{ crumb.nome }}</a>
                        </li>
                    </ol>
                    <hr>
                </div>

                <div class="row">

                    <div class="col-6 col-lg-2" *ngIf="currentCategory" (click)="goBack()">
                        <div class="document">
                            <img [src]="'flats/left-arrow.png' | assets"/>
                            <p>Torna indietro</p>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2" *ngFor="let category of currentCategories">
                        <div class="document" (click)="selectCategory(category)">
                            <img [src]="'flats/folder.png' | assets"/>
                            <p>{{ category.nome }}</p>
                        </div>

                        <div class="text-center" *show="['master']">
                            <button class="btn btn-circle bg-gradient-info btn-circle-xs mr-2" (click)="showUpdateCategoryModal(category); $event.stopPropagation();"><i class="fas fa-pencil-alt"></i></button>
                            <button class="btn btn-circle bg-gradient-danger btn-circle-xs" [swal]="categoryConfirmDeleteOptions" (confirm)="deleteCategory(category)"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2" *show="['master']" (click)="showCreateCategoryModal()">
                        <div class="document">
                            <img [src]="'flats/plus.png' | assets"/>
                            <p>Aggiungi Categoria</p>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="currentCategory">
                    <hr>
                    <div class="col-12">
                        <h1 class="display-6">Documenti in {{currentCategory.nome}}</h1>
                    </div>
                    <div class="col-6 col-lg-2" *show="['master']" (click)="showCreateDocumentModal(currentCategory)">
                        <div class="document">
                            <img [src]="'flats/cloud-computing.png' | assets"/>
                            <p>Carica Documento</p>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2" *ngFor="let document of currentCategory.documents">
                        <div class="document" *ngIf="!document.download" (click)="showModal.showDocument(document.file_path)">
                            <img src="/assets/img/flats/document.png"/>
                            <p>{{ document.nome }}</p>
                            <span>{{ document.created | date: 'longDate' }}</span>
                        </div>
                        <div class="document" *ngIf="document.download" (click)="goToLink(document.file_path)">
                            <img src="/assets/img/flats/document.png"/>
                            <p>{{ document.nome }}</p>
                            <span>{{ document.created | date: 'longDate' }}</span>
                        </div>
                        <div class="text-center" *show="['master']">
                            <button class="btn btn-circle bg-gradient-info btn-circle-xs mr-2" *ngIf="false" (click)="showUpdateDocumentModal(document); $event.stopPropagation();"><i class="fas fa-pencil-alt"></i></button>
                            <button class="btn btn-circle bg-gradient-danger btn-circle-xs" [swal]="documentConfirmDeleteOptions" (confirm)="deleteDocument(document)"><i class="fas fa-times"></i></button>
                            <button class="btn btn-circle bg-gradient-info btn-circle-xs ml-2" [swal]="documentSendOptions" (confirm)="sendDocument($event, document)" ><i class="fas fa-paper-plane"></i></button>
                        </div>
                        <div class="text-center" *show="['agent', 'masteragent']">
                            <button class="btn btn-circle bg-gradient-info btn-circle-xs ml-2" [swal]="documentSendOptions" (confirm)="sendDocument($event, document)" ><i class="fas fa-paper-plane"></i></button>
                        </div>
                    </div>
                </div>
                
                <ng-container *ngIf="this.filtro">
                    <div class="row">
                        <div class="col-6 col-lg-2"
                             *ngFor="let documento of documenti">
                            <div class="document" *ngIf="!documento.download" (click)="showModal.showDocument(documento.file_path)">
                                <img src="/assets/img/flats/document.png"/>
                                <p>{{ documento.nome }}</p>
                                <span>{{ documento.created | date: 'longDate' }}</span>
                            </div>
                            <div class="document" *ngIf="documento.download"  (click)="goToLink(documento.file_path)">
                                <img src="/assets/img/flats/document.png"/>
                                <p>{{ documento.nome }}</p>
                                <span>{{ documento.created | date: 'longDate' }}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>


<ng-template #categoryFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title">{{ categoryModalTitle }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyCategoryFormModal">
        <form [formGroup]="categoryForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="lead">Nome</label>
                        <input [(ngModel)]="selectedCategory.nome" formControlName="nome" type="text"
                            class="form-control" />
                        <val-errors controlName="nome">
                            <ng-template valError="required">
                                Il nome della categoria è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="lead">Visibile solo se abilitato con questo brand</label>
                        <input [(ngModel)]="selectedCategory.id_operatore" formControlName="id_operatore" type="text"
                            class="form-control" />
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button class="btn btn-success btn-block" [disabled]="!this.categoryForm.valid"
                        (click)="saveCategory(selectedCategory)">
                        <i class="fa fa-check-circle mr-2"></i>Salva
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #documentFormTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title">{{ documentModalTitle }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" [ngBusy]="busyDocumentFormModal">
        <form [formGroup]="documentForm" *ngIf="selectedDocument">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label class="lead">Titolo</label>
                        <input [(ngModel)]="selectedDocument.nome" formControlName="nome" type="text"
                            class="form-control" />
                        <val-errors controlName="nome">
                            <ng-template valError="required">
                                Il nome è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="lead">Scegli il file</label>
                        <div class="custom-file">
                            <input formControlName="file" type="file"
                                class="custom-file-input" id="validatedCustomFile"
                                (change)="handleFileInput($event.target.files)">
                            <label class="custom-file-label" for="validatedCustomFile">{{ fileName }}</label>
                        </div>
                        <val-errors controlName="file">
                            <ng-template valError="required">
                                Il file è obbligatorio
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="lead">Descrizione</label>
                        <textarea [(ngModel)]="selectedDocument.descrizione" formControlName="descrizione"
                            class="form-control" rows="3"></textarea>
                        <val-errors controlName="descrizione">
                            <ng-template valError="required">
                                La descrizione è obbligatoria
                            </ng-template>
                        </val-errors>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <button class="btn btn-success btn-block" [disabled]="!this.documentForm.valid"
                        (click)="saveDocument(selectedDocument)">
                        <i class="fa fa-check-circle mr-2"></i> Salva
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-template>