import {Injectable} from '@angular/core';
import {RequestService} from './request.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {

    ENDPOINT = 'documents';

    constructor(public request: RequestService) {

    }

    saveDocument(data) {
        return this.request.post(`${this.ENDPOINT}`, data);
    }

    updateDocument(id, data) {
        return this.request.put(`${this.ENDPOINT}/${id}`, data);
    }

    deleteDocument(id) {
        return this.request.delete(`${this.ENDPOINT}/${id}`);
    }

    getCategories() {
        return this.request.get(`${this.ENDPOINT}/category?expand=subcategories,documents`);
    }

    saveCategory(data) {
        return this.request.post(`${this.ENDPOINT}/category?expand=subcategories,documents`, data);
    }

    updateCategory(id, data) {
        return this.request.post(`${this.ENDPOINT}/category/${id}?expand=subcategories,documents`, data);
    }

    deleteCategory(id) {
        return this.request.delete(`${this.ENDPOINT}/category/${id}`);
    }

    sendDocument(id: number, $event: any) {
        return this.request.post(`${this.ENDPOINT}/send/${id}`, {email: $event});
    }
}
