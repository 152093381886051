import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MetaPagination } from "@app/models/meta-pagination";
import { WispPagination } from "@app/models/wisp";
import { WispService } from "@app/services/wisp.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DaterangepickerConfig } from "ng2-daterangepicker";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import * as XLSX from "xlsx";
import { AgentSelectComponent } from "@app/widgets/agent-select/agent-select.component";

@Component({
  selector: "app-wisp",
  templateUrl: "./wisp.component.html",
  styleUrls: ["./wisp.component.css"],
})
export class WispComponent implements OnInit {
  busy: any;
  selectedItem: any;
  form: FormGroup;
  wispList: any[];
  idSelectedItem: any;
  isUpdate: boolean = false;
  selectedFile: File;
  selectedFileName: string = "";
  pagination: MetaPagination;
  today: Date = new Date();
  activeTab: string = "tabDealers";

  @ViewChild("agentSelect") private agentSelect: AgentSelectComponent;

  filter = {
    dealer_id: "",
    agent_id: "",
    masteragent_id: "",
    data: "",
    province_id: "",
    region_id: "",
    city_id: "",
  };

  apiDate = {
    from: "",
    to: "",
  };

  pickerOptions = {
    singleDatePicker: false,
    maxDate: moment(),
    ranges: {
      "Last Month": [moment().subtract(1, "month"), moment()],
      "Last 6 Months": [moment().subtract(6, "month"), moment()],
      "Last Year": [moment().subtract(12, "month"), moment()],
    },
  };

  @ViewChild("insertModal") private insertModal;
  @ViewChild("deleteModal") private deleteModal;
  @ViewChild("popupNote") private popupNote;

  private modal: NgbModalRef;

  popupConfig = {
    key: null,
    id: null,
    popover: null,
    inputValue: null,
  };

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private service: WispService,
    private dateRangePickerOptions: DaterangepickerConfig
  ) {}

  ngOnInit(): void {
    this.refreshList();
    this.form = this.fb.group({
      data: ["", Validators.required],
      tecnologia: ["", Validators.required],
      copertura: ["", Validators.required],
      offerta: ["", Validators.required],
      foto_file: [""],
    });
  }

  refreshList() {
    this.spinner.show("card");
    const params = {};

    if (this.filter.data) {
      params["from_date"] = this.apiDate.from;
      params["to_date"] = this.apiDate.to;
    }

    if (this.filter.dealer_id) {
      params["dealer_id"] = this.filter.dealer_id;
    }

    if (this.filter.agent_id) {
      params["agent_id"] = this.filter.agent_id;
    }

    if (this.filter.masteragent_id) {
      params["masteragent_id"] = this.filter.masteragent_id;
    }

    if (this.activeTab == "tabDealers") {
      this.service.getListWithDealer(params).subscribe(
        (response: WispPagination) => {
          this.wispList = response.data;
          this.pagination = response._meta;
          this.spinner.hide("card");
        },
        (err) => {
          this.spinner.hide("card");
        }
      );
    } else {
      this.service.getListWithoutDealer(params).subscribe(
        (response: WispPagination) => {
          this.wispList = response.data;
          this.pagination = response._meta;
          this.spinner.hide("card");
        },
        (err) => {
          this.spinner.hide("card");
        }
      );
    }
  }

  reloadList(event?) {
    this.spinner.show("card");
    if (this.activeTab == "tabDealers") {
      this.busy = this.service.getListWithDealer(event).subscribe(
        (response: WispPagination) => {
          this.wispList = response.data;
          this.pagination = response["_meta"];
          this.spinner.hide("card");
        },
        (err) => {
          this.spinner.hide("card");
        }
      );
    } else {
      this.service.getListWithoutDealer(event).subscribe(
        (response: WispPagination) => {
          this.wispList = response.data;
          this.pagination = response["_meta"];
          this.spinner.hide("card");
        },
        (err) => {
          this.spinner.hide("card");
        }
      );
    }
  }

  openModal(isUpdate: boolean, item?: any) {
    this.selectedItem = item;
    this.modal = this.modalService.open(this.insertModal, {
      size: "xl",
    });

    this.isUpdate = isUpdate;

    if (item) {
      this.form.patchValue(item);
    }

    if (this.activeTab === "tabDealers") {
      if (!this.form.get("dealer_id")) {
        this.form.addControl("dealer_id", this.fb.control(""));
      }
    } else {
      if (this.form.get("dealer_id")) {
        this.form.removeControl("dealer_id");
      }
    }
  }

  onTabSelect(event) {
    this.activeTab = event.nextId;
    this.refreshList();
  }

  closeModal() {
    this.form.reset();
    this.modalService.dismissAll();
    this.selectedFile = null;
    this.selectedFileName = null;
    this.selectedItem = null;
    this.idSelectedItem = null;
  }

  closePopover() {
    this.popupConfig.popover.close();
  }

  openPopover(popOver: any, orderId, item, key) {
    this.popupConfig.inputValue = item;
    this.popupConfig.id = orderId;
    this.popupConfig.key = key;
    this.popupConfig.popover = popOver;
    popOver.open();
  }

  openDeleteModal(id) {
    this.idSelectedItem = id;
    this.modal = this.modalService.open(this.deleteModal, {
      size: "sm",
    });
  }

  save(value) {
    const body = {};
    body[this.popupConfig["key"]] = value;
    this.spinner.show("popupSpinner");
    this.updateWisp(this.popupConfig.id, body);
  }

  hasImgError(): boolean {
    const imgPathControl = this.form.get("foto_file");
    return (
      imgPathControl?.dirty && imgPathControl.hasError("invalidImageFormat")
    );
  }

  onFileChange(event: any) {
    const file: File = event.target.files[0];
    this.selectedFile = file;
    this.selectedFileName = file.name;
    this.form.get("foto_file").setValue(file);
  }

  deleteRecord() {
    this.spinner.show("deleteModalSpinner");
    this.service.delete(this.idSelectedItem).subscribe(
      (res) => {
        this.spinner.hide("deleteModalSpinner");
        this.modalService.dismissAll();
        this.refreshList();
        this.idSelectedItem = null;
        this.toastr.success(`Record eliminato correttamente`, "Wisp", {
          positionClass: "toast-top-right",
        });
      },
      (err) => {
        this.toastr.error(
          `Errore durante l'eliminazione del record, contattare il supporto!`,
          "Wisp",
          { positionClass: "toast-top-right" }
        );
      }
    );
  }

  submitForm() {
    const formData = new FormData();

    if (this.activeTab === "tabDealers") {
      formData.append("dealer_id", this.form.getRawValue()["dealer_id"]);
    }

    formData.append("data", this.form.getRawValue()["data"]);
    formData.append("tecnologia", this.form.getRawValue()["tecnologia"]);
    formData.append("copertura", this.form.getRawValue()["copertura"]);
    formData.append("offerta", this.form.getRawValue()["offerta"]);
    formData.append("comune", this.filter.city_id);

    if (this.selectedFile) {
      formData.append("foto_file", this.selectedFile);
    } else {
      formData.append("foto_file", null);
    }

    if (this.isUpdate) {
      return this.updateWisp(this.selectedItem.id, formData);
    } else if (!this.isUpdate) {
      this.spinner.show("modalSpinner");
      this.service.create(formData).subscribe((res) => {
        this.spinner.hide("modalSpinner");
        this.modalService.dismissAll();
        this.form.reset();
        this.refreshList();
        this.selectedItem = null;
        this.toastr.success(`Creazione effettuata con successo`, "Wisp", {
          positionClass: "toast-top-right",
        });
      }),
        (err) => {
          this.toastr.error(
            `Errore durante la creazione, contattare il supporto!`,
            "Wisp",
            { positionClass: "toast-top-right" }
          );
        };
    }
  }

  updateWisp(id, data) {
    this.spinner.show("modalSpinner");
    this.service.update(id, data).subscribe(
      (res) => {
        this.spinner.hide("modalSpinner");
        this.modalService.dismissAll();
        this.form.reset();
        this.refreshList();
        this.selectedItem = null;
        this.toastr.success(`Modifica effettuata con successo`, "Wisp", {
          positionClass: "toast-top-right",
        });
      },
      (err) => {
        this.toastr.error(
          `Errore durante la modifica, contattare il supporto!`,
          "Wisp",
          { positionClass: "toast-top-right" }
        );
      }
    );
  }

  export() {
    let rows = this.wispList;
    let output = rows.map((row) => {
      return {
        "District Manager": row.district ? row.district : "-",
        Data: row.data,
        "Ragione Sociale": row.ragsociale,
        Tecnologia: row.tecnologia,
        Comune: row.comune,
        Copertura: row.copertura,
        Offerta: row.offerta,
        Foto: row.foto,
      };
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "export-rilevazione-wisp.xlsx");
  }

  public generateDateRangeString(
    startDate: moment.Moment,
    endDate: moment.Moment
  ) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    this.apiDate.from = startDate.format(
      this.dateRangePickerOptions.settings.locale.apiFormat
    );
    this.apiDate.to = endDate.format(
      this.dateRangePickerOptions.settings.locale.apiFormat
    );
    this.filter.data =
      startDate.format(localDateFormat) +
      this.dateRangePickerOptions.settings.locale.separator +
      endDate.format(localDateFormat);
  }

  truncateOfferta(str: string, maxLength: number): string {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  }

  setMasterAgent($event: any) {
    if ($event !== null) {
      this.agentSelect.reset();
    }
    this.filter.masteragent_id = $event;
  }

  changeRegion(event) {
    this.filter.region_id = event;
    if (!event) {
      this.filter.province_id = "";
    }
  }

  changeProvince(event) {
    this.filter.province_id = event;
    if (!event) {
      this.filter.region_id = "";
    }
  }

  onCityChange(event) {
    this.filter.city_id = event;

    if (!event) {
      this.filter.province_id = "";
    }
  }
}
