<app-master-header
  cardClass="bg-gradient-info"
  image="assets/img/flats/destination.png"
  title="Visite"
>
  <div class="row align-items-end">
    <div class="col-lg-4 col-12">
      <label translate>Seleziona periodo</label>
      <input
        (selected)="setSelectedDateRange($event.start, $event.end)"
        [(ngModel)]="filterForm.dateRange"
        class="form-control"
        daterangepicker
        type="text"
      />
    </div>
    <ng-container *show="['master', 'operator', 'submaster']">
      <div class="col-lg-4 col-12">
        <label translate>Master Agent</label>
        <app-master-agent-select
          [(ngModel)]="filter.masteragent_id"
          (onChange)="setMasterAgent($event)"
        ></app-master-agent-select>
      </div>
    </ng-container>
    <ng-container *show="['master', 'operator', 'submaster', 'suboperator']">
      <div class="col-lg-4 col-12">
        <label>Ruolo</label>
        <ng-select
          [(ngModel)]="filter.role"
          (change)="this.filter.role = $event"
        >
          <ng-option value="district">District</ng-option>
          <ng-option value="trade_marketing">Trade Marketing</ng-option>
        </ng-select>
      </div>
    </ng-container>
    <div class="col-lg-4 col-12">
      <label for="city" translate>Sottocanale</label>
      <app-channel-select
        [(ngModel)]="filter.channel"
        (onChange)="setChannel($event)"
      ></app-channel-select>
    </div>

    <div class="col-lg-4 col-12">
      <label for="city" translate>Partner Funzionale</label>
      <ng-select
        [(ngModel)]="filter.functional"
        (change)="this.filter.functional = $event"
      >
        <ng-option value="">Tutti</ng-option>
        <ng-option value="1">Funzionale</ng-option>
        <ng-option value="0">Non Funzionale</ng-option>
      </ng-select>
    </div>

    <div class="col-lg-4 col-12">
      <label>&nbsp;</label>
      <button
        class="btn btn-light btn-block font-weight-bold text-uppercase"
        (click)="this.refresh()"
      >
        Aggiorna
      </button>
    </div>
    <div class="col-lg-4 col-12">
      <button
        [ladda]="this.laddaExcel"
        class="btn btn-dark float-right"
        (click)="this.downloadExcel()"
      >
        <i class="fa fa-download"></i> Excel
      </button>
    </div>
  </div>
</app-master-header>

<div class="card">
  <div class="card-body">
    <div *ngIf="this.loading" class="w-100 text-center">
      <img src="../../../../assets/img/svgs/loading_gears.svg" />
    </div>
    <div *ngIf="global && !this.loading" class="row">
      <div *ngIf="defaultView" class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/online-store.png" />
          <span class="title">{{ global.today_visits }}</span>
          <span class="description"> Visite di oggi </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/calendar_2.png" />
          <span class="title">{{ global.period_visits }}</span>
          <span class="description"> Visite nel periodo </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/percentage.png" />
          <span class="title">{{ global.average_period_visits }}</span>
          <span class="description"> Media visite </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/settings_2.png" />
          <span class="title">{{ global.period_our_retailers }}</span>
          <span class="description" translate="">Visite uniche</span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/new.png" />
          <span class="title">{{ global.period_other_retailers }}</span>
          <span class="description"> Visite potenziali partner </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/internet.png" />
          <span class="title">{{ global.total_shops }}</span>
          <span class="description" translate>
            Negozi totali a portafoglio
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="global" class="row mt-4">
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/map.png" />
          <span class="title">{{ global.period_cities }}</span>
          <span class="description"> Comuni visitati </span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/placeholder.png" />
          <span class="title">{{ global.cities_with_shops }}</span>
          <span class="description" translate> Comuni con negozi</span>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="icon-block">
          <img class="img-fluid" src="/assets/img/flats/global-network.png" />
          <span class="title">{{ global.covered_cities }}</span>
          <span class="description" translate
            >Comuni potenzialmente coperti con agenti</span
          >
        </div>
      </div>

      <div class="col-12 col-md-4 col-xl-2">
        <div class="d-flex">
          <i
            style="font-size: 52px"
            class="fa fa-circle text-success"
            aria-hidden="true"
          ></i>
          <div class="icon-block d-flex flex-column">
            <span class="title ml-2">{{ global.cities_green }}</span>
            <span class="description ml-2" translate
              >Comuni Verdi Visitati</span
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="d-flex">
          <i
            style="font-size: 52px"
            class="fa fa-circle text-warning"
            aria-hidden="true"
          ></i>
          <div class="icon-block d-flex flex-column">
            <span class="title ml-2">{{ global.cities_yellow }}</span>
            <span class="description ml-2" translate
              >Comuni Gialli Visitati</span
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 col-xl-2">
        <div class="d-flex">
          <i
            style="font-size: 52px"
            class="fa fa-circle text-danger"
            aria-hidden="true"
          ></i>
          <div class="icon-block d-flex flex-column">
            <span class="title ml-2">{{ global.cities_red }}</span>
            <span class="description ml-2" translate
              >Comuni Rossi Visitati</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ul #nav="ngbNav" class="nav-tabs nav-pills border-0 mb-3" ngbNav>
  <li ngbNavItem>
    <a ngbNavLink>Visuale</a>
    <ng-template ngbNavContent>
      <div *ngIf="this.loading" class="w-100 text-center">
        <img src="../../../../assets/img/svgs/loading_gears.svg" />
      </div>
      <div class="row" *ngIf="!this.loading">
        <div *ngFor="let card of rows" class="col-12 col-md-4 col-lg-3">
          <div class="card bg-gradient-white">
            <div class="card-body box-profile">
              <div class="text-center">
                <img
                  class="profile-user-img img-fluid img-circle"
                  src="../../../../assets/img/flats/agent.png"
                />
              </div>
              <h3 class="text-center profile-username">{{ card.agente }}</h3>
              <p class="text-center text-muted mb-2"></p>
              <ul class="list-group list-group-unbordered">
                <li *ngIf="defaultView" class="list-group-item">
                  <img
                    class="mr-2"
                    height="24px"
                    src="../../../../assets/img/flats/online-store.png"
                  />
                  <strong translate>Negozi visitati oggi</strong
                  ><span class="float-right"
                    >{{ card.today_visits }} su {{ card.total_shops }}</span
                  >
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    height="24px"
                    src="../../../../assets/img/flats/calendar_2.png"
                  />
                  <strong translate>Negozi visitati nel periodo</strong
                  ><span class="float-right"
                    >{{ card.period_our_retailers }} su
                    {{ card.total_shops }}</span
                  >
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    height="24px"
                    src="../../../../assets/img/flats/destination.png"
                  />
                  <strong>Comuni Visitati</strong
                  ><span class="float-right"
                    >{{ card.period_cities }} su {{ card.covered_cities }}</span
                  >
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    height="24px"
                    src="../../../../assets/img/flats/percentage.png"
                  />
                  <strong>Media visite giornaliere</strong
                  ><span class="float-right">{{
                    card.average_period_visits
                  }}</span>
                </li>
              </ul>
              <hr class="mb-2 mt-0" />
              <div class="container bg-light rounded">
                <div class="row">
                  <div class="col-4 p-2 d-flex align-items-center">
                    <span
                      style="font-size: 20px; padding: 5px"
                      class="badge badge-success mr-2"
                      >{{ card.cities_green }}</span
                    >
                    <p style="font-size: 14px; line-height: 1.1" class="mb-0">
                      Comuni Verdi
                    </p>
                  </div>
                  <div class="col-4 p-2 d-flex align-items-center">
                    <span
                      style="font-size: 20px; padding: 5px"
                      class="badge badge-warning mr-2"
                      >{{ card.cities_yellow }}</span
                    >
                    <p style="font-size: 14px; line-height: 1.1" class="mb-0">
                      Comuni Gialli
                    </p>
                  </div>
                  <div class="col-4 p-2 d-flex align-items-center">
                    <span
                      style="font-size: 20px; padding: 5px"
                      class="badge badge-danger mr-2"
                      >{{ card.cities_red }}</span
                    >
                    <p style="font-size: 14px; line-height: 1.1" class="mb-0">
                      Comuni Rossi
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="p-2 bg-gradient-dark elevation-1 rounded my-2"
                *ngIf="false"
              >
                <p class="text-white text-center">Esito delle visite</p>
              </div>
              <ul class="list-group list-group-unbordered" *ngIf="false">
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    height="24px"
                    src="../../../../assets/img/flats/settings_2.png"
                  />
                  <strong translate>Negozi diventati produttivi</strong
                  ><span class="float-right">{{
                    card.retailers_started_to_be_productive
                  }}</span>
                </li>
                <li class="list-group-item">
                  <img
                    class="mr-2"
                    height="24px"
                    src="../../../../assets/img/flats/diagram.png"
                  />
                  <strong translate>Negozi con incremento produttività</strong
                  ><span class="float-right">{{
                    card.retailers_increased_sales
                  }}</span>
                </li>
                <li class="list-group-item" *isVendorDisabled="'Eolo'">
                  <img
                    class="mr-2"
                    height="24px"
                    src="../../../../assets/img/flats/dollar.png"
                  />
                  <strong>Negozi che hanno comprato plafond</strong
                  ><span class="float-right">{{
                    card.retailers_bought_plafond
                  }}</span>
                </li>
                <li class="list-group-item" *isVendorDisabled="'Eolo'">
                  <img
                    class="mr-2"
                    height="24px"
                    src="../../../../assets/img/flats/sim-card.png"
                  />
                  <strong>Negozi che hanno comprato sim</strong
                  ><span class="float-right">{{
                    card.retailers_bought_sim
                  }}</span>
                </li>
              </ul>
              <a
                [routerLink]="['/visite-riepilogo', card.id_agente]"
                class="btn bg-gradient-info text-white btn-block btn-sm mt-2"
                >Dettaglio</a
              >
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </li>

  <li ngbNavItem>
    <a ngbNavLink>Tabellare</a>
    <ng-template ngbNavContent>
      <div *ngIf="this.loading" class="w-100 text-center">
        <img src="../../../../assets/img/svgs/loading_gears.svg" />
      </div>
      <div class="card bg-gradient-light">
        <div class="card-body p-0">
          <table class="table">
            <thead>
              <tr>
                <th translate>Key Account</th>
                <!-- <th class="text-right">Visite Totali</th>
              <th class="text-right">Partner Totali</th>
              <th class="text-right">Partner Visitati</th>
              <th class="text-right">Partner potenziali</th>
              <th class="text-right">Comuni Totali</th>
              <th class="text-right">Comuni Visitati</th> -->

                <th
                  style="cursor: pointer"
                  (click)="sortData('period_visits')"
                  class="text-right"
                >
                  Visite Totali
                  <span *ngIf="!sortColumn"><i class="fas fa-sort"></i></span>
                  <span
                    *ngIf="
                      sortColumn === 'period_visits' && sortDirection === 'asc'
                    "
                    ><i class="fas fa-sort-amount-down text-success"></i
                  ></span>
                  <span
                    *ngIf="
                      sortColumn === 'period_visits' && sortDirection === 'desc'
                    "
                    ><i class="fas fa-sort-amount-up text-success"></i
                  ></span>
                </th>

                <th
                  style="cursor: pointer"
                  (click)="sortData('total_shops')"
                  class="text-right"
                >
                  Partner Totali
                  <span *ngIf="!sortColumn"><i class="fas fa-sort"></i></span>
                  <span
                    *ngIf="
                      sortColumn === 'total_shops' && sortDirection === 'asc'
                    "
                    ><i class="fas fa-sort-amount-down text-success"></i
                  ></span>
                  <span
                    *ngIf="
                      sortColumn === 'total_shops' && sortDirection === 'desc'
                    "
                    ><i class="fas fa-sort-amount-up text-success"></i
                  ></span>
                </th>

                <th
                  style="cursor: pointer"
                  (click)="sortData('period_our_retailers')"
                  class="text-right"
                >
                  Partner Visitati
                  <span *ngIf="!sortColumn"><i class="fas fa-sort"></i></span>
                  <span
                    *ngIf="
                      sortColumn === 'period_our_retailers' &&
                      sortDirection === 'asc'
                    "
                    ><i class="fas fa-sort-amount-down text-success"></i
                  ></span>
                  <span
                    *ngIf="
                      sortColumn === 'period_our_retailers' &&
                      sortDirection === 'desc'
                    "
                    ><i class="fas fa-sort-amount-up text-success"></i
                  ></span>
                </th>

                <th
                  style="cursor: pointer"
                  (click)="sortData('period_other_retailers')"
                  class="text-right"
                >
                  Partner Potenziali
                  <span *ngIf="!sortColumn"><i class="fas fa-sort"></i></span>
                  <span
                    *ngIf="
                      sortColumn === 'period_other_retailers' &&
                      sortDirection === 'asc'
                    "
                    ><i class="fas fa-sort-amount-down text-success"></i
                  ></span>
                  <span
                    *ngIf="
                      sortColumn === 'period_other_retailers' &&
                      sortDirection === 'desc'
                    "
                    ><i class="fas fa-sort-amount-up text-success"></i
                  ></span>
                </th>

                <th
                  style="cursor: pointer"
                  (click)="sortData('covered_cities')"
                  class="text-right"
                >
                  Comuni Totali
                  <span *ngIf="!sortColumn"><i class="fas fa-sort"></i></span>
                  <span
                    *ngIf="
                      sortColumn === 'covered_cities' && sortDirection === 'asc'
                    "
                    ><i class="fas fa-sort-amount-down text-success"></i
                  ></span>
                  <span
                    *ngIf="
                      sortColumn === 'covered_cities' &&
                      sortDirection === 'desc'
                    "
                    ><i class="fas fa-sort-amount-up text-success"></i
                  ></span>
                </th>

                <th
                  style="cursor: pointer"
                  (click)="sortData('period_cities')"
                  class="text-right"
                >
                  Comuni Visitati
                  <span *ngIf="!sortColumn"><i class="fas fa-sort"></i></span>
                  <span
                    *ngIf="
                      sortColumn === 'period_cities' && sortDirection === 'asc'
                    "
                    ><i class="fas fa-sort-amount-down text-success"></i
                  ></span>
                  <span
                    *ngIf="
                      sortColumn === 'period_cities' && sortDirection === 'desc'
                    "
                    ><i class="fas fa-sort-amount-up text-success"></i
                  ></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of rows">
                <td>
                  <a [routerLink]="['/visite-riepilogo', row.id_agente]">{{
                    row.agente
                  }}</a>
                </td>
                <td class="text-right">{{ row.period_visits }}</td>
                <td class="text-right">{{ row.total_shops }}</td>
                <td class="text-right">{{ row.period_our_retailers }}</td>
                <td class="text-right">{{ row.period_other_retailers }}</td>
                <td class="text-right">{{ row.covered_cities }}</td>
                <td class="text-right">{{ row.period_cities }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <a (click)="exportList()" class="link">Esporta la lista completa</a>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
