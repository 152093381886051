import { CostsManagementComponent } from "./pages/gestione-incentivazione/costs-management/costs-management.component";

import { KenaVisiteComponent } from "./pages/kena-visite/kena-visite.component";
import { MonthRangePickerComponent } from "./widgets/month-range-picker/month-range-picker.component";
import { KenaRecapComponent } from "./pages/kena-recap/kena-recap.component";
import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { CommonModule, registerLocaleData } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import localeIT from "@angular/common/locales/it";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MainComponent } from "./pages/main/main.component";
import { LoginComponent } from "./pages/login/login.component";
import { HeaderComponent } from "./pages/main/header/header.component";
import { FooterComponent } from "./pages/main/footer/footer.component";
import { MenuSidebarComponent } from "./pages/main/menu-sidebar/menu-sidebar.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ToastrModule } from "ngx-toastr";
import { MessagesDropdownMenuComponent } from "./pages/main/header/messages-dropdown-menu/messages-dropdown-menu.component";
import { NotificationsDropdownMenuComponent } from "./pages/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component";
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TicketListComponent } from "./pages/tickets/list/ticket-list.component";
import { TicketDetailComponent } from "./pages/tickets/detail/ticket-detail.component";
import {
  NgArrayPipesModule,
  NgObjectPipesModule,
  NgPipesModule,
} from "angular-pipes";
import { InvoiceListComponent } from "./pages/invoices/list/invoice-list.component";
import { FaqListComponent } from "./pages/faq/list/faq-list.component";
import { DocumentsListComponent } from "./pages/documents/list/documents-list.component";
import { ValdemortModule } from "ngx-valdemort";
import { ErrorLogService } from "@helpers/error-log.service";
import { GlobalErrorHandler } from "@helpers/global-error.handler";
import { ToastsContainerComponent } from "@widgets/toasts-container/toasts-container.component";
import { ShopIndexComponent } from "./pages/shop/index/shop-index.component";
import { SafePipe } from "@helpers/safe.pipe";
import { MomentModule } from "ngx-moment";
import "moment/locale/it";
import { TicketModalComponent } from "@app/pages/tickets/modal/ticket-modal.component";
import { DocumentsModalComponent } from "./pages/documents/modal/documents-modal.component";
import { PbxListComponent } from "./pages/pbx/list/pbx-list.component";
import { NotFoundComponent } from "./pages/main/not-found/not-found.component";
import { ResetPasswordComponent } from "./pages/main/reset-password/reset-password.component";
import { CreditoIndexComponent } from "./pages/credito/index/credito-index.component";
import { ProductComponent } from "./pages/shop/partials/product/product.component";
import { CartComponent } from "./pages/shop/partials/cart/cart.component";
import { ShopMenuComponent } from "./pages/shop/partials/shop-menu/shop-menu.component";
import { ShopSubProductsPickerComponent } from "./pages/shop/partials/sub-products/shop-sub-products-picker.component";
import { ShopBuyComponent } from "./pages/shop/buy/shop-buy.component";
import { AddressComponent } from "./pages/partials/address/address.component";
import { ShopDetailComponent } from "./pages/shop/detail/shop-detail.component";
import { DocumentsCreateModalComponent } from "./pages/documents/create-modal/documents-create-modal.component";
import { ShopListComponent } from "./pages/shop/list/shop-list.component";
import { VideoIndexComponent } from "./pages/video/index/video-index.component";
import { VisiteAgenteIndexComponent } from "./pages/visite-agente/index/visite-agente-index.component";
import { DashboardRiepilogoComponent } from "./pages/dashboard/partials/riepilogo/dashboard-riepilogo.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { MomentDateFormatter } from "@helpers/datepicker.formatter";
import { DatepickerAdapter } from "@helpers/datepicker.adapter";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxFileDropModule } from "ngx-file-drop";
import { InvoiceDetailComponent } from "./pages/invoices/detail/invoice-detail.component";
import { PeriodSelectorComponent } from "./pages/partials/period-selector/period-selector.component";
import { DataRangeComponent } from "./pages/partials/data-range/data-range.component";
import { TableComponent } from "./pages/partials/table/table.component";
import { FormComponent } from "./pages/partials/form/form.component";
import { DialogFieldEditComponent } from "./pages/partials/dialog-field-edit/dialog-field-edit.component";
import { ErrorInterceptor } from "@helpers/error.interceptor";
import { BasicAuthInterceptor } from "@helpers/basic-auth.interceptor";
import { NgBusyModule } from "ng-busy";
import { ShopBlockedComponent } from "./pages/shop/blocked/shop-blocked.component";
import { ShopPosComponent } from "./pages/shop/pos/shop-pos.component";
import { AutocompleteAddressComponent } from "./widgets/autocomplete-address/autocomplete-address.component";
import { ShopPrintingProductComponent } from "./pages/shop/partials/printing-product/shop-printing-product.component";
import { MasterDashboardComponent } from "./pages/master-dashboard/master-dashboard.component";
import { MasterCardComponent } from "./pages/master-dashboard/partials/master-card/master-card.component";
import { AutoloadChartComponent } from "./pages/master-dashboard/partials/autoload-chart/autoload-chart.component";
import { AutoloadRiepilogoComponent } from "./pages/master-dashboard/partials/autoload-riepilogo/autoload-riepilogo.component";
import { AutoloadPuntiVenditaComponent } from "./pages/master-dashboard/partials/autoload-punti-vendita/autoload-punti-vendita.component";
import { AutoloadTargetComponent } from "./pages/master-dashboard/partials/autoload-target/autoload-target.component";
import { AutoloadFatturatoComponent } from "./pages/master-dashboard/partials/autoload-fatturato/autoload-fatturato.component";
import { AutoloadOfferteComponent } from "./pages/master-dashboard/partials/autoload-offerte/autoload-offerte.component";
import { MasterHeaderComponent } from "./pages/master-dashboard/partials/master-header/master-header.component";
import { MasterSubheaderComponent } from "./pages/master-dashboard/partials/master-subheader/master-subheader.component";
import { MasterProduzionePersaComponent } from "./pages/master-produzione-persa/master-produzione-persa.component";
import { AutoloadTableComponent } from "./pages/master-dashboard/partials/autoload-table/autoload-table.component";
import { MasterAnalisiFasceComponent } from "./pages/master-analisi-fasce/master-analisi-fasce.component";
import { MasterAnalisiPlafondComponent } from "./pages/master-analisi-plafond/master-analisi-plafond.component";
import { MasterAnalisiSimComponent } from "./pages/master-analisi-sim/master-analisi-sim.component";
import { MasterClassificaComponent } from "./pages/master-classifica/master-classifica.component";
import { MasterConfrontoComponent } from "./pages/master-confronto/master-confronto.component";
import { MasterNuoveAffiliazioniComponent } from "./pages/master-nuove-affiliazioni/master-nuove-affiliazioni.component";
import { MasterMappaComponent } from "./pages/master-mappa/master-mappa.component";
import { AgmCoreModule } from "@agm/core";
import { MasterEmailComponent } from "./pages/master-email/master-email.component";
import { MasterEmailDettaglioComponent } from "./pages/master-email-dettaglio/master-email-dettaglio.component";
import { GestioneIncentivazioneComponent } from "./pages/gestione-incentivazione/gestione-incentivazione.component";
import { HotTableModule } from "@handsontable/angular";
import { HotComponent } from "@widgets/hot/hot.component";
import { ModalComponent } from "@widgets/modal/modal.component";
import { GestioneFatturazioneComponent } from "./pages/gestione-fatturazione/gestione-fatturazione.component";
import { MasterVisiteIndexComponent } from "./pages/master-visite/master-visite-index/master-visite-index.component";
import { MasterVisiteDetailComponent } from "./pages/master-visite/master-visite-detail/master-visite-detail.component";
import { AgentVisiteIndexComponent } from "./pages/agent-visite/agent-visite-index/agent-visite-index.component";
import { SafeHtmlPipe } from "@helpers/safe-html.pipe";
import { MasterDashboardTabComponent } from "./pages/master-dashboard/master-dashboard-tab/master-dashboard-tab.component";
import { GestioneStorniComponent } from "./pages/gestione-incentivazione/gestione-storni/gestione-storni.component";
import { GestioneReportComponent } from "./pages/gestione-incentivazione/gestione-report/gestione-report.component";
import { ModalQuestionsComponent } from "./widgets/modal-questions/modal-questions.component";
import { DatepickerRangePopupComponent } from "./widgets/datepicker-range.popup/datepicker-range.popup.component";
import { MasterDealerDettaglioComponent } from "./pages/master-dealer-dettaglio/master-dealer-dettaglio.component";
import { ChartsModule } from "ng2-charts";
import { AutoloadChartjsComponent } from "./widgets/autoload-chartjs/autoload-chartjs.component";
import { AutoloadTableRiepilogoComponent } from "./widgets/autoload-table-riepilogo/autoload-table-riepilogo.component";
import { AutoloadKeypointsComponent } from "./widgets/autoload-keypoints/autoload-keypoints.component";
import { ShowDirective } from "./directives/show.directive";
import { DontShowDirective } from "./directives/dont-show.directive";
import { IsVendorEnabledDirective } from "./directives/is-vendor-enabled.directive";
import { LaddaModule } from "angular2-ladda";
import { MasterPlanningComponent } from "./pages/master-planning/master-planning.component";
import { MasterTicketsComponent } from "./pages/master-tickets/master-tickets.component";
import { MasterKpiComponent } from "./pages/master-kpi/master-kpi.component";
import { SpinnerComponent } from "./widgets/spinner/spinner.component";
import { SpinnerDirective } from "./directives/spinner.directive";
import { NegozioSelectComponent } from "./widgets/negozio-select/negozio-select.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgenteSelectComponent } from "./widgets/agente-select/agente-select.component";
import { CalendarioVisiteComponent } from "./pages/calendario-visite/calendario-visite.component";
import { Daterangepicker } from "ng2-daterangepicker";
import { AssetsPipe } from "./helpers/assets.pipe";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { DragDropListModule } from "ng-drag-drop-list";
import { DisableControlDirective } from "./directives/disable-input.directive";
import { CbiGeneratorComponent } from "./pages/cbi-generator/cbi-generator.component";
import { AffilationsComponent } from "./pages/affiliations/affilations.component";
import { InvoiceManagementComponent } from "./pages/invoice-management/invoice-management.component";
import { TicketManagementComponent } from "./pages/ticket-management/ticket-management.component";
import { RetailerTypeaheadComponent } from "./widgets/retailer-typeahead/retailer-typeahead.component";
import { AffilationModalComponent } from "./pages/affiliations/affilation-modal/affilation-modal.component";
import { RetailerManagementComponent } from "./pages/retailer-management/retailer-management.component";
import { StateSelectComponent } from "./widgets/state-select/state-select.component";
import { ProvinceSelectComponent } from "./widgets/province-select/province-select.component";
import { CitySelectComponent } from "./widgets/city-select/city-select.component";
import { VendorSelectComponent } from "./widgets/vendor-select/vendor-select.component";
import { AgentSelectComponent } from "./widgets/agent-select/agent-select.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { StaffSelectComponent } from "./widgets/staff-select/staff-select.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { IsVendorDisabledDirective } from "./directives/is-vendor-disabled.directive";
import { PlanPercentageComponent } from "./pages/master-planning/plan-percentage/plan-percentage.component";
import { RealComponent } from "@app/pages/master-planning/real/real.component";
import { AgentsPerformanceComponent } from "@app/pages/master-planning/agents-performance/agents-performance.component";
import { KenaRecapAffiliationsComponent } from "@app/pages/kena-recap/kena-recap-affiliations/kena-recap-affiliations.component";
import { KenaRecapSalesComponent } from "@app/pages/kena-recap/kena-recap-sales/kena-recap-sales.component";
import { LoyaltyCardComponent } from "./pages/loyalty-card/loyalty-card.component";
import { CampaignsComponent } from "./pages/campaigns/campaigns.component";
import { CampaignModalComponent } from "./pages/campaigns/campaign-modal/campaign-modal.component";
import { DataBuilderTableComponent } from "./widgets/data-builder-table/data-builder-table.component";
import { ShopOrderDashboardComponent } from "./pages/shop-order-dashboard/shop-order-dashboard.component";
import { PbxCampaignModalComponent } from "./pages/retailer-management/pbx-campaign-modal/pbx-campaign-modal.component";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { KenaRecapSalesMnpComponent } from "./pages/kena-recap/kena-recap-sales-mnp/kena-recap-sales-mnp.component";
import { PbxCampaignComponent } from "@app/pages/pbx/pbx-campaign/pbx-campaign.component";
import { PbxLeadsComponent } from "./pages/pbx/pbx-leads/pbx-leads.component";
import { PbxCampaignDetailsComponent } from "./pages/pbx/pbx-campaign-details/pbx-campaign-details.component";
import { PbxCallsComponent } from "./pages/pbx/pbx-calls/pbx-calls.component";
import { PbxScheduleCallModalComponent } from "./pages/pbx/pbx-schedule-call-modal/pbx-schedule-call-modal.component";
import { PbxCallModalComponent } from "./pages/pbx/pbx-call-modal/pbx-call-modal.component";
import { NgxMaskModule } from "ngx-mask";
import { TimeToTextPipe } from "@helpers/time-to-text.pipe";
import { PbxQuestionsComponent } from "./pages/pbx/pbx-questions/pbx-questions.component";
import { PbxAnswersModalComponent } from "@app/pages/pbx/pbx-answers-modal/pbx-answers-modal.component";
import { TicketDashboardComponent } from "./pages/ticket-dashboard/ticket-dashboard.component";
import { HideDashboardDirective } from "@app/directives/hide-dashboard.directive";
import { ShowDashboardDirective } from "@app/directives/show-dashboard.directive";
import { RichTextTruncateComponent } from "./widgets/rich-text-truncate/rich-text-truncate.component";
import { ProductsComponent } from "@app/pages/e-pay/products/products.component";
import { ProductModalComponent } from "@app/pages/e-pay/products/product-modal/product-modal.component";
import { BrandsComponent } from "./pages/e-pay/brands/brands.component";
import { BrandModalComponent } from "./pages/e-pay/brand-modal/brand-modal.component";
import { ProductDetailComponent } from "./pages/e-pay/products/product-detail/product-detail.component";
import { MasterEmailCardComponent } from "./pages/master-email/master-email-card/master-email-card.component";
import { OrdersComponent } from "./pages/e-pay/orders/orders.component";
import { ReceiptComponent } from "./pages/e-pay/receipt/receipt.component";
import { DataBuilderModalComponent } from "./widgets/data-builder-modal/data-builder-modal.component";
import { AgentGpsTrackingComponent } from "./pages/agent-gps-tracking/agent-gps-tracking.component";
import { CategoryComponent } from "./pages/shop/category/category.component";
import { CategoryModalComponent } from "./pages/shop/category/category-modal/category-modal.component";
import { OperatorComponent } from "./pages/shop/operator/operator.component";
import { OperatorModalComponent } from "./pages/shop/operator/operator-modal/operator-modal.component";
import { ShopProductComponent } from "./pages/shop/shop-product/shop-product.component";
import { ShopProductModalComponent } from "./pages/shop/shop-product/shop-product-modal/shop-product-modal.component";
import { ShopProductDetailComponent } from "./pages/shop/shop-product/shop-product-detail/shop-product-detail.component";
import { StandSelectComponent } from "@widgets/stand-select/stand-select.component";
import { AgmDirectionModule } from "agm-direction";
import { EpaySalesComponent } from "./pages/e-pay/epay-sales/epay-sales.component";
import { MasterWappCardComponent } from "@app/pages/master-email/master-wapp-card/master-wapp-card.component";
import { DealerMapComponent } from "./pages/dealer-map/dealer-map.component";
import { MasterAgentSelectComponent } from "./widgets/master-agent-select/master-agent-select.component";
import { EMailCampaignComponent } from "./pages/e-mail-campaign/e-mail-campaign.component";
import { EMailCampaignModalComponent } from "./pages/e-mail-campaign/e-mail-campaign-modal/e-mail-campaign-modal.component";
import { ShopProductTemplateComponent } from "./pages/shop/shop-product/shop-product-template/shop-product-template.component";
import { ProductVariantDetailComponent } from "./pages/shop/shop-product/product-variant-detail/product-variant-detail.component";
import { SmsSalesComponent } from "./pages/sms-sales/sms-sales.component";
import { ReportTimelineComponent } from "./pages/master-dealer-dettaglio/report-timeline/report-timeline.component";
import { ShopProductInstallmentsComponent } from "./pages/shop/shop-product-installments/shop-product-installments.component";
import { ShopSalesComponent } from "./pages/shop/shop-sales/shop-sales.component";
import { ChangePasswordComponent } from "./pages/main/change-password/change-password.component";
import { PopupComponent } from "./pages/popup/popup.component";
import { ShopOrderHistoryComponent } from "./pages/shop-order-history/shop-order-history.component";
import { ShopOrderDetailComponent } from "./pages/shop-order-history/shop-order-detail/shop-order-detail.component";
import { EditPopupComponent } from "./pages/shop-order-history/edit-popup/edit-popup.component";
import { CountdownModule } from "ngx-countdown";
import { ShopCreditComponent } from "./pages/shop-credit/shop-credit.component";
import { CreditModalComponent } from "./pages/shop-credit/credit-modal/credit-modal.component";
import { ShopAccessComponent } from "./pages/shop-access/shop-access.component";
import { ShopAccessModalComponent } from "./pages/shop-access/shop-access-modal/shop-access-modal.component";
import { DigitalSignatureComponent } from "./pages/kd-orders/digital-signature/digital-signature.component";
import { CertificatesComponent } from "./pages/kd-orders/certificates/certificates.component";
import { ShippingComponent } from "./pages/kd-orders/shipping/shipping.component";
import { PackagesComponent } from "./pages/kd-orders/packages/packages.component";
import { PecComponent } from "./pages/kd-orders/pec/pec.component";
import { TicketsComponent } from "./pages/kd-orders/tickets/tickets.component";
import { PrintingListComponent } from "./pages/kd-orders/printing-list/printing-list.component";
import { RacesComponent } from "./pages/races/races.component";
import { RacesFormComponent } from "./pages/races/races-form/races-form.component";
import { GroupComponent } from "./pages/group/group.component";
import { GroupModalComponent } from "./pages/group/group-modal/group-modal/group-modal.component";
import { GroupDealerModalComponent } from "./pages/group/group-modal/group-modal/group-dealer-modal/group-dealer-modal.component";
import { RacesFilesModalComponent } from "./pages/races/races-form/races-files-modal/races-files-modal.component";
import { RacesDealersComponent } from "./pages/races/races-form/races-dealers/races-dealers.component";
import { LoginVerificationComponent } from "./pages/login-verification/login-verification.component";
import { PostalSlipComponent } from "./pages/kd-orders/postal-slip/postal-slip.component";
import { DealerDetailComponent } from "@app/pages/master-dealer-dettaglio/dealer-detail/dealer-detail.component";
import { KdSalesComponent } from "@app/pages/kd-orders/kd-sales/kd-sales.component";
import { InvoiceTypesComponent } from "./pages/invoices/invoice-types/invoice-types.component";
import { InvoiceUploadComponent } from "./pages/invoices/invoice-upload/invoice-upload.component";
import { SuretyCreditComponent } from "./pages/surety-credit/surety-credit.component";
import { BulkAssignDealerComponent } from "./pages/bulk-assign-dealer/bulk-assign-dealer.component";
import { UserListComponent } from "@app/pages/user-list/user-list.component";
import { ChannelSelectComponent } from "@widgets/channel-select/channel-select.component";
import { ClusterSelectComponent } from "@widgets/cluster-select/cluster-select.component";
import { AutoloadClusterComuniComponent } from "@app/pages/master-dashboard/partials/autoload-cluster-comuni/autoload-cluster-comuni.component";
import { ReportVisiteComponent } from "./pages/report-visite/report-visite.component";
import { DistrictDetailComponent } from "./pages/district-detail/district-detail.component";
import { RegionalDetailComponent } from "./pages/regional-detail/regional-detail.component";
import { ConcorrenzaComponent } from "./pages/concorrenza/concorrenza.component";
import { WispComponent } from "./pages/wisp/wisp.component";
import { FormazioneRegionComponent } from "./pages/formazione-detail/formazione-region.component";
import { TradeMarketingRegionComponent } from "./pages/trade-marketing-detail/trade-marketing-region.component";
import { RacesOverviewComponent } from './pages/races/races-overview/races-overview.component';

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);

registerLocaleData(localeIT, "it");

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    ProfileComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    TicketListComponent,
    TicketDetailComponent,
    InvoiceListComponent,
    FaqListComponent,
    DocumentsListComponent,
    ToastsContainerComponent,
    ShopIndexComponent,
    SafePipe,
    SafeHtmlPipe,
    TimeToTextPipe,
    TicketModalComponent,
    DocumentsModalComponent,
    PbxListComponent,
    NotFoundComponent,
    ResetPasswordComponent,
    CreditoIndexComponent,
    ProductComponent,
    CartComponent,
    ShopMenuComponent,
    ShopSubProductsPickerComponent,
    ShopBuyComponent,
    AddressComponent,
    ShopDetailComponent,
    DocumentsCreateModalComponent,
    ShopListComponent,
    VideoIndexComponent,
    VisiteAgenteIndexComponent,
    DashboardRiepilogoComponent,
    InvoiceDetailComponent,
    PeriodSelectorComponent,
    DataRangeComponent,
    TableComponent,
    FormComponent,
    DialogFieldEditComponent,
    ShopBlockedComponent,
    ShopPosComponent,
    AutocompleteAddressComponent,
    ShopPrintingProductComponent,
    MasterDashboardComponent,
    MasterCardComponent,
    AutoloadChartComponent,
    AutoloadRiepilogoComponent,
    AutoloadClusterComuniComponent,
    AutoloadPuntiVenditaComponent,
    AutoloadTargetComponent,
    AutoloadFatturatoComponent,
    AutoloadOfferteComponent,
    MasterHeaderComponent,
    MasterSubheaderComponent,
    MasterProduzionePersaComponent,
    AutoloadTableComponent,
    MasterAnalisiFasceComponent,
    MasterAnalisiPlafondComponent,
    MasterAnalisiSimComponent,
    MasterClassificaComponent,
    MasterConfrontoComponent,
    MasterNuoveAffiliazioniComponent,
    MasterMappaComponent,
    MasterEmailComponent,
    MasterEmailDettaglioComponent,
    GestioneIncentivazioneComponent,
    HotComponent,
    ModalComponent,
    GestioneFatturazioneComponent,
    MasterVisiteIndexComponent,
    MasterVisiteDetailComponent,
    AgentVisiteIndexComponent,
    MasterDashboardTabComponent,
    GestioneStorniComponent,
    GestioneReportComponent,
    ModalQuestionsComponent,
    DatepickerRangePopupComponent,
    MasterDealerDettaglioComponent,
    DistrictDetailComponent,
    RegionalDetailComponent,
    FormazioneRegionComponent,
    TradeMarketingRegionComponent,
    ConcorrenzaComponent,
    WispComponent,
    AutoloadChartjsComponent,
    AutoloadTableRiepilogoComponent,
    AutoloadKeypointsComponent,
    HideDashboardDirective,
    ShowDashboardDirective,
    ShowDirective,
    DontShowDirective,
    SpinnerDirective,
    DisableControlDirective,
    MasterPlanningComponent,
    MasterTicketsComponent,
    MasterKpiComponent,
    SpinnerComponent,
    NegozioSelectComponent,
    AgenteSelectComponent,
    CalendarioVisiteComponent,
    AssetsPipe,
    AffilationsComponent,
    IsVendorEnabledDirective,
    IsVendorDisabledDirective,
    CbiGeneratorComponent,
    InvoiceManagementComponent,
    TicketManagementComponent,
    RetailerTypeaheadComponent,
    AffilationModalComponent,
    StateSelectComponent,
    ProvinceSelectComponent,
    CitySelectComponent,
    ChannelSelectComponent,
    ClusterSelectComponent,
    VendorSelectComponent,
    AgentSelectComponent,
    StaffSelectComponent,
    RetailerManagementComponent,
    UserListComponent,
    MonthRangePickerComponent,
    KenaRecapComponent,
    PlanPercentageComponent,
    AgentsPerformanceComponent,
    KenaVisiteComponent,
    RealComponent,
    CostsManagementComponent,
    KenaRecapSalesComponent,
    KenaRecapSalesMnpComponent,
    KenaRecapAffiliationsComponent,
    LoyaltyCardComponent,
    CampaignsComponent,
    CampaignModalComponent,
    DataBuilderTableComponent,
    ShopOrderDashboardComponent,
    PbxCampaignModalComponent,
    PbxCampaignComponent,
    PbxLeadsComponent,
    PbxCampaignDetailsComponent,
    PbxCallsComponent,
    PbxScheduleCallModalComponent,
    PbxCallModalComponent,
    PbxAnswersModalComponent,
    PbxQuestionsComponent,
    RichTextTruncateComponent,
    TicketDashboardComponent,
    ProductsComponent,
    ProductModalComponent,
    BrandsComponent,
    BrandModalComponent,
    ProductDetailComponent,
    MasterEmailCardComponent,
    DataBuilderModalComponent,
    ProductDetailComponent,
    OrdersComponent,
    ReceiptComponent,
    AgentGpsTrackingComponent,
    CategoryComponent,
    CategoryModalComponent,
    OperatorComponent,
    OperatorModalComponent,
    ShopProductComponent,
    ShopProductModalComponent,
    ShopProductDetailComponent,
    StandSelectComponent,
    EpaySalesComponent,
    MasterWappCardComponent,
    DealerMapComponent,
    MasterAgentSelectComponent,
    EMailCampaignComponent,
    EMailCampaignModalComponent,
    ShopProductTemplateComponent,
    ProductVariantDetailComponent,
    SmsSalesComponent,
    ReportTimelineComponent,
    ShopProductInstallmentsComponent,
    ShopSalesComponent,
    ChangePasswordComponent,
    PopupComponent,
    PopupComponent,
    ShopOrderHistoryComponent,
    ShopOrderDetailComponent,
    EditPopupComponent,
    ShopCreditComponent,
    CreditModalComponent,
    ShopAccessComponent,
    ShopAccessModalComponent,
    DigitalSignatureComponent,
    CertificatesComponent,
    ShippingComponent,
    PackagesComponent,
    PecComponent,
    TicketsComponent,
    PrintingListComponent,
    RacesComponent,
    RacesFormComponent,
    GroupComponent,
    GroupModalComponent,
    GroupDealerModalComponent,
    RacesFilesModalComponent,
    RacesFilesModalComponent,
    RacesDealersComponent,
    LoginVerificationComponent,
    PostalSlipComponent,
    DealerDetailComponent,
    KdSalesComponent,
    InvoiceTypesComponent,
    InvoiceUploadComponent,
    SuretyCreditComponent,
    BulkAssignDealerComponent,
    ReportVisiteComponent,
    RacesOverviewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    NgbModule,
    NgArrayPipesModule,
    NgObjectPipesModule,
    FormsModule,
    NgPipesModule,
    ValdemortModule,
    FullCalendarModule,
    MomentModule,
    NgxSpinnerModule,
    NgSelectModule,
    NgxFileDropModule,
    NgBusyModule,
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAU1bDjb98aRhTpRq6EVDHkuK1Y-r7jxA0",
      /* apiKey is required, unless you are a
            premium customer, in which case you can
            use clientId
            */
    }),
    AgmDirectionModule,
    HotTableModule,
    ChartsModule,
    LaddaModule,
    GooglePlaceModule,
    SweetAlert2Module.forRoot(),
    Daterangepicker,
    DragDropListModule,
    // NgBusyModule.forRoot(new BusyConfig({
    //     message: 'Caricamento...',
    //     backdrop: true,
    //     minDuration: 600
    // }))
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    EditorModule,
    NgxMaskModule.forRoot(),
    CountdownModule,
  ],
  entryComponents: [SpinnerComponent],
  providers: [
    { provide: LOCALE_ID, useValue: "it" },
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    { provide: NgbDateAdapter, useClass: DatepickerAdapter },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
    GlobalErrorHandler,
    ErrorLogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
