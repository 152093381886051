import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DealerService } from "@services/dealer.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Dealer } from "@app/models/dealer";
import { RequestService } from "@services/request.service";

@Component({
  selector: "app-negozio-select",
  templateUrl: "./negozio-select.component.html",
  styleUrls: ["./negozio-select.component.css"],
})
export class NegozioSelectComponent implements OnInit {
  data = [];

  @Input()
  model: any;
  @Input()
  small = false;

  @Input()
  bindLabel = "company_name";

  @Input()
  disabled = false;

  @Input() isFunctional: string;

  @Output() isValid = new EventEmitter();
  @Output() onChange = new EventEmitter();

  form: FormGroup;

  loading = false;

  constructor(private requestService: RequestService) {}

  ngOnInit(): void {
    this.loading = true;
    this.requestService.get("v3/dealers/select-list").subscribe((data) => {
      const dealers = new Dealer().fromArray(data);
      if (this.isFunctional && this.isFunctional == "1") {
        this.data = dealers.filter((dealer) => dealer.is_functional == "1");
      } else if (this.isFunctional && this.isFunctional == "0") {
        this.data = dealers.filter((dealer) => dealer.is_functional == "0");
      } else {
        this.data = dealers;
      }
      this.loading = false;
    });

    // this.isValid.emit();
  }
}
