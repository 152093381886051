import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Dealer} from '@app/models/dealer';
import {AgenteService} from '@app/services/agente.service';

@Component({
    selector: 'app-agent-select',
    templateUrl: './agent-select.component.html',
    styleUrls: ['./agent-select.component.scss']
})
export class AgentSelectComponent implements OnInit {
    @Input()
    small = false;
    @Input()
    disabled = false;

    @Input()
    agent: any;

    @Input()
    set masteragentId(value) {
        this._masteragentId = value;
        this.reset();
        this.setOptions();
    }

    get masteragentId() {
        return this._masteragentId;
    }
    _masteragentId: any = null;

    @Input()
    vendors: [];

    data: any[] = [];

    set options(value) {
        this._options = value;
    }
    _options = [];

    @Output() onChange = new EventEmitter();

    loading = false;

    constructor(
        private service: AgenteService
    ) {
    }

    ngOnInit(): void {
        if (this.agent) {
            this.agent = parseInt(this.agent);
        }

        this.loading = true;
        this.service.selectOptions().subscribe((data) => {
            this.data = data;
            this.setOptions();
            this.loading = false;
        });
    }

    setOptions() {
        this.options = this.data.filter((item: any) => {
            if (this.masteragentId) {
                return item.id_masteragente == this.masteragentId;
            }
            return true;
        });
    }

    get options() {
        return this._options;
    }


    public reset() {
        this.agent = null;
    }
}
