<div [ngBusy]="busy" class="row" style="padding: 40px">
  <div class="container-fluid">
    <div class="card w-100 pb-0">
      <div
        class="card-header border-bottom pb-0 d-flex justify-content-center align-items-center"
      >
        <h2 class="lead display-5 pb-2 pt-2" style="font-weight: bold">
          Scheda Anagrafica District
        </h2>
      </div>
      <div class="card-body bg-gradient-info">
        <div class="container-fluid">
          <ngx-spinner
            [fullScreen]="false"
            bdColor="rgba(0, 0, 0, 0.8)"
            color="#fff"
            name="districtDetails"
            size="default"
            type="square-jelly-box"
          >
            <p style="color: white">Caricamento...</p>
          </ngx-spinner>
          <div class="info-text">
            <p><i class="fa fa-user-circle"></i> Ragione Sociale</p>
            <span class="display-6" style="font-weight: bold">{{
              district.ragsociale
            }}</span>
          </div>
          <div class="row">
            <div class="col-sm-6 p-4">
              <div class="info-text">
                <p><i class="fa fa-user"></i> Login Code</p>
                <span class="lead" *ngIf="!edit.username"
                  >{{ district.username }}
                  <i
                    (click)="edit.username = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                  ></i>
                </span>
                <i
                  class="fas fa-circle-notch fa-spin"
                  *ngIf="this.loading.username"
                ></i>
                <div
                  *ngIf="edit.username && !this.loading.username"
                  class="input-group input-group-sm"
                >
                  <input
                    [(ngModel)]="district.username"
                    class="form-control"
                    type="text"
                  />
                  <div class="input-group-append">
                    <button
                      (click)="edit.username = false"
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-times mr-0"></i>
                    </button>
                    <button
                      (click)="
                        saveDistrictInfo(
                          { username: district.username },
                          'username'
                        )
                      "
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-check mr-0"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 p-4">
              <div class="info-text">
                <p><i class="fa fa-mobile"></i> Cellulare</p>
                <span class="lead" *ngIf="!edit.cellulare"
                  >{{ district.cellulare }}
                  <i
                    (click)="edit.cellulare = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                  ></i>
                </span>
                <i
                  class="fas fa-circle-notch fa-spin"
                  *ngIf="this.loading.cellulare"
                ></i>
                <div
                  *ngIf="edit.cellulare && !this.loading.cellulare"
                  class="input-group input-group-sm"
                >
                  <input
                    [(ngModel)]="district.cellulare"
                    class="form-control"
                    type="text"
                  />
                  <div class="input-group-append">
                    <button
                      (click)="edit.cellulare = false"
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-times mr-0"></i>
                    </button>
                    <button
                      (click)="
                        saveDistrictInfo(
                          { cellulare: district.cellulare },
                          'cellulare'
                        )
                      "
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-check mr-0"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 p-4">
              <div class="info-text">
                <p><i class="fa fa-envelope"></i> E-Mail</p>
                <span class="lead" *ngIf="!edit.email"
                  >{{ district.email }}
                  <i
                    (click)="edit.email = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                  ></i>
                </span>
                <i
                  class="fas fa-circle-notch fa-spin"
                  *ngIf="this.loading.email"
                ></i>
                <div
                  *ngIf="edit.email && !this.loading.email"
                  class="input-group input-group-sm"
                >
                  <input
                    [(ngModel)]="district.email"
                    class="form-control"
                    type="text"
                  />
                  <div class="input-group-append">
                    <button
                      (click)="edit.email = false"
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-times mr-0"></i>
                    </button>
                    <button
                      (click)="
                        saveDistrictInfo({ email: district.email }, 'email')
                      "
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-check mr-0"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 p-4">
              <div class="info-text">
                <p><i class="fa fa-envelope"></i> Regional</p>
                <span class="lead" *ngIf="!edit.regional"
                  >{{ district?.regional?.ragsociale }}
                  <i
                    (click)="edit.regional = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                  ></i>
                </span>
                <i
                  class="fas fa-circle-notch fa-spin"
                  *ngIf="this.loading.regional"
                ></i>
                <div
                  *ngIf="edit.regional && !this.loading.regional"
                  class="input-group input-group-sm"
                >
                  <app-master-agent-select
                    (onChange)="district.regional.id = $event"
                    class="w-75"
                  ></app-master-agent-select>
                  <div class="input-group-append">
                    <button
                      (click)="edit.regional = false"
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-times mr-0"></i>
                    </button>
                    <button
                      (click)="
                        saveDistrictInfo(
                          { id_masteragente: district.regional.id },
                          'regional'
                        )
                      "
                      class="btn btn-light"
                      type="button"
                    >
                      <i class="fas fa-check mr-0"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button *show="['master']" class="btn btn-success" (click)="openModal()">
      Aggiungi Provincia
      <span><i class="fa fa-plus text-white ml-1"></i></span>
    </button>

    <ng-container *ngIf="province">
      <div class="card mt-3">
        <div class="rounded-lg shadow-sm table-responsive p-0">
          <table class="table table-striped mb-0">
            <thead class="bg-dark">
              <tr>
                <th>#</th>
                <th>Provincia</th>
                <th>Sigla</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let provincia of province">
                <td>{{ provincia.id_provincia }}</td>
                <td>{{ provincia.provincia }}</td>
                <td>{{ provincia.sigla }}</td>
                <td>
                  <button
                    class="btn btn-block"
                    (click)="openConfirmDeleteModal(provincia)"
                  >
                    <i class="fas fa-trash text-danger mr-0"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <!-- <tfoot class="bg-white">
                  <tr>
                    <td colspan="3">
                      <div class="d-flex justify-content-between">
                        <p class="p-1">Footer sinistro</p>
                        <p class="p-1">Footer destro</p>
                      </div>
                    </td>
                  </tr>
                </tfoot> -->
          </table>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!--
<ngb-pagination
    size="sm"
    [pageSize]="10"
    [maxSize]="5"
    [(page)]="provincePagination.current_page"
    [collectionSize]="provincePagination.total_items"
    (pageChange)="loadProvince()"
    [boundaryLinks]="true"
  ></ngb-pagination>
 -->

<ng-template #provinceTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title clamp-title">Assegna Provincia</h4>
    <button
      (click)="closeModal()"
      aria-label="Close"
      class="close"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ngx-spinner
      [fullScreen]="false"
      bdColor="rgba(0, 0, 0, 0.8)"
      color="#fff"
      name="addProvincia"
      size="default"
      type="square-jelly-box"
    >
      <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <label>Provincia</label>
    <app-province-select
      (onChange)="changeProvince($event)"
    ></app-province-select>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" (click)="saveProvince()">Salva</button>
  </div>
</ng-template>

<ng-template #confirmDeleteModal let-modal>
  <ngx-spinner
    [fullScreen]="false"
    bdColor="rgba(0, 0, 0, 0.8)"
    color="#fff"
    name="deleteModalSpinner"
    size="default"
    type="square-jelly-box"
  >
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
  <div class="modal-header">
    <h5 class="modal-title">Elimina Provincia</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Sei sicuro di voler procedere?</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      Annulla
    </button>
    <button type="submit" class="btn btn-danger" (click)="confirmDelete()">
      Elimina
    </button>
  </div>
</ng-template>
