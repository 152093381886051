import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {AllReport, Report, ReportCategory, ReportCategoryPagination, ReportPagination, AllReports} from '@models/report';
import {ReportService} from '@services/report.service';
import {MetaPagination} from '@models/meta-pagination';
import {ConfirmOptionsService} from '@services/confirm-options.service';
import {Moment} from 'moment';
import {DaterangepickerConfig} from 'ng2-daterangepicker';
import * as moment from 'moment';
import {User} from '@models/user';
import {AuthenticationService} from '@services/authentication.service';
import {TinyMCE} from '@models/tinyMCE';
import {environment} from '@environments/environment';
import * as XLSX from 'xlsx';
import {AgentVisitsService} from '@services/agent-visits.service';

@Component({
    selector: 'app-report-timeline',
    templateUrl: './report-timeline.component.html',
    styleUrls: ['./report-timeline.component.css']
})
export class ReportTimelineComponent implements OnInit {
    @Input() dealerId;
    @Input()
    hideHeader = false;
    defaultConfig = new TinyMCE();
    busy: any;
    busyCategory: any;
    reportForm = new FormGroup({
        category_id: new FormControl('', Validators.required),
        note: new FormControl('', Validators.required),
    });
    categoryForm = new FormGroup({
        name: new FormControl('', Validators.required)
    });
    categories: ReportCategory[] = [];
    reports: AllReport[] = [];
    reportPagination: MetaPagination;
    categoriesLoading = false;
    categoryModal: NgbModalRef;
    @ViewChild('reportFormTemplate') private reportFormTemplate;
    @ViewChild('categoryFormTemplate') private categoryFormTemplate;
    localTimeFormat = 'HH:mm D MMM';
    localDateFormat = 'DD/MM/YYYY';
    deleteDocumentOptions: ConfirmOptionsService;
    selectedReport: Report;
    selectedDate: string;
    apiDate: {
        from: any,
        to: any
    };

    visit_id = null;
    currentImageIndex = null; // Indice dell'immagine corrente
    visitImages = [];
    isImageLoading: boolean = false;

    get currentVisitImage() {
        if(this.currentImageIndex !== null && this.currentImageIndex < this.visitImages.length){
            return this.visitImages[this.currentImageIndex];
        }
        return null;
    }
    


    currentUser = null;
    dates = [];

    @ViewChild('questionsModal') private questionsModal;
    dealerData = null;

    constructor(public modalService: NgbModal,
                private spinner: NgxSpinnerService,
                private reportService: ReportService,
                private agentVisitsService: AgentVisitsService,
                private dateRangePickerConfig: DaterangepickerConfig,
                private authService: AuthenticationService,
                public authenticationService: AuthenticationService) {
        this.authenticationService.currentUser.subscribe((user: User) => {
                this.currentUser = user?.profile ? user?.profile?.id : null;
            }
        );
        this.deleteDocumentOptions = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro!',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });
        // this.selectedDate = moment().add('-1', 'month').format(this.dateRangePickerConfig.settings.locale.format)
        //     + this.dateRangePickerConfig.settings.locale.separator + moment().format(this.dateRangePickerConfig.settings.locale.format);
        // this.apiDate = {
        //     from: moment().add('-1', 'month').format(this.dateRangePickerConfig.settings.locale.apiFormat),
        //     to: moment().format(this.dateRangePickerConfig.settings.locale.apiFormat)
        // };
    }

    ngOnInit(): void {
        this.loadReports();
    }

    showReportModal(report?: Report) {
        this.selectedReport = report;
        this.loadData();
        this.loadCategories();
        this.modalService.open(this.reportFormTemplate, {size: 'md'});
    }

    showCategoryModal() {
        this.categoryForm.reset();
        this.categoryModal = this.modalService.open(this.categoryFormTemplate, {size: 'sm'});
    }

    loadData() {
        this.reportForm.reset();
        if (this.selectedReport) {
            this.reportForm.setValue({
                category_id: this.selectedReport?.category_id,
                note: this.selectedReport?.note
            });
            this.categories.unshift(this.selectedReport?.category);
        }
    }

    saveReport() {
        const report = new Report();
        report.dealer_id = this.dealerId;
        report.category_id = this.reportForm.value.category_id;
        report.note = this.reportForm.value.note;
        if (this.selectedReport) {
            return this.busy = this.reportService.putReport(this.selectedReport?.id, report).subscribe((response: Report) => {
                this.loadReports();
                this.modalService.dismissAll();
            }, err => {
                this.modalService.dismissAll();
            });
        }
        return this.busy = this.reportService.postReport(report).subscribe((response: Report) => {
            this.loadReports();
            this.modalService.dismissAll();
        }, err => {
            this.modalService.dismissAll();
        });
    }

    saveReportCategory() {
        const category = new ReportCategory();
        category.name = this.categoryForm.value.name;
        this.busyCategory = this.reportService.postReportCategory(category).subscribe((response: ReportCategory) => {
            this.categories.unshift(response);
            this.categories = this.categories.slice(0);
            this.reportForm.get('category_id').setValue(response.id);
            this.categoryModal.dismiss();
        }, err => {
            this.categoryModal.dismiss();
        });
    }

    loadCategories(term?: string) {
        this.categoriesLoading = true;
        const params = {};
        if (term) {
            params['name'] = term;
        }
        this.reportService.getCategories(params).subscribe((response: ReportCategoryPagination) => {
            this.categories = response.data;
            this.categoriesLoading = false;
        }, err => {
            this.categoriesLoading = false;
        });
    }

    loadReports() {
        this.spinner.show('timeline');
        const params = {};
        params['dealer_id'] = this.dealerId;
        if (this.reportPagination?.currentPage) {
            params['page'] = this.reportPagination.currentPage;
        }
        if (this.apiDate?.from && this.apiDate?.to) {
            params['from'] = this.apiDate?.from;
            params['to'] = this.apiDate?.to;
        }
        this.reportService.getAllReports(params).subscribe((response: AllReports) => {
            this.dates = [];
            this.reports = response.data;
            this.reports.forEach(item => {

                for (let k = 0; k < item.documents.length; k++) {
                    let document = item.documents[k];
                    let visitImage = {
                        id: document.id,
                        visitId: document.visit_id,
                        filePath: this.environment.baseUrl + 'visite/get-picture?visit_id=' + document.visit_id + '&id=' + document.id + '&access_token=' + this.authService.currentUserToken,
                    };
                    this.visitImages.push(visitImage);
                }
            
                if (this.visitImages.length > 0) {
                    this.currentImageIndex = 0;
                }

                if (this.dates.indexOf(item.date) === -1) {
                    this.dates.push(item.date);
                }
            });
            // this.reportPagination = response._meta;
            this.spinner.hide('timeline');
        }, err => {
            this.spinner.hide('timeline');
        });
            
    }

    previousImage() {
        if(this.currentImageIndex > 0){
            this.spinner.show('photoTime');
            this.isImageLoading = true; 
            this.currentImageIndex--;
            this.spinner.hide('photoTime')
            this.isImageLoading = false;
        }
    }

    nextImage() {
        if(this.currentImageIndex < this.visitImages.length - 1){
            this.spinner.show('photoTime');
            this.isImageLoading = true; 
            this.currentImageIndex++;
            this.spinner.hide('photoTime')
            this.isImageLoading = false;

        }
    }
    
    deleteReport(id: number) {
        this.reportService.deleteReport(id).subscribe(() => {
            const index = this.reports.findIndex(item => item.id === id);
            if (index > -1) {
                this.reports.splice(index, 1);
                this.reportService.successAlert('Deleted Report!');
            }
        }, (err) => {
            this.reportService.errorAlert(err.message);
        });
    }

    loadNewDate(start: Moment, end: Moment) {
        if (start && end) {
            this.selectedDate = start.format(this.dateRangePickerConfig.settings.locale.format)
                + this.dateRangePickerConfig.settings.locale.separator +
                end.format(this.dateRangePickerConfig.settings.locale.format);
            this.apiDate.from = start.format(this.dateRangePickerConfig.settings.locale.apiFormat);
            this.apiDate.to = end.format(this.dateRangePickerConfig.settings.locale.apiFormat);
            this.loadReports();
        }
    }

    showAll() {
        this.selectedDate = null;
        this.apiDate.from = null;
        this.apiDate.to = null;
        this.loadReports();
    }

    downloadReportExel(){
        let rows = this.reports;
        let output = rows.map((row) => {
            let azioniConcordate = (row.json_data && row.json_data['Azioni commerciali concordate'])
            ? row.json_data['Azioni commerciali concordate'].replace(/[\r\n]+/g, ' ')
            : '';

            return {
                'ID': row.id,
                'District': row.from,
                'Data': row.datetime,
                'Attività chiusa': row.attivita_chiusa.toString() == "0" ? "No" : "Si",
                'Azioni Com. Concordate': azioniConcordate,
                // 'Azioni Com. Concordate': row.json_data ? row.json_data['Azioni commerciali concordate'] : '',
            };
        });

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'export-report-visita.xlsx');
    }
    
    showModalQuestions(id_visit: any){

        this.modalService.open(this.questionsModal,{ size: 'lg', scrollable: true});
        console.log("id della visita", id_visit);

        const params = {
            visitId: id_visit,
        }
        this.dealerData = null;
        this.agentVisitsService
            .getQuestionsDetail(params)
            .subscribe((data) => {
                this.dealerData = data;
            });

    }

    protected readonly environment = environment;
}
