<div class="card bg-gradient-1">
    <div class="card-header">
        <h3 class="mb-0">Avanzamento Gare</h3>
    </div>
    <div class="card-body">
        <div class="form-row align-items-end">
            <div class="col-lg-3 col-12"><label for="date" translate>Mese</label>
                <select class="form-control" [(ngModel)]="filter.month">
                    <option *ngFor="let month of months" [value]="month.id" translate>{{month.name}}</option>
                </select>
            </div>
            <div class="col-lg-3 col-12"><label for="date" translate>Anno</label>
                <select class="form-control" [(ngModel)]="filter.year">
                    <option *ngFor="let year of years" [value]="year.id" translate>{{year.name}}</option>
                </select>
            </div>
            <div class="col-lg-3 col-12"><label
                for="date" translate>Dealer</label>
                <app-retailer-typeahead (onChange)="filter.dealer_id = $event"></app-retailer-typeahead>
            </div>
            <div *show="['master','operator','submaster']" class="col-lg-3 col-12"><label
                for="date" translate>Master Agent</label>
                <app-master-agent-select (onChange)="filter.masteragent_id = $event"></app-master-agent-select>
            </div>
            <div *show="['masteragent', 'master','operator','submaster']" class="col-lg-3 col-12"><label
                for="date" translate>Agent</label>
                <app-agent-select [masteragentId]="filter.masteragent_id" (onChange)="filter.agent_id = $event"></app-agent-select>
            </div>

            <div class="col-lg-3 col-12"><label for="city" translate>Sottocanale</label>
                <app-channel-select
                    (onChange)="this.filter.channel = $event"
                ></app-channel-select>
            </div>

            <div class="col-lg-3 col-12"><label translate>Conferma Partecipazione</label>
                <select class="form-control" [(ngModel)]="filter.confirmation">
                    <option value="" translate>Tutti</option>
                    <option value="0" translate>No</option>
                    <option value="1" translate>Si</option>
                </select>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <button (click)="refresh()" class="btn btn-light"
                translate
                type="submit">Filter
        </button>
    </div>
</div>

<div class="card">
    <div class="card-header">
        <h4 class="mb-0">Lista Partecipanti</h4>
    </div>
    <div class="card-body p-0 px-3">
        <app-spinner status="loading" *ngIf="loading"></app-spinner>
        <table class="table table-sm mb-0" *ngIf="!loading">
            <tr>
                <th>Login</th>
                <th>Ragione Sociale</th>
                <th>Sottocanale</th>
                <th>Regional</th>
                <th>District</th>
                <th>Gara</th>
                <th class="text-center">Tot. ordini</th>
                <th class="text-center">Tot. ordini canc.</th>
                <th class="text-center">Tot. ordini in gara</th>
                <th class="text-center">Soglia</th>
                <th class="text-center">Ordini alla pross. soglia</th>
                <th class="text-center">Conf. Partecipazione</th>
            </tr>
            <tr *ngFor="let row of rows">
                <td>{{row.login_code}}</td>
                <td>{{row.company_name}}</td>
                <td>{{row.subchannel}}</td>
                <td>{{row.district}}</td>
                <td>{{row.regional}}</td>
                <td>{{row.race}}</td>
                <td class="text-center">{{row.total_valid_orders}}</td>
                <td class="text-center">{{row.total_invalid_orders}}</td>
                <td class="text-center">{{row.total_race_orders}}</td>
                <td class="text-center">{{row.range}}</td>
                <td class="text-center">{{row.missing_range_orders}}</td>
                <td class="text-center">{{row.confirmed == '1' ? 'Si' : 'No'}}</td>
            </tr>
        </table>
    </div>
    <div class="card-footer">
        <div class="form-row justify-content-between">
            <div>
                <a *ngIf="originalRows.length > 0" class="text-success btn-link" (click)="exportExcel()">
                    Ci sono {{originalRows.length}} partecipanti. Esporta la lista in Excel
                </a>
            </div>
            <ngb-pagination *ngIf="rows.length>1"
                            size="sm"
                            [pageSize]="perPage"
                            [maxSize]="5"
                            [(page)]="currentPage"
                            (pageChange)="preparePagination()"
                            [collectionSize]="originalRows.length"
                            [boundaryLinks]="true"
            ></ngb-pagination>
        </div>
    </div>
</div>