<ng-select
  [ngClass]="{ 'form-control-sm': small }"
  [items]="data"
  [multiple]="multiple"
  bindValue="id"
  bindLabel="name"
  [(ngModel)]="model"
  (ngModelChange)="emit($event)"
  [clearable]="false"
></ng-select>
