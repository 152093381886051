import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

@Injectable({
  providedIn: "root",
})
export class WispService {
  constructor(public request: RequestService) {}

  getListWithDealer(params?) {
    return this.request.get(`wisp/list-dealer`, params);
  }

  getListWithoutDealer(params?) {
    return this.request.get(`wisp/list-comuni`, params);
  }

  create(payload: FormData) {
    return this.request.post("wisp/create", payload);
  }

  update(id, body: FormData) {
    return this.request.post(`wisp/update/${id}`, body);
  }

  delete(id) {
    return this.request.delete(`wisp/delete/${id}`);
  }
}
