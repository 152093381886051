import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ConcorrenzaService {
  constructor(public request: RequestService) {}

  getListWithDealer(params?): Observable<any> {
    return this.request.get(`concorrenza/list-dealer`, params);
  }

  getListWithoutDealer(params?): Observable<any> {
    return this.request.get(`concorrenza/list-comuni`, params);
  }

  create(payload: FormData) {
    return this.request.post("concorrenza/create", payload);
  }

  update(id, body: FormData) {
    return this.request.post(`concorrenza/update/${id}`, body);
  }

  delete(id) {
    return this.request.delete(`concorrenza/delete/${id}`);
  }
}
