import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "@services/dashboard.service";
import {NgxSpinnerService} from "ngx-spinner";
import {RequestService} from '@services/request.service';

@Component({
    selector: 'app-autoload-keypoints',
    templateUrl: './autoload-keypoints.component.html',
    styleUrls: ['./autoload-keypoints.component.css']
})
export class AutoloadKeypointsComponent implements OnInit {
    @Input() brand: string = null;
    _url: string;
    data: [];

    @Input()
    set url(value: string) {
        this._url = value;

        if (this._url) {
            this.refresh();
        }
    }

    get url() {
        return this._url;
    }

    constructor(private requestService: RequestService,
                private spinner: NgxSpinnerService) {
    }

    ngOnInit() {

    }

    public setUrl(value) {
        this.url = value;
    }

    refresh() {
        this.spinner.show('loader-keypoints');

        let requestUrl = 'dashboard' + this.url;;

        if(this.brand !== null) {
            requestUrl = this.brand + this.url;
        }

        this.requestService.get(requestUrl)
            .subscribe((data) => {
                this.data = data.result;
                this.spinner.hide('loader-keypoints');
            }, (error) => {
                this.spinner.hide('loader-keypoints');
            });
    }

}
