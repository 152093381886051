import {BaseModel} from '@models/base-model';
import {DealerView} from '@models/dealer-view';
import {MetaPagination} from '@models/meta-pagination';
import {Operator} from '@models/operator';

export class Races extends BaseModel {
    id: number;
    id_operator?: number;
    dealer?: DealerView;
    type?: string;

    created_at?: string;
    file?: File;
    operator?: Operator;
    races_files: [];
    races_dealers: [];
    company_name: string;
    operator_name: any;
    code: any;
    dealers: any;

    get postPayload() {
        const payload = new FormData();
        this.addToPayload(payload, this.file, 'file');
        return payload;
    }
}

export class RacesPagination {
    data: Races[];
    _meta: MetaPagination;
    _links: Array<any>;
}


export class RacesFiles extends BaseModel {
    races_id: number;
    title: string;
    description: string;
    file?: File;
}

export class RacesFilesPagination {
    data: RacesFiles[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class RacesDealers extends BaseModel {
    race_id: number;
    dealer_id: number;
    company_name: string;

}

export class RaceDealersPagination {
    data: RacesDealers[];
    _meta: MetaPagination;
    _links: Array<any>;
}
