// @ts-ignore
import {version} from '../../package.json';

export const environment = {
    production: true,
    title: 'Suite Master',
    baseUrl: 'https://api.eolo.suitemaster.it/',
    assetsUrl: 'https://ewr1.vultrobjects.com/mgroup/static/',
    baseAwsUrl: 'https://ewr1.vultrobjects.com',
    apiUrl: 'https://api.eolo.suitemaster.it/v1',
    dashboardUrl: 'https://api.eolo.suitemaster.it/dashboard',
    operatorUrl: 'https://api.eolo.suitemaster.it/operator',
    language: 'it',
    startDate: '2022-01',
    whatsAppUrl: '',
    telegramUrl: '',
    company: {
        url: 'https://eolo.it',
        name: 'Eolo'
    },
    version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
