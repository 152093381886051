<app-master-header
  [ngBusy]="busy"
  cardClass="bg-gradient-info"
  image="assets/img/flats/chart.png"
  title="Concorrenza"
>
  <div class="row justify-content-start align-items-end">
    <div class="col-md-3 col-12">
      <label translate>Date</label>
      <input
        id="documentDate"
        type="text"
        daterangepicker
        [options]="pickerOptions"
        [(ngModel)]="filter.data"
        name="date"
        class="form-control"
        (selected)="generateDateRangeString($event.start, $event.end)"
      />
    </div>
    <ng-container *show="['master', 'submaster']">
      <div class="col-md-3 col-12">
        <label translate>Master Agent</label>
        <app-master-agent-select
          (onChange)="setMasterAgent($event)"
        ></app-master-agent-select>
      </div>
    </ng-container>
    <ng-container *show="['master', 'submaster', 'masteragent']">
      <div class="col-md-3 col-12">
        <label translate>Agent</label>
        <app-agent-select
          [masteragentId]="filter.masteragent_id"
          #agentSelect
          (onChange)="filter.agent_id = $event"
        ></app-agent-select>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab == 'tabDealers'">
      <ng-container *show="['master', 'submaster.', 'agent', 'masteragent']">
        <div class="col-md-3">
          <label for="dealer_id">Partner</label>
          <app-retailer-typeahead
            (onChange)="filter.dealer_id = $event"
          ></app-retailer-typeahead>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="row justify-content-between align-items-end mt-4">
    <div class="col-md-3 h-50">
      <button
        (click)="refreshList()"
        class="btn w-100 btn-light"
        translate
        type="submit"
      >
        Filter
      </button>
    </div>
  </div>
</app-master-header>

<div [ngBusy]="busy" class="container-fluid mt-3 pr-0 mr-0 ml-0 pl-0">
  <div class="row w-100 mr-0 pr-0 pl-0 ml-0">
    <div
      class="col-12 h-50 mb-2 d-flex justify-content-end align-items-center"
      *show="['master', 'operator', 'submaster', 'agent']"
    >
      <button
        class="btn btn-success border border-success"
        (click)="openModal(false)"
      >
        Inserisci
        <span class="ml-2"><i class="fas fa-plus"></i></span>
      </button>
    </div>
    <ngb-tabset (tabChange)="onTabSelect($event)" class="w-100">
      <ngb-tab title="DEALERS" id="tabDealers">
        <ng-template ngbTabContent>
          <div class="card w-100">
            <ngx-spinner
              [fullScreen]="false"
              bdColor="rgba(0, 0, 0, 0.8)"
              color="#fff"
              name="card"
              size="default"
              type="square-jelly-box"
            >
              <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <div class="card-body p-0">
              <table class="table table-striped table-responsive">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Operatori</th>
                    <th>Tecnologia</th>
                    <th>Login Partner</th>
                    <th>Partner</th>
                    <th>Regione</th>
                    <th>Provincia</th>
                    <th>Comune</th>
                    <th>Regional</th>
                    <th>District</th>
                    <th>Canone</th>
                    <th>Note</th>
                    <th>Locandina</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of concorrenzaList">
                    <td style="width: 7%; white-space: nowrap">
                      {{ item.data | amDateFormat : "DD-MM-YYYY" }}
                    </td>
                    <td style="width: 12%" class="text-left text-truncate">
                      {{ item.operatori }}
                    </td>
                    <td style="width: 10%">{{ item.tecnologia }}</td>
                    <td style="width: 10%">{{ item.login_partner }}</td>
                    <td style="width: 10%" class="text-truncate">
                      <span
                        class="text-primary text-decoration-none"
                        style="cursor: pointer"
                        [routerLink]="['/dealer', item.dealer_id]"
                        >{{ item.ragsociale_partner }}</span
                      >
                    </td>
                    <td style="width: 7%">{{ item.regione_nome }}</td>
                    <td style="width: 5%">{{ item.provincia_nome }}</td>
                    <td style="width: 7%">{{ item.comune_nome }}</td>
                    <td style="width: 8%">
                      {{
                        item.ragsociale_regional
                          ? item.ragsociale_regional
                          : "-"
                      }}
                    </td>
                    <td style="width: 8%">
                      {{
                        item.ragsociale_district
                          ? item.ragsociale_district
                          : "-"
                      }}
                    </td>
                    <td style="width: 6%">
                      {{ item.canone | currency : "EUR" : "symbol" : "1.2-2" }}
                    </td>
                    <td style="width: 5%">
                      <button
                        (click)="
                          openPopover(notePopover, item.id, item?.note, 'note')
                        "
                        #notePopover="ngbPopover"
                        autoClose="outside"
                        triggers="manual"
                        [ngbPopover]="popupNote"
                        popoverTitle="Note"
                        class="btn btn-info btn-block btn-sm"
                      >
                        <i class="fas fa-eye mr-0"></i>
                      </button>
                    </td>
                    <td style="width: 5%" class="text-center" *ngIf="item.img">
                      <app-documents-modal
                        #showModal
                        [image]="true"
                      ></app-documents-modal>
                      <div
                        (click)="showModal.showDocument(item.img)"
                        class="link"
                      >
                        <i class="fas fa-search-plus mr-0"></i>
                      </div>
                    </td>
                    <td style="width: 5%" class="text-center" *ngIf="!item.img">
                      -
                    </td>
                    <td style="width: 5%" class="p-0">
                      <div class="d-flex w-100 h-100">
                        <button
                          class="btn btn-link"
                          *show="['master', 'operator', 'submaster']"
                          (click)="openModal(true, item)"
                        >
                          <i class="fas fa-edit text-info"></i>
                        </button>
                        <button
                          class="btn btn-link"
                          *show="['master', 'operator', 'submaster']"
                          (click)="openDeleteModal(item.id)"
                        >
                          <i class="fas fa-times text-red"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="row">
                <div *ngIf="concorrenzaList?.length > 0" class="col-6">
                  <a (click)="export()" class="text-success cursor-hand"
                    ><i class="fas fa-table"></i> Esporta lista in excel
                    <span class="text-sm"
                      >({{ concorrenzaList?.length }} righe)</span
                    >
                  </a>
                </div>
                <div class="col-6">
                  <ngb-pagination
                    *ngIf="pagination"
                    [(page)]="pagination.currentPage"
                    [pageSize]="pagination.perPage"
                    [boundaryLinks]="true"
                    size="sm"
                    [collectionSize]="pagination.pageCount * pagination.perPage"
                    [maxSize]="5"
                    (pageChange)="reloadList($event)"
                    [ellipses]="false"
                    [hidden]="pagination.pageCount <= 1"
                  >
                    <ng-template ngbPaginationFirst>««</ng-template>
                    <ng-template ngbPaginationPrevious>«</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{
                      page
                    }}</ng-template>
                    <ng-template ngbPaginationNext>»</ng-template>
                    <ng-template ngbPaginationLast>»»</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="COMUNI" id="tabComuni">
        <ng-template ngbTabContent>
          <div class="card w-100">
            <ngx-spinner
              [fullScreen]="false"
              bdColor="rgba(0, 0, 0, 0.8)"
              color="#fff"
              name="card"
              size="default"
              type="square-jelly-box"
            >
              <p style="color: white">Caricamento...</p>
            </ngx-spinner>
            <div class="card-body p-0 table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Creato da</th>
                    <th>Regional</th>
                    <th>Operatori</th>
                    <th>Tecnologia</th>
                    <th>Regione</th>
                    <th>Provincia</th>
                    <th>Comune</th>
                    <th>Canone</th>
                    <th>Note</th>
                    <th>Locandina</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of concorrenzaList">
                    <td style="white-space: nowrap">
                      {{ item.data | amDateFormat : "DD-MM-YYYY" }}
                    </td>
                    <td>
                      {{
                        item.ragsociale_regional
                          ? item.ragsociale_regional
                          : "-"
                      }}
                    </td>
                    <td>
                      {{
                        item.ragsociale_district
                          ? item.ragsociale_district
                          : "-"
                      }}
                    </td>
                    <td class="text-truncate">
                      {{ item.operatori }}
                    </td>
                    <td>{{ item.tecnologia }}</td>
                    <td>{{ item.regione_nome }}</td>
                    <td>{{ item.provincia_nome }}</td>
                    <td>{{ item.comune_nome }}</td>
                    <td>
                      {{ item.canone | currency : "EUR" : "symbol" : "1.2-2" }}
                    </td>
                    <td>
                      <button
                        (click)="
                          openPopover(notePopover, item.id, item?.note, 'note')
                        "
                        #notePopover="ngbPopover"
                        autoClose="outside"
                        triggers="manual"
                        [ngbPopover]="popupNote"
                        popoverTitle="Note"
                        class="btn btn-info btn-block btn-sm"
                      >
                        <i class="fas fa-eye mr-0"></i>
                      </button>
                    </td>
                    <td class="text-center" *ngIf="item.img">
                      <app-documents-modal
                        #showModal
                        [image]="true"
                      ></app-documents-modal>
                      <div
                        (click)="showModal.showDocument(item.img)"
                        class="link"
                      >
                        <i class="fas fa-search-plus mr-0"></i>
                      </div>
                    </td>
                    <td class="text-center" *ngIf="!item.img">-</td>
                    <td class="p-0">
                      <div class="d-flex w-100 h-100">
                        <button
                          class="btn btn-link"
                          *show="['master', 'operator', 'submaster']"
                          (click)="openModal(true, item)"
                        >
                          <i class="fas fa-edit text-info"></i>
                        </button>
                        <button
                          class="btn btn-link"
                          *show="['master', 'operator', 'submaster']"
                          (click)="openDeleteModal(item.id)"
                        >
                          <i class="fas fa-times text-red"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="row">
                <div *ngIf="concorrenzaList?.length > 0" class="col-6">
                  <a (click)="export()" class="text-success cursor-hand"
                    ><i class="fas fa-table"></i> Esporta lista in excel
                    <span class="text-sm"
                      >({{ concorrenzaList?.length }} righe)</span
                    >
                  </a>
                </div>
                <div class="col-6">
                  <ngb-pagination
                    *ngIf="pagination"
                    [(page)]="pagination.currentPage"
                    [pageSize]="pagination.perPage"
                    [boundaryLinks]="true"
                    size="sm"
                    [collectionSize]="pagination.pageCount * pagination.perPage"
                    [maxSize]="5"
                    (pageChange)="reloadList($event)"
                    [ellipses]="false"
                    [hidden]="pagination.pageCount <= 1"
                  >
                    <ng-template ngbPaginationFirst>««</ng-template>
                    <ng-template ngbPaginationPrevious>«</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{
                      page
                    }}</ng-template>
                    <ng-template ngbPaginationNext>»</ng-template>
                    <ng-template ngbPaginationLast>»»</ng-template>
                  </ngb-pagination>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>

<ng-template #deleteModal let-modal>
  <ngx-spinner
    [fullScreen]="false"
    bdColor="rgba(0, 0, 0, 0.8)"
    color="#fff"
    name="deleteModalSpinner"
    size="default"
    type="square-jelly-box"
  >
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
  <div class="modal-header">
    <h5 class="modal-title">Elimina</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Sei sicuro di voler eliminare il record?</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      Annulla
    </button>
    <button type="submit" class="btn btn-danger" (click)="deleteRecord()">
      Elimina
    </button>
  </div>
</ng-template>

<ng-template #insertModal let-modal>
  <ngx-spinner
    [fullScreen]="false"
    bdColor="rgba(0, 0, 0, 0.8)"
    color="#fff"
    name="modalSpinner"
    size="default"
    type="square-jelly-box"
  >
    <p style="color: white">Caricamento...</p>
  </ngx-spinner>
  <div class="modal-header">
    <h5 class="modal-title">Inserisci Dati</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-6">
          <ng-container *ngIf="activeTab == 'tabDealers'">
            <div class="form-group" *ngIf="!selectedItem?.dealer_id">
              <label for="dealer_id">Dealer</label>
              <app-retailer-typeahead
                (onChange)="form.get('dealer_id')?.setValue($event)"
              ></app-retailer-typeahead>
              <div
                *ngIf="
                  form.get('dealer_id')?.invalid &&
                  form.get('dealer_id')?.touched
                "
                class="invalid-feedback"
              >
                Il campo "Partner" è richiesto.
              </div>
            </div>

            <div class="formGroup" *ngIf="selectedItem?.dealer_id">
              <div class="form-group mt-2 mb-2">
                <label class="text-sm text-muted">Dealer</label>
                <div>{{ selectedItem?.ragsociale_partner }}</div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="activeTab == 'tabComuni'">
            <div class="row justify-content-between align-items-end mb-0">
              <div class="col-lg-4 col-12" *ngIf="!selectedItem?.regione_id">
                <div class="form-group">
                  <label for="region" translate>Region</label>
                  <app-state-select
                    (onChange)="changeRegion($event)"
                    id="region"
                  ></app-state-select>
                </div>
              </div>
              <div class="col-lg-4 col-12" *ngIf="selectedItem?.regione_id">
                <div class="form-group mt-2 mb-2">
                  <label class="text-sm text-muted">Regione</label>
                  <div>{{ selectedItem?.regione_nome }}</div>
                </div>
              </div>
              <div class="col-lg-4 col-12" *ngIf="!selectedItem?.provincia_id">
                <div class="form-group">
                  <label for="province" translate>Provincia</label>
                  <app-province-select
                    (onChange)="changeProvince($event)"
                    [onDemand]="true"
                    [state]="this.filter.region_id"
                    id="province"
                  ></app-province-select>
                </div>
              </div>
              <div class="col-lg-4 col-12" *ngIf="selectedItem?.provincia_id">
                <div class="form-group mt-2 mb-0">
                  <label class="text-sm text-muted">Provincia</label>
                  <p>{{ selectedItem?.provincia_nome }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-12" *ngIf="!selectedItem?.comune_id">
                <div class="form-group">
                  <label for="city" translate>City</label>
                  <app-city-select
                    [onDemand]="true"
                    [province]="this.filter.province_id"
                    (onChange)="onCityChange($event)"
                    id="city"
                  ></app-city-select>
                </div>
              </div>
              <div class="col-lg-4 col-12" *ngIf="selectedItem?.comune_nome">
                <div class="form-group mt-2 mb-0">
                  <label class="text-sm text-muted">Comune</label>
                  <p>{{ selectedItem?.comune_nome }}</p>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="form-group">
            <label for="data">Data</label>
            <input
              type="date"
              class="form-control"
              id="data"
              formControlName="data"
              max="{{ today | date : 'yyyy-MM-dd' }}"
            />
            <div
              *ngIf="form.get('data').invalid && form.get('data').touched"
              class="invalid-feedback"
            >
              Campo richiesto
            </div>
          </div>

          <div class="form-group">
            <label for="note">Note</label>
            <textarea
              class="form-control"
              id="note"
              formControlName="note"
              [ngClass]="{
                'is-invalid':
                  form.get('note').invalid && form.get('note').touched
              }"
            ></textarea>
            <div
              *ngIf="form.get('note').invalid && form.get('note').touched"
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  form.get('note').errors?.required && !areFieldsPopulated()
                "
              >
                Il campo "Note" è richiesto se il campo "Canone" non è
                compilato.
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label translate>Operatori</label>
            <ng-select
              [multiple]="true"
              addTagText="Aggiungi altro operatori"
              [addTag]="true"
              formControlName="operatori"
            >
              <ng-option value="Vodafone">Vodafone</ng-option>
              <ng-option value="WindTre">WindTre</ng-option>
              <ng-option value="Tim">Tim</ng-option>
              <ng-option value="ILIAD">ILIAD</ng-option>
              <ng-option value="Tiscali">Tiscali</ng-option>
              <ng-option value="Fastweb">Fastweb</ng-option>
              <ng-option value="Linkem">Linkem</ng-option>
              <ng-option value="Sky WiFi">Sky WiFi</ng-option>
            </ng-select>
            <div
              *ngIf="
                form.get('operatori').invalid && form.get('operatori').touched
              "
              class="invalid-feedback"
            >
              Campo richiesto
            </div>
          </div>
          <div class="form-group">
            <label for="tecnologia">Tecnologia</label>
            <ng-select
              id="tecnologia"
              formControlName="tecnologia"
              [ngClass]="{
                'is-invalid':
                  form.get('tecnologia').invalid &&
                  form.get('tecnologia').touched
              }"
            >
              <ng-option value="FWA">FWA</ng-option>
              <ng-option value="FTTC">FTTC</ng-option>
              <ng-option value="FTTH">FTTH</ng-option>
            </ng-select>
            <div
              *ngIf="
                form.get('tecnologia').invalid && form.get('tecnologia').touched
              "
              class="invalid-feedback"
            >
              Campo richiesto
            </div>
          </div>
          <div class="form-group">
            <label for="canone">Canone</label>
            <input
              type="number"
              class="form-control"
              id="canone"
              formControlName="canone"
              [ngClass]="{
                'is-invalid':
                  form.get('canone').invalid && form.get('canone').touched
              }"
            />
            <div
              *ngIf="form.get('canone').invalid && form.get('canone').touched"
              class="invalid-feedback"
            >
              <div *ngIf="form.get('canone').errors?.pattern">
                Il campo "Canone" deve contenere solo numeri, punti o virgole.
              </div>
              <div
                *ngIf="
                  form.get('canone').errors?.required && !areFieldsPopulated()
                "
              >
                Il campo "Canone" è richiesto se il campo "Note" non è
                compilato.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="img">Immagine (jpg, jpeg, png)</label>
            <input
              type="file"
              class="form-control-file"
              id="img"
              accept="image/*"
              (change)="onFileChange($event)"
            />
            <div *ngIf="hasImgError()" class="invalid-feedback">
              Il formato dell'immagine deve essere JPG o PNG.
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      Chiudi
    </button>
    <button
      type="submit"
      class="btn btn-primary"
      (click)="submitForm()"
      [disabled]="!form.valid"
    >
      Salva
    </button>
  </div>
</ng-template>

<ng-template #popupNote>
  <div class="d-flex align-items-center my-2 w-100 popup-container">
    <div class="mr-2" readonly>{{ popupConfig.inputValue }}</div>
    <button class="btn btn-light" type="button" (click)="closePopover()">
      <i class="fa fa-times m-0"></i>
    </button>
  </div>
</ng-template>
