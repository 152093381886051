<form [formGroup]="raceDealersForm" (ngSubmit)="save()" class="form-height" [ngBusy]="busy">
    <div>
        <div class="m-3 mb-5">
            <app-retailer-typeahead  (onChange)="selectDealer($event)"></app-retailer-typeahead>
        </div>
        <div class="col-12 mt-4">
            <button type="submit" class="btn btn-success btn-block mb-3" [disabled]="raceDealersForm.invalid">
                <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
            </button>
        </div>
    </div>
</form>

