import { VisiteService } from '@services/visite.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardService } from '@services/dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgenteService } from '@services/agente.service';
import { CalendarOptions } from '@fullcalendar/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { AgentVisitsService } from '@services/agent-visits.service';
import { environment } from '@environments/environment';
import convert from 'client-side-image-resize';
import { Helpers } from '@helpers/helpers';
import * as XLSX from 'xlsx';
import { ConfirmOptionsService } from '@app/services/confirm-options.service';


@Component({
  selector: 'app-agent-visite-index',
  templateUrl: './agent-visite-index.component.html',
  styleUrls: ['./agent-visite-index.component.css'],
})
export class AgentVisiteIndexComponent implements OnInit {
  @ViewChild('questionsModal') private questionsModal;
  busy: Subscription;
  busyModal: Subscription;
  startup: {
    agente: '';
    periodi: any;
    periodo: any;
  };

  selectedDateRange = {
    from: '',
    to: '',
  };

  filterForm = {
    dateRange: '',
  };

  urlSubheader: string;
  id: any;
  urlTabellaDealers: any;
  table = {
    rows: [],
    columns: [],
  };

  visiteProgrammate = {
    rows: [],
    columns: [],
  };

  @ViewChild('visitFormModal') visitFormModal;

  defaultView = true;
  global: any;
  timeline: any = [];
  baseAwsUrl = environment.baseAwsUrl;
  currentLocation = {
    lat: null,
    lng: null,
  };
  maxDate;

  visitForm: FormGroup;
  visitFormMode = 'new';
  visitFormReady = false;
  visitFormDataReady = false;
  visitFormStructure: any = [];
  visitFormStructureProspect: any = [];
  visitFormInitialData: any = [];
  saving: Subscription;
  savingVisit = false;
  visitOperators = '';

  showCap = false;
  dealerData = null;

  deleteVisitOption: ConfirmOptionsService;
  isEdit = true;


  constructor(
    public modalService: NgbModal,
    private dashboardService: DashboardService,
    private agentVisitsService: AgentVisitsService,
    private service: VisiteService,
    private agenteService: AgenteService,
    public formBuilder: FormBuilder,
    private dateRangePickerOptions: DaterangepickerConfig,
    public route: ActivatedRoute,
  ) {
    this.maxDate = {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('MM')),
      day: parseInt(moment().format('DD')),
    };
    this.id = this.route.snapshot.paramMap.get('id');
    this.dateRangePickerOptions.settings.locale.format = 'DD/MM/YYYY';
    this.setDefaultRange();

    this.deleteVisitOption = new ConfirmOptionsService({
      title: 'Se sicuro di voler cancellare la visita registrata?',
      text: 'Non potrai tornare indietro',
      confirmButtonText: 'Si',
      cancelButtonText: 'Annulla',
    });
  }

  ngOnInit() {
    this.loadVisitSchedule();
  }

  refresh() {
    this.busy = this.service
      .salesAgentOverview(
        this.selectedDateRange.from,
        this.selectedDateRange.to,
      )
      .subscribe((data) => {
        this.timeline = data.timeline;
        this.global = data.overview;

        this.refreshTable();
      });
  }

  setDefaultRange() {
    this.setSelectedDateRange(moment().startOf('month'), moment());
  }

  setSelectedDateRange(from: Moment, to: Moment) {
    if (from.isSame(moment().startOf('month')) && to.isSame(moment())) {
      this.defaultView = true;
    } else {
      this.defaultView = false;
    }

    const selectedDateRange = this.getDateString(from, to);
    this.filterForm.dateRange = selectedDateRange;
    this.refresh();
  }

  loadVisitSchedule() {
    this.agenteService.visiteProgrammate().subscribe((data) => {
      this.visiteProgrammate = data.result;
    });
  }

  trigger($event: any) {
    this.visitFormReady = false;
    this.visitFormDataReady = false;
    this.visitFormMode = 'new';
    this.modalService.open(this.visitFormModal, { size: 'lg', scrollable: true });

    this.agentVisitsService
      .getVisitFormStructure({ dealerId: $event.value.id })
      .subscribe((data) => {
        this.visitFormStructure = data.additionalStructure;
        this.visitFormInitialData = data.initialValues;
        this.generateFormStructure(null, $event.value.id);
      });
  }

  triggerProgrammata($event: any) {
    this.visitFormReady = false;
    this.visitFormDataReady = false;
    if ($event.value.dealer_id === null) {
      this.visitFormMode = 'unknown';
    } else {
      this.visitFormMode = 'scheduled';
    }
    this.modalService.open(this.visitFormModal, { size: 'lg', scrollable: true });

    this.agentVisitsService
      .getVisitFormStructure({ dealerId: $event.value.dealer_id, scheduledId: $event.value.id })
      .subscribe((data) => {
        this.visitFormStructure = data.additionalStructure;
        this.visitFormInitialData = data.initialValues;
        this.generateFormStructure($event.value.id, $event.value.dealer_id);
      });
  }

  triggerCustom() {
    this.visitFormReady = false;
    this.visitFormDataReady = false;
    this.visitFormMode = 'unknown';
    this.modalService.open(this.visitFormModal, { size: 'lg', scrollable: true });

    this.agentVisitsService
      .getVisitFormStructure()
      .subscribe((data) => {
        // this.visitFormStructure = data.additionalStructure;
        this.visitFormStructureProspect = data.additionalStructureProspect;
        this.visitFormInitialData = data.initialValues;
        this.generateFormStructure(null, null);
      });
  }

  showModalQuestions(id_visit: any) {

    this.modalService.open(this.questionsModal, { size: 'lg', scrollable: true });

    const params = {
      visitId: id_visit,
    };
    this.dealerData = null;
    this.agentVisitsService
      .getQuestionsDetail(params)
      .subscribe((data) => {
        this.dealerData = data;
      });

  }


  downloadQuestionExcel() {
    let rows = this.dealerData;
    let output = [{
      'Nome': rows.dealerInfo.nome,
      'Cognome': rows.dealerInfo.cognome,
      'Attività chiusa': rows.is_activity_closed ? 'Si' : 'No',
      'Indirizzo': rows.dealerInfo.indirizzo,
      'Civico': rows.dealerInfo.civico,
      'Comune': rows.dealerInfo.localita, // o 'provincia' se il dato è più adatto
      'CAP': rows.dealerInfo.cap,
      'Telefono': rows.dealerInfo.telefono,
      'Cellulare': rows.dealerInfo.cellulare,
      'Email': rows.dealerInfo.email,
      'Giorni di chiusura': rows.dealerInfo.closing_days.join(', '), // Converti l'array in stringa
      'Materiale esposto': rows.dealerInfo.material_exposed.join(', '), // Converti l'array in stringa
      'Operatori': rows.dealerInfo.brands.join(', '),
      'Vetrina Dedicata EOLO': rows.json_data.vetrina_dedicata_eolo,
      'Azioni commerciali concordate': rows.json_data.azioni_commerciali_concordate,
      'note': rows.json_data.note,
    }];

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(output);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'export-questionario-visite.xlsx');
  };


  sendReply(item: any) {
    this.busy = this.agentVisitsService
      .postResponse({
        id_visita: item.id,
        testo: item.reply,
      })
      .subscribe((data) => {
        this.refresh();
      });
  }

  generateFormStructure(scheduled_id: any, dealer_id: any) {
    this.visitFormReady = false;
    this.visitFormDataReady = false;
    this.showCap = false;

    if (dealer_id) {
      this.visitForm = this.formBuilder.group({
        schedule_id: [scheduled_id, []],
        dealer_id: [dealer_id, [Validators.required]],
        closed_activity: ['No', [Validators.required]],
        address: [null, [Validators.required]],
        address_number: [null, [Validators.required]],
        city_name: [null, [Validators.required]],
        cap: [null, [Validators.required, Validators.pattern('^[0-9]{5}$')]],
        dealerAdditional: this.formBuilder.group({
          name: [null, [Validators.required]],
          surname: [null, [Validators.required]],
          phone: [null, [Validators.required]],
          mobile: [null, [Validators.required]],
          email: [null, [Validators.required]],
          closing_days: [null, [Validators.required]],
          brands: [null, [Validators.required]],
          advertising: [null, [Validators.required]],
        }),
        additional: this.formBuilder.group({}),
        documents: new FormArray([]),
      });

      this.visitForm.controls.closed_activity.valueChanges.subscribe(this.closedActivityChange.bind(this));

      this.visitForm.patchValue(this.visitFormInitialData);
    } else {
      this.visitForm = this.formBuilder.group({
        schedule_id: [null, []],
        dealer_id: [null, []],
        closed_activity: ['No', []],
        shop: ['', [Validators.required]],
        address: ['', [Validators.required]],
        address_number: [null, [Validators.required]],
        city_name: [null, [Validators.required]],
        cap: [null, [Validators.required, Validators.pattern('^[0-9]{5}$')]],
        additional: this.formBuilder.group({}),
        documents: new FormArray([]),
      });

      this.visitForm.patchValue(this.visitFormInitialData);
    }

    const additionalGroup = this.visitForm.get('additional') as FormGroup;

    this.visitFormStructure.forEach((item) => {
      additionalGroup.addControl(item.name, new FormControl(item.value, item.validators));
    });
    this.visitFormStructureProspect.forEach((item) => {
      additionalGroup.addControl(item.name, new FormControl(item.value, item.validators));
    });

    this.visitFormDataReady = true;
    this.visitFormReady = true;
    this.saving = null;
  }

  closedActivityChange(value: any) {
    const scheduled_id = this.visitForm.controls.schedule_id.value;
    const dealer_id = this.visitForm.controls.dealer_id.value;
    if (value === 'Sì') {
      this.visitFormDataReady = false;
      this.visitForm = this.formBuilder.group({
        schedule_id: [scheduled_id, []],
        dealer_id: [dealer_id, [Validators.required]],
        closed_activity: ['Sì', [Validators.required]],
      });
      this.visitForm.controls.closed_activity.valueChanges.subscribe(this.closedActivityChange.bind(this));
    } else {
      this.modalService.dismissAll();
      this.generateFormStructure(scheduled_id, dealer_id);
    }
  }

  addDocument(form: FormGroup) {
    const documents = form.get('documents') as FormArray;
    documents.push(new FormGroup({
      file: new FormControl(''),
      file_name: new FormControl(''),
    }));
  }

  removeDocument(form: FormGroup, index) {
    const documents = form.get('documents') as FormArray;
    return documents.removeAt(index);
  }

  handleFileInput(file, form: FormGroup) {
    convert({
      file: file,
      height: 1024,
      type: 'jpeg',
    }).then(resp => {
      form.get('file').setValue(resp);
      form.get('file_name').setValue(resp?.name);
    }).catch(error => {
      // Error
    });


  }

  handleFileRemove(form: FormGroup) {
    form.get('file').setValue(null);
    form.get('file_name').setValue('');
  }

  saveVisit() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.currentLocation.lat = position.coords.latitude;
      this.currentLocation.lng = position.coords.longitude;

      this.postVisit();
    }, (error) => {
      this.currentLocation.lat = null;
      this.currentLocation.lng = null;

      this.postVisit();
    });
  }

  postVisit() {
    const data = this.visitForm.value;
    if (this.currentLocation.lat === null || this.currentLocation.lng === null) {
      this.agentVisitsService.errorAlert('Non è stato possibile registrare la posizione');
      return;
    }

    data.current_lat = this.currentLocation.lat.toString();
    data.current_lng = this.currentLocation.lng.toString();

    const formData = new FormData();
    Helpers.convertDictToFormData(formData, data);

    this.savingVisit = true;
    this.saving = this.agentVisitsService.postVisit(formData)
      .subscribe((data) => {
        this.modalService.dismissAll();
        this.visitForm.reset();
        this.refresh();
        this.saving = null;
        this.savingVisit = false;
      }, (error) => {
        this.saving = null;
        this.savingVisit = false;
      });
  }

  private getDateString(startDate: any, endDate: any) {
    const localDateFormat = this.dateRangePickerOptions.settings.locale.format;
    const start = moment(startDate);
    const end = moment(endDate);
    this.selectedDateRange.from = start.format('YYYY-MM-DD');
    this.selectedDateRange.to = end.format('YYYY-MM-DD');
    return (
      start.format(localDateFormat) +
      this.dateRangePickerOptions.settings.locale.separator +
      end.format(localDateFormat)
    );
  }

  private refreshTable() {
    this.busy = this.dashboardService
      .get(`/visite-tabella`)
      .subscribe((data) => {
        this.table = data.result;
      });
  }

  setAddress($event: any) {
    this.showCap = false;

    if ($event.postalCode === null) {
      this.showCap = true;
    }

    this.visitForm.patchValue({
      address: $event.address,
      address_number: $event.streetNumber === null ? 'snc' : $event.streetNumber,
      city_name: $event.city,
      cap: $event.postalCode,
    });
  }


  deleteTimelineVisit(id: number) {
    this.agentVisitsService.deleteVisit(id).subscribe(
      () => {
        const index = this.timeline.findIndex((dealer) => dealer.id === id);
        if (index) {
          this.agentVisitsService.successAlert('cancellata correttamente!!!');

          this.refresh();
        }
      },
      (err) => {
        this.agentVisitsService.errorAlert(err.message);
      },
    );
  }
}
