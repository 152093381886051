import {BaseModel} from '@models/base-model';
import {MetaPagination} from '@models/meta-pagination';

export class Report extends BaseModel {
    id: number;
    note: string;
    user_id: number;
    dealer_id: number;
    category_id: number;
    user_name: string;
    dealer_name: string;
    created_at: string;
    category: ReportCategory;
    date?:string;
}

export class AllReport extends BaseModel {
    id: number;
    type: string;
    from?: string;
    duration?: string;
    text: string;
    category: string;
    datetime: string;
    documents: any;
    date: string;
    user_id: string;
    json_data: string;
    affiliato : string;
    attivita_chiusa : string;
}

export class AllReports extends BaseModel {
    data: AllReport[];
}

export class ReportPagination extends BaseModel {
    data: Report[];
    _meta: MetaPagination;
    _links: Array<any>;
}

export class ReportCategory extends BaseModel {
    id: number;
    name: string;
}

export class ReportCategoryPagination extends BaseModel {
    data: ReportCategory[];
    _meta: MetaPagination;
    _links: Array<any>;
}
