<div class="card bg-gradient-1">
    <div class="card-body">
        <div class="d-flex align-items-lg-center">
            <img class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
                 src="assets/img/flats/placeholder.png"
                 width="160px">
            <div class="d-block w-100 pl-lg-3"><h1 class="display-4" translate>GPS tracking</h1>
                <hr>
                <div class="form-row align-items-end">
                        <div class="col-lg-3 col-12"><label translate>Operatore</label>
                            <app-vendor-select (onChange)="changeVendor($event)" [outputLabel]="true" [pickFirst]="true"
                            ></app-vendor-select>
                        </div>
                        <div *show="['master','operator','submaster']" class="col-lg-3 col-12"><label
                                for="date" translate>Master Agent</label>
                            <app-master-agent-select (onChange)="filter.masteragent_id = $event"></app-master-agent-select>
                        </div>
                        <div *show="['masteragent', 'master','operator','submaster']" class="col-lg-3 col-12"><label
                                for="date" translate>Agent</label>
                            <app-agent-select [masteragentId]="filter.masteragent_id" (onChange)="filter.agent_id = $event"></app-agent-select>
                        </div>
                        <!--                        <div class="col-lg-4 col-12"><label for="shop" translate>Shop</label>-->
                        <!--                            <input [(ngModel)]="filter.shop" class="form-control" id="shop"-->
                        <!--                                   name="shop" type="text"-->
                        <!--                            />-->
                        <!--                        </div>-->
                        <div class="col-lg-3 col-12"><label for="date" translate>Date</label>
                            <input
                                    [(ngModel)]="this.filter.dateOutput"
                                    class="form-control"
                                    (selected)="setSelectedDateRange($event.start, $event.end)"
                                    daterangepicker
                                    id="date"
                                    name="date" type="text"
                            />
                        </div>
                        <div class="col-lg-3 col-12"><label for="region" translate>Region</label>
                            <app-state-select (onChange)="changeRegion($event)"
                                              id="region"
                            ></app-state-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="province" translate>Province</label>
                            <app-province-select (onChange)="changeProvince($event)"
                                                 [onDemand]="true"
                                                 [state]="this.filter.region_id"
                                                 id="province"
                            ></app-province-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="city" translate>City</label>
                            <app-city-select (onChange)="this.filter.city_id = $event"
                                             [onDemand]="true"
                                             [province]="this.filter.province_id"
                                             id="city"
                            ></app-city-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="city" translate>Sottocanale</label>
                            <app-channel-select
                                    (onChange)="this.filter.channel = $event"
                            ></app-channel-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="city" translate>Cluster Comune</label>
                            <app-cluster-select
                                    (onChange)="this.filter.cluster = $event"
                            ></app-cluster-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="city" translate>Tipologia Visita</label>
                            <ng-select [(ngModel)]="this.filter.type" (change)="this.filter.type = $event;">
                                <ng-option value="">Tutti</ng-option>
                                <ng-option value="Potenziale">Potenziale</ng-option>
                                <ng-option value="Partner">Partner</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-12"><label for="city" translate>Partner Funzionale</label>
                            <ng-select [(ngModel)]="this.filter.functional" (change)="this.filter.functional = $event;">
                                <ng-option value="">Tutti</ng-option>
                                <ng-option value="Si">Funzionale</ng-option>
                                <ng-option value="No">Non Funzionale</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-12 mt-2 mt-lg-auto">
                            <button (click)="loadData()" [disabled]="filter.region_id && !filter.province_id" class="btn btn-light btn-block"
                                    translate
                                    type="submit">Filter
                            </button>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <ngx-spinner
            [fullScreen]="false"
            bdColor="rgba(0, 0, 0, 0.8)"
            color="#fff"
            name="dashboard"
            size="default"
            type="square-jelly-box"
    >
        <p style="color: white">Caricamento...</p>
    </ngx-spinner>
    <div class="card-body">
        <div class="row d-flex">
            <div class="col-lg-6 col-12 dashboard-card">
                <div class="d-flex align-items-center">
                    <img alt="" class="d-inline-flex" src="assets/img/flats/deal.png"
                         width="80px">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Visits</p>
                        <h1 class="display-6">{{report ? report.total_visits : 0}}</h1>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 dashboard-card">
                <div class="d-flex align-items-center">
                    <img class="d-inline-flex" src="assets/img/flats/destination.png"
                         width="80px">
                    <div class="d-block w-100 pl-3">
                        <p class="lead" translate>Media visite giornaliere</p>
                        <h1 class="display-6">{{report ? report.average_visits_daily : 0}}</h1>
                    </div>
                </div>
            </div>
            <!--            <div class="col-lg-4 col-12 dashboard-card">-->
            <!--                <div class="d-flex align-items-center"><img-->
            <!--                        class="d-inline-flex" src="assets/img/flats/time.png"-->
            <!--                        width="80px">-->
            <!--                    <div class="d-block w-100 pl-3">-->
            <!--                        <p class="lead" translate>Media visite totali</p>-->
            <!--                        <h1 class="display-6">{{report ? report.average_visits_duration : null}}</h1>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            <div class="col-lg-3 col-12 dashboard-card">-->
            <!--                <div class="d-flex align-items-center"><img-->
            <!--                        class="d-inline-flex" width="80px"-->
            <!--                        src="assets/img/flats/time.png">-->
            <!--                    <div class="d-block w-100 pl-3">-->
            <!--                        <p class="lead" translate>Average stops duration</p>-->
            <!--                        <h1 class="display-6">{{report ? report.average_stops_duration : null}}</h1>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 col-lg-6">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-9 col-12">
                        <h4 class="mb-0" translate>Map</h4>
                        <table>
                            <tr>
                                <td>
                                    <img [src]="mapAgentPinOptions.url" height="24" /></td>
                                <td>Visita Partner</td>
                                <td>
                                    <img [src]="mapPotentialPinOptions.url" height="24" /></td>
                                <td>Visita Potenziale</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-lg-3 col-12 d-flex justify-content-end">
                        <div class="form-check form-check-inline">
                            <input (change)="mode=0" checked class="form-check-input" id="inlineRadio1"
                                   name="inlineRadioOptions" type="radio" value="option1">
                            <label class="form-check-label" for="inlineRadio1" translate>Visits</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input (change)="mode=1" class="form-check-input" id="inlineRadio2"
                                   name="inlineRadioOptions"
                                   type="radio" value="option2">
                            <label class="form-check-label" for="inlineRadio2" translate>Dealers</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input (change)="mode=2" class="form-check-input" id="inlineRadio3"
                                   name="inlineRadioOptions"
                                   type="radio" value="option3">
                            <label class="form-check-label" for="inlineRadio3" translate>Entrambi</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body p-0">
                <ngx-spinner
                        [fullScreen]="false"
                        bdColor="rgba(0, 0, 0, 0.8)"
                        color="#fff"
                        name="map"
                        size="default"
                        type="square-jelly-box"
                >
                    <p style="color: white">Caricamento...</p>
                </ngx-spinner>
                <agm-map #gm
                         [clickableIcons]="false"
                         [latitude]='42.2894929'
                         [longitude]='11.7101332'
                         [showDefaultInfoWindow]="false"
                         [styles]="defaultStyles"
                         [zoom]="6"
                         mapTypeId="roadmap">
                    <ng-container *ngIf="visitsData && visitsData.length > 0">
                        <ng-container *ngIf="mode === 0 || mode === 2">
                            <agm-marker
                                    *ngFor="let visit of visitsData"
                                    [iconUrl]="getIcon(visit)"
                                    [latitude]="visit.default_current_lat"
                                    [longitude]="visit.default_current_lng"
                            >
                                <agm-info-window>
                                    <p *ngIf="visit.default_type === 'Partner'">Partner</p>
                                    <p *ngIf="visit.default_type === 'Potenziale'">Potenziale Partner</p>
                                    <b>{{visit.default_shop}}</b>
                                    <p>District</p>
                                    <b>{{visit.default_agent_name}}</b>
                                    <p>Data</p>
                                    <b>{{visit.default_datetime | amDateFormat: 'DD/MM/YYYY HH:mm'}}</b>
                                    <hr>
                                    <p>Posizione del district</p>
                                </agm-info-window>
                            </agm-marker>
                        </ng-container>
                        <ng-container *ngIf="mode === 1 || mode === 2">
                            <ng-container
                                    *ngFor="let visit of visitsData">
                                <agm-marker
                                        *ngIf="visit.default_lat && visit.default_lng"
                                        [latitude]="visit.default_lat"
                                        [longitude]="visit.default_lng"
                                >
                                    <agm-info-window>
                                        <p *ngIf="visit.default_type === 'Partner'">Partner</p>
                                        <p *ngIf="visit.default_type === 'Potenziale'">Potenziale Partner</p>
                                        <b>{{visit.default_shop}}</b>
                                        <p>District</p>
                                        <b>{{visit.default_agent_name}}</b>
                                        <p>Data</p>
                                        <b>{{visit.default_datetime | amDateFormat: 'DD/MM/YYYY HH:mm'}}</b>
                                        <hr>
                                        <p>Posizione del partner</p>
                                    </agm-info-window>
                                </agm-marker>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </agm-map>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6">
        <app-master-card
                bodyClass="p-0"
                image="assets/img/flats/new.png"
                title="Visite"
        >
            <data-builder-table (onRowsChange)="setMapPins($event)" *ngIf="tableUrl"
                                [url]="tableUrl"></data-builder-table>
        </app-master-card>
        <div *isVendorDisabled="'Eolo'" class="card">
            <div class="card-header">
                <h4 class="mb-0" translate>Stops</h4>
            </div>
            <div class="card-body  table-responsive p-0">
                <table class="table table-sm mb-0 table-striped">
                    <thead>
                    <tr>
                        <th *ngIf="!isSelectedAgent" scope="col" translate>Agent</th>
                        <th scope="col" translate>Start stop</th>
                        <th scope="col" translate>End stop</th>
                        <th scope="col" translate>Duration</th>
                        <th class="text-center" scope="col" translate>Work time</th>
                        <th scope="col" translate>Period</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ngx-spinner
                            [fullScreen]="false"
                            bdColor="rgba(0, 0, 0, 0.8)"
                            color="#fff"
                            name="table-stops"
                            size="default"
                            type="square-jelly-box"
                    >
                        <p style="color: white">Caricamento...</p>
                    </ngx-spinner>
                    <tr *ngFor="let agentStop of agentStops">
                        <td *ngIf="!isSelectedAgent">{{agentStop.car.owner}}</td>
                        <td>{{agentStop.start_datetime|amDateFormat:localFormat}}</td>
                        <td>{{agentStop.end_datetime|amDateFormat:localFormat}}</td>
                        <td>{{agentStop.duration}}</td>
                        <td class="text-center  w-20">
                            <i *ngIf="agentStop.is_work_time==1" class="text-success fas fa-check"></i>
                            <i *ngIf="agentStop.is_work_time===0" class="text-danger fas fa-times"></i>
                        </td>
                        <td>
                            <span *ngIf="agentStop.period==lunchTime" translate>Lunch time</span>
                            <span *ngIf="agentStop.period==nightTime" translate>Night time</span>
                        </td>
                    </tr>
                    <tr *ngIf="agentStops?.length<=0">
                        <td class="text-muted" colspan="6" translate>List is empty</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="agentStops?.length>0" class="card-footer">
        <span><span translate>There are</span><b> {{ this.agentStopsPagination.totalCount }}</b>
            {{this.agentStopsPagination.totalCount <= 1 ? 'stop' : 'stops'|translate}}.</span>
                <ngb-pagination (pageChange)="loadAgentStops()"
                                *ngIf="agentStopsPagination.pageCount>1"
                                [(page)]="agentStopsPagination.currentPage"
                                [boundaryLinks]="true"
                                [collectionSize]="agentStopsPagination.totalCount"
                                [maxSize]="5"
                                [pageSize]="agentStopsPagination.perPage"
                                size="sm"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #fileTemplate let-modal>
    <div class="modal-header">
        <h4 class="modal-title clamp-title" translate>{{selectedVisit?.agent_name}}</h4>
        <button (click)="modalService.dismissAll()"
                aria-label="Close"
                class="close"
                type="button"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="list-group list-group-flush">
            <li *ngFor="let document of selectedVisit?.documents" class="list-group-item px-0">
                <a [href]="baseAwsUrl+'/'+document?.file_path"
                   target="_blank">{{document?.file_name}}</a></li>
            <li *ngIf="selectedVisit?.documents?.length<=0" class="list-group-item px-0" translate>No files added</li>
        </ul>
    </div>
</ng-template>
