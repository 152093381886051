<div class="modal-body">
    <form [formGroup]="racesFilesGroup" class="form-height" [ngBusy]="busy" (ngSubmit)="save()">
        <div class="col-12 form-group">
            <label translate> Title</label>
        </div>
        <div class="col-12 form-group">
            <input type="text" formControlName="title" name="title" class="form-control"/>
        </div>

        <div class="col-12">
            <label translate> Description</label>
        </div>
        <div class="col-12 form-group">
            <input type="text" formControlName="description" name="description" class="form-control"/>
        </div>

        <div class="col-12 form-group">
            <label for="pdfFile" translate>Upload file</label>
            <div class="custom-file" *ngIf="!submittedFileName">
                <input id="pdfFile" type="file" class="custom-file-input"
                       (change)="handleFileInput($event?.target?.files[0])" accept=".xlsx, .xls">
                <label class="custom-file-label" for="pdfFile" translate>
                    Upload file...
                </label>
            </div>
            <div *ngIf="submittedFileName">
                <span class="text-break">{{submittedFileName}}</span>
                <button class="btn btn-xs btn-warning float-right" type="button"
                        (click)="handleFileRemove()">
                    <i class="fa fa-times m-0 px-1"></i>
                </button>
            </div>
        </div>
        <div class="col-12 mt-4">
            <button type="submit" class="btn btn-success btn-block">
                <i class="fa fa-check-circle mr-2"></i> <span translate>Save</span>
            </button>
        </div>
    </form>
</div>

