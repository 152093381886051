import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '@services/dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';
import * as moment from 'moment';
import {ShopAccess} from '@app/pages/shop-access/shop-access.type';
import {DealerService} from '@services/dealer.service';
import {Subscription} from 'rxjs';
import {environment} from '@environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-master-dealer-dettaglio',
    templateUrl: './master-dealer-dettaglio.component.html',
    styleUrls: ['./master-dealer-dettaglio.component.scss']
})
export class MasterDealerDettaglioComponent implements OnInit {
    busy: Subscription;
    id: string;
    data: any;

    @ViewChild('msgModal') private msgModal;

    modalMessage: string = 'Aggiornamento completato con successo!';

    edit = {
        responsabile: false,
        referente: false,
        telefono: false,
        cellulare: false,
        email: false,
        formazione: false,
        padre_figlio: false,
        negozio: false,
        funzionale: false,
        sede: false,
        insegna: false,
        blacklist: false,
        brands: false,
        closing_days: false
    };
    loading = {
        responsabile: false,
        referente: false,
        telefono: false,
        cellulare: false,
        email: false,
        formazione: false,
        padre_figlio: false,
        negozio: false,
        funzionale: false,
        sede: false,
        insegna: false,
        blacklist: false,
        brands: false,
        closing_days: false
    };

    riepilogoPeriod = {
        from: moment().format('YYYY-MM-01'),
        to: moment().format('YYYY-MM-DD'),
    };
    paymentTypesPair = ShopAccess.paymentTypesPair;
    
    timeline = [];
    global: any;
    provinces: any;
    cities_with_most_visits: any;
    kpi: any;
    sales_agent: string;


    constructor(
        public route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private dashboardService: DashboardService,
        private dealerService: DealerService,
        public modalService: NgbModal,
        private router: Router
    ) {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            this.reload(); // reset and set based on new parameter this time
        });
    }

    get dealer() {
        if (this.data) {
            return this.data.dealer;
        }

        return {
            ragsociale: ''
        };
    }


    get info() {
        if (this.data) {
            return this.data.info;
        }

        return {
            is_shop: '1',
            functional: '0'
        };
    }

    get location() {
        if (this.data) {
            return this.data.location;
        }

        return {
            address: '',
            address_number: '',
            city: '',
            region: '',
            province: '',
            zip_code: '',
        };
    }

    get chartPeriod() {
        if (this.data) {
            return this.data.chartPeriod;
        }

        return {
            from: moment().subtract(12, 'months').format('YYYY-MM-01'),
            to: moment().format('YYYY-MM-DD'),
        };
    }

    affiliazione(id) {
        if (this.data) {
            if (id in this.data.affiliazioni) {
                return this.data.affiliazioni[id];
            }
        }

        return null;
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
    }

    reload() {
        this.spinner.show();

        this.busy = this.dashboardService.get(`/negozio-dettaglio?id=${this.id}`)
            .subscribe(data => {
                this.data = data.result;
                this.spinner.hide();
            }, (error) => {
               this.router.navigate(['/retailers/list']);
            });
    }

    sendReply(item: any) {
        this.spinner.show();

        this.dashboardService.post(`/visite-risposta`, {
            id_visita: item.id,
            testo: item.reply
        })
            .subscribe((data) => {
                this.dashboardService.get(`-detail/negozio-visite?id=${this.id}`)
                    .subscribe(data => {
                        this.timeline = data.result;
                        this.spinner.hide();
                    });
            });
    }

    saveDealerInfo(params: any, key: string) {

        this.loading[key] = true;
        this.dealerService.updateDealer(this.id, params)
            .subscribe((data) => {
                this.loading[key] = false;
                this.edit[key] = false;
                
                this.reload();
                if(key === 'sede' && params.location && ['Service', 'Master Service', 'Service Light'].includes(this.dealer.position)){
                    this.modalService.open(this.msgModal, {size: 'md'});
                }

            }, (error) => {
                this.loading[key] = false;
                this.edit[key] = false;
                this.reload();
            });
    }

    saveDealerJson(params: any, key: string) {
        params = JSON.stringify(params);

        this.loading[key] = true;
        this.dealerService.updateDealer(this.id, {json: params})
            .subscribe((data) => {
                this.loading[key] = false;
                this.edit[key] = false;
                this.reload();
            }, (error) => {
                this.loading[key] = false;
                this.edit[key] = false;
                this.reload();
            });
    }

    setAddress($event: any) {
        this.location.address = $event.address;
        this.location.address_number = $event.streetNumber === null ? 'snc' : $event.streetNumber;
        this.location.city = $event.city;
        this.location.zip_code = $event.postalCode;
    }

    protected readonly environment = environment;
}
