import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Races, RacesDealers, RacesFiles} from '@models/races';
import {RacesService} from '@services/races.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmOptionsService} from '@services/confirm-options.service';

@Component({
    selector: 'app-races-form',
    templateUrl: './races-form.component.html',
    styleUrls: ['./races-form.component.css']
})
export class RacesFormComponent implements OnInit {
    @Input() data: Races;
    @Input() isUploadFile = false;
    @Input() types = [];
    @Output() refresh = new EventEmitter<boolean>();
    @Input() files: RacesFiles;
    @Input() racesDealers: RacesDealers;
    formGroup: FormGroup;
    busy: any;
    @ViewChild('uploadRacesFiles') private uploadRacesFiles;
    @ViewChild('raceDealerTemplate') private raceDealerTemplate;
    uploadFileModal: NgbModalRef;
    dealerModal: NgbModalRef;
    submittedFileName: string;
    isNewType = false;
    races_files: any[] = [];
    races_dealers: any[] = [];
    deleteRaceFiles: ConfirmOptionsService;

    constructor(private service: RacesService,
                private modalService: NgbModal) {
        this.deleteRaceFiles = new ConfirmOptionsService({
            title: 'Vuoi cancellarla?',
            text: 'Non potrai tornare indietro',
            confirmButtonText: 'Si',
            cancelButtonText: 'Annulla'
        });

    }

    ngOnInit(): void {
        this.formGroup = new FormGroup({
        });
        if (this.isUploadFile) {
            this.formGroup.addControl('file', new FormControl('', Validators.required));

        } else if (!this.data) {
            this.formGroup.addControl('dealer_id', new FormControl('', Validators.required));
            this.formGroup.addControl('type', new FormControl('', Validators.required));
            this.formGroup.addControl('id_operator', new FormControl(this.data?.id_operator || '', Validators.required));
        } else {
            this.formGroup.addControl('type', new FormControl(this.data.type, Validators.required));
            this.formGroup.addControl('file', new FormControl('', Validators.required));
            this.formGroup.addControl('title', new FormControl('', Validators.required));
            this.formGroup.addControl('description', new FormControl('', Validators.required));

        }

        this.races_files = this.data.races_files;
        this.races_dealers = this.data.races_dealers;
    }

    setDealerId(dealerId: any) {
        this.formGroup.get('dealer_id').setValue(dealerId);
        //pozvati api rsda
    }

    save() {
        const races = new Races(this.formGroup.value);

        this.busy = this.service[this.isUploadFile ? 'uploadFormFile' : this.data ? 'putRaces' : 'postRaces']
        (races, this.data?.id).subscribe((response: Races) => {
            this.service.successAlert(this.data ? 'Changed!' : 'Updated!');
            this.formGroup.reset();
            this.isNewType = false;
            this.refresh.emit(true);
        }, err => {
            this.service.errorAlert(err.message);
            this.isNewType = false;
            this.refresh.emit(true);
        });
    }

    handleFileInput(file: File) {
        this.submittedFileName = file.name;
        this.formGroup.get('file').setValue(file);
    }

    handleFileRemove() {
        this.submittedFileName = null;
        this.formGroup.get('file').setValue('');
    }

    get fileName() {
        console.log(this.formGroup.get('file')?.value);
        return this.formGroup.get('file')?.value?.name;
    }
    showModal() {
        this.uploadFileModal = this.modalService.open(this.uploadRacesFiles, {size: 'md'});
    }
    showDealersModal() {
        this.dealerModal = this.modalService.open(this.raceDealerTemplate, {size: 'md'});

    }
    addFile(event: any) {
        this.races_files.push(event);
    }
    deleteRacesFiles(id: any) {
        this.service.deleteRacesFiles(id).subscribe((response) => {
            const index = this.races_files.findIndex(x => x.id === id);
            if (index >= 0) {
                this.races_files.splice(index, 1);
            }
        });
    }
    addDealer(event: any) {
        this.races_dealers.push(event);
    }
    deleteRacesDealers(id: any) {
        this.service.deleteRacesDealers(id).subscribe((response) => {
            const index = this.races_dealers.findIndex(x => x.id === id);
            if (index >= 0) {
                this.races_dealers.splice(index, 1);
            }
        });
    }

    setIdOperator(id: number) {
        this.formGroup.get('id_operator').setValue(id);
    }
}
