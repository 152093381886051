import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {GroupService} from '@services/group.service';
import Handsontable from 'handsontable';
import validators = Handsontable.validators;
import {Races, RacesFiles} from '@models/races';
import {RacesService} from '@services/races.service';

@Component({
    selector: 'app-races-files-modal',
    templateUrl: './races-files-modal.component.html',
    styleUrls: ['./races-files-modal.component.css']
})
export class RacesFilesModalComponent implements OnInit {
    racesFilesGroup: FormGroup;
    @Input() raceId: number;
    @Input() modal: NgbModalRef;
    @Input() data: Races;
    @Input() files: RacesFiles;
    @Output() addFile = new EventEmitter<RacesFiles>();
    @Output() refresh = new EventEmitter<boolean>();
    busy: any;
    submittedFileName: string;

    constructor(
        private groupService: GroupService,
        private service: RacesService,
    ) {
    }

    ngOnInit(): void {
        this.racesFilesGroup = new FormGroup({
            title: new FormControl('', [Validators.required]),
            description: new FormControl('', [Validators.required]),
            file: new FormControl('', [Validators.required]),
        });
    }

    save() {
        const racesFiles = new RacesFiles(this.racesFilesGroup.value);
        racesFiles.title = racesFiles.title.toString();
        racesFiles.description = racesFiles.description.toString();
        racesFiles.races_id = this.data.id;
        racesFiles.file = this.data.file;
        this.busy = this.service.postRacesFiles(racesFiles, racesFiles.races_id).subscribe((response: RacesFiles) => {
            this.addFile.emit(response);
            this.groupService.successAlert("Added File!");
            this.refresh.emit(true);
            this.dismissModal();
        }, error => {
            this.refresh.emit(true);
            this.dismissModal();
            this.groupService.errorAlert("Upload failed!");
        });

    }
    handleFileInput(file: File) {
        this.submittedFileName = file.name;
        this.racesFilesGroup.get('file').setValue(file);
    }
    handleFileRemove() {
        this.submittedFileName = null;
        this.racesFilesGroup.get('file').setValue('');
    }

    get fileName() {
        console.log(this.racesFilesGroup.get('file')?.value);
        return this.racesFilesGroup.get('file')?.value?.name;
    }

    dismissModal() {
        this.modal.dismiss();
    }
}
