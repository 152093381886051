import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {GroupService} from '@services/group.service';
import {GroupDealers} from '@models/group';
import {Races, RacesDealers} from '@models/races';
import {RacesService} from '@services/races.service';

@Component({
  selector: 'app-races-dealers',
  templateUrl: './races-dealers.component.html',
  styleUrls: ['./races-dealers.component.css']
})
export class RacesDealersComponent implements OnInit {

  raceDealersForm: FormGroup;
  @Input() raceId: number;
  @Input() data: Races;
  @Input() modal: NgbModalRef;
  @Output() addDealer = new EventEmitter<RacesDealers>();
  busy: any;

  constructor(
      private service: RacesService,
  ) {
  }

  ngOnInit(): void {
    this.raceDealersForm = new FormGroup({
      dealer_id: new FormControl('', Validators.required),
    });
  }

  dismissModal() {
    this.raceDealersForm.reset();
    this.modal.dismiss();
  }

  save() {
    const dealer = new RacesDealers(this.raceDealersForm.value);
    dealer.race_id = this.raceId;
    dealer.dealer_id = this.raceDealersForm.get('dealer_id').value;


    this.service.postRacesDealers(dealer).subscribe((response: RacesDealers) => {
      this.addDealer.emit(response);
      this.dismissModal();
      this.service.successAlert("Added Dealer!");
    }, err => {
      this.dismissModal();
      this.service.errorAlert("Dealer already exists in this group!");
    });
  }

  selectDealer($event) {
    this.raceDealersForm.get('dealer_id').setValue($event);
  }
}
