<div class="row" [ngBusy]="busy">
  <div class="col-12">
    <div class="card bg-gradient-info">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-3">
            <div class="info-text">
              <p><i class="fa fa-user-circle"></i> Ragione Sociale</p>
              <span class="lead">{{ dealer.ragsociale }}</span>
            </div>
            <div class="info-text">
              <p><i class="fa fa-stream"></i> Partita IVA</p>
              <span class="lead">{{ dealer.piva }}</span>
            </div>
            <div class="info-text">
              <p><i class="fa fa-address-card"></i> Titolare</p>
              <span *ngIf="!edit.responsabile" class="lead"
                >{{ dealer.nome }} {{ dealer.cognome }}
                <span *ngIf="dealer.visibile == 0"
                    ><i
                      (click)="edit.responsabile = true"
                      class="fas fa-pencil-alt cursor-hand float-right text-md"
                      *show="['master', 'agent', 'masteragent']"
                    ></i></span
              ></span>

              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.responsabile"
              ></i>

              <div
                *ngIf="edit.responsabile && !this.loading.responsabile"
                class="input-group input-group-sm"
              >
                <input
                  [(ngModel)]="dealer.nome"
                  class="form-control"
                  type="text"
                />
                <input
                  [(ngModel)]="dealer.cognome"
                  class="form-control"
                  type="text"
                />
                <div class="input-group-append">
                  <button
                    (click)="edit.responsabile = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerInfo(
                        { nome: dealer.nome, cognome: dealer.cognome },
                        'responsabile'
                      )
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="info-text">
              <p><i class="fa fa-address-card"></i> Referente Negozio</p>
              <span *ngIf="!edit.referente" class="lead"
                >{{ dealer?.json?.referente_nome || "-" }}
                {{ dealer?.json?.referente_cognome || "-" }}
                <span *ngIf="dealer.visible == 0">
                  <i
                    (click)="edit.referente = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                    *show="['master', 'agent', 'masteragent']"
                  ></i></span
              ></span>

              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.referente"
              ></i>

              <div
                *ngIf="edit.referente && !this.loading.referente"
                class="input-group input-group-sm"
              >
                <input
                  [(ngModel)]="dealer.json.referente_nome"
                  class="form-control"
                  type="text"
                />
                <input
                  [(ngModel)]="dealer.json.referente_cognome"
                  class="form-control"
                  type="text"
                />
                <div class="input-group-append">
                  <button
                    (click)="edit.referente = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerJson(
                        {
                          referente_nome: dealer.json.referente_nome,
                          referente_cognome: dealer.json.referente_cognome
                        },
                        'referente'
                      )
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="info-text">
              <p><i class="fa fa-clock"></i> Data Creazione</p>
              <span class="lead">{{
                dealer.created | amDateFormat : "DD/MM/YYYY"
              }}</span>
            </div>
            <div class="info-text">
              <p><i class="fa fa-calendar"></i> Operatori</p>
              <span class="lead" *ngIf="!edit.brands"
                >{{ info.brands }}
                <span *ngIf="dealer.visibile == 0">
                    <i
                      (click)="edit.brands = true"
                      class="fas fa-pencil-alt cursor-hand float-right text-md"
                      *show="['master', 'agent', 'masteragent']"
                    ></i></span
              ></span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.brands"
              ></i>

              <div
                *ngIf="edit.brands && !this.loading.brands"
                class="input-group input-group-sm"
              >
                <ng-select
                  [multiple]="true"
                  class="form-control-sm"
                  addTagText="Aggiungi altro operatore"
                  [addTag]="true"
                  [(ngModel)]="info.brands"
                >
                  <ng-option value="Nessun altro operatore"
                    >Nessun altro operatore</ng-option
                  >
                  <ng-option value="Vodafone">Vodafone</ng-option>
                  <ng-option value="WindTre">WindTre</ng-option>
                  <ng-option value="Tim">Tim</ng-option>
                  <ng-option value="ILIAD">ILIAD</ng-option>
                  <ng-option value="Tiscali">Tiscali</ng-option>
                  <ng-option value="Fastweb">Fastweb</ng-option>
                  <ng-option value="Linkem">Linkem</ng-option>
                  <ng-option value="Sky WiFi">Sky WiFi</ng-option>
                </ng-select>

                <div class="input-group-append">
                  <button
                    (click)="edit.brands = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="saveDealerInfo({ brands: info.brands }, 'brands')"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="info-text">
              <p><i class="fa fa-mobile"></i> Cellulare</p>
              <span class="lead" *ngIf="!edit.cellulare"
                >{{ dealer.cellulare }}
                <span *ngIf="dealer.visibile == 0">
                    <i
                      (click)="edit.cellulare = true"
                      class="fas fa-pencil-alt cursor-hand float-right text-md"
                      *show="['master', 'agent', 'masteragent']"
                    ></i></span
              ></span>

              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.cellulare"
              ></i>

              <div
                *ngIf="edit.cellulare && !this.loading.cellulare"
                class="input-group input-group-sm"
              >
                <input
                  [(ngModel)]="dealer.cellulare"
                  class="form-control"
                  type="text"
                />
                <div class="input-group-append">
                  <button
                    (click)="edit.cellulare = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerInfo(
                        { cellulare: dealer.cellulare },
                        'cellulare'
                      )
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="info-text">
              <p><i class="fa fa-phone-alt"></i> Telefono</p>
              <span class="lead" *ngIf="!edit.telefono"
                >{{ dealer.telefono }}
                <span *ngIf="dealer.visibile == 0"
                  ><i
                    (click)="edit.telefono = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                    *show="['master', 'agent', 'masteragent']"
                  ></i></span
              ></span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.telefono"
              ></i>

              <div
                *ngIf="edit.telefono && !this.loading.telefono"
                class="input-group input-group-sm"
              >
                <input
                  [(ngModel)]="dealer.telefono"
                  class="form-control"
                  type="text"
                />
                <div class="input-group-append">
                  <button
                    (click)="edit.telefono = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerInfo({ telefono: dealer.telefono }, 'telefono')
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="info-text">
              <p><i class="fa fa-envelope"></i> E-Mail</p>
              <span class="lead" *ngIf="!edit.email"
                >{{ dealer.email }}
                <span *ngIf="dealer.visibile == 0">
                    <i
                      (click)="edit.email = true"
                      class="fas fa-pencil-alt cursor-hand float-right text-md"
                      *show="['master', 'agent', 'masteragent']"
                    ></i></span
              ></span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.email"
              ></i>

              <div
                *ngIf="edit.email && !this.loading.email"
                class="input-group input-group-sm"
              >
                <input
                  [(ngModel)]="dealer.email"
                  class="form-control"
                  type="text"
                />
                <div class="input-group-append">
                  <button
                    (click)="edit.email = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="saveDealerInfo({ email: dealer.email }, 'email')"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="info-text">
              <p><i class="fa fa-map"></i> Sede Operativa</p>
              <span class="lead" *ngIf="!edit.sede"
                >{{ location.address }} {{ location.address_number }} -
                {{ location.city }} , {{ location.province }}
                <span *ngIf="dealer.visibile == 0">
                  <i
                    (click)="edit.sede = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                    *show="['master', 'agent', 'masteragent']"
                  ></i></span
              ></span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.sede"
              ></i>

              <div *ngIf="edit.sede && !this.loading.sede">
                <app-autocomplete-address
                  (setAddress)="setAddress($event)"
                  [small]="true"
                ></app-autocomplete-address>

                <div>
                  <span class="text-sm mr-1">Indirizzo:</span>
                  <span>{{ location.address }}</span>
                  <span class="text-sm mx-1">Civico:</span>
                  <span>{{ location.address_number }}</span>
                  <span class="text-sm mx-1">Comune:</span>
                  <span>{{ location.city }}</span>
                  <span class="text-sm mx-1">CAP:</span>
                  <span>{{ location.zip_code }}</span>
                </div>

                <button
                  (click)="edit.sede = false"
                  class="btn btn-light"
                  type="button"
                >
                  <i class="fas fa-times mr-0"></i>
                </button>
                <button
                  (click)="saveDealerInfo({ location: location }, 'sede')"
                  class="btn btn-light"
                  type="button"
                >
                  <i class="fas fa-check mr-0"></i>
                </button>
              </div>
            </div>
            <div *isVendorDisabled="'Eolo'" class="info-text">
              <p><i class="fa fa-envelope"></i> PEC</p>
              <span class="lead">{{ dealer.pec }}</span>
            </div>
            <div class="info-text">
              <p><i class="fa fa-envelope"></i> Insegna</p>
              <span class="lead" *ngIf="!edit.insegna"
                >{{ dealer.ragsociale_fatt || "-" }}

                <span *ngIf="dealer.visibile == 0"
                  ><i
                    (click)="edit.insegna = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                    *show="['master', 'agent', 'masteragent']"
                  ></i></span
              ></span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.insegna"
              ></i>

              <div
                *ngIf="edit.insegna && !this.loading.insegna"
                class="input-group input-group-sm"
              >
                <input
                  [(ngModel)]="dealer.ragsociale_fatt"
                  class="form-control"
                  type="text"
                />
                <div class="input-group-append">
                  <button
                    (click)="edit.insegna = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerInfo(
                        { ragsociale_fatt: dealer.ragsociale_fatt },
                        'insegna'
                      )
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="info-text">
              <p><i class="fa fa-calendar"></i> Giorni di chiusura</p>
              <span class="lead" *ngIf="!edit.closing_days"
                >{{ info.closing_days }}
                <span *ngIf="dealer.visibile == 0">
                  <i
                    (click)="edit.closing_days = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                    *show="['master', 'agent', 'masteragent']"
                  ></i></span
              ></span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.closing_days"
              ></i>

              <div
                *ngIf="edit.closing_days && !this.loading.closing_days"
                class="input-group input-group-sm"
              >
                <ng-select
                  [multiple]="true"
                  class="form-control-sm"
                  [(ngModel)]="info.closing_days"
                >
                  <ng-option value="Lunedì">Lunedì</ng-option>
                  <ng-option value="Martedì">Martedì</ng-option>
                  <ng-option value="Mercoledì">Mercoledì</ng-option>
                  <ng-option value="Giovedì">Giovedì</ng-option>
                  <ng-option value="Venerdì">Venerdì</ng-option>
                  <ng-option value="Sabato">Sabato</ng-option>
                  <ng-option value="Domenica">Domenica</ng-option>
                  <ng-option value="No chiusura">No chiusura</ng-option>
                </ng-select>

                <div class="input-group-append">
                  <button
                    (click)="edit.closing_days = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerInfo(
                        { closing_days: info.closing_days },
                        'closing_days'
                      )
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div *ngIf="affiliazione(1)" class="info-text">
              <p><i class="fa fa-arrow-circle-right"></i> District Manager</p>
              <span class="lead">{{ affiliazione(1).agente }}</span>
            </div>
            <div *ngIf="affiliazione(6)" class="info-text">
              <p>
                <i class="fa fa-arrow-circle-right"></i> Kena Mobile con
                {{ affiliazione(6).agente }}
              </p>
              <span class="lead"
                >{{ affiliazione(6).code }}
                <span class="float-right">{{
                  affiliazione(6).created | amDateFormat : "DD/MM/YYYY"
                }}</span></span
              >
            </div>
            <div *ngIf="affiliazione(11)" class="info-text">
              <p>
                <i class="fa fa-arrow-circle-right"></i> Tim con
                {{ affiliazione(11).agente }}
              </p>
              <span class="lead"
                >{{ affiliazione(11).code }}
                <span class="float-right">{{
                  affiliazione(11).created | amDateFormat : "DD/MM/YYYY"
                }}</span></span
              >
            </div>
            <div *ngIf="affiliazione(4)" class="info-text">
              <p>
                <i class="fa fa-arrow-circle-right"></i> Tiscali con
                {{ affiliazione(4).agente }}
              </p>
              <span class="lead"
                >{{ affiliazione(4).code }}
                <span class="float-right">{{
                  affiliazione(4).created | amDateFormat : "DD/MM/YYYY"
                }}</span></span
              >
            </div>
            <div *ngIf="affiliazione(9)" class="info-text">
              <p>
                <i class="fa fa-arrow-circle-right"></i> Eni gas e luce con
                {{ affiliazione(9).agente }}
              </p>
              <span class="lead"
                >{{ affiliazione(9).code }}
                <span class="float-right">{{
                  affiliazione(9).created | amDateFormat : "DD/MM/YYYY"
                }}</span></span
              >
            </div>
            <div *ngIf="dealer.pro && dealer.pro.isPro" class="info-text">
              <p><i class="fa fa-star"></i> Dealer Pro</p>
              <span class="lead">{{
                this.paymentTypesPair[data.pro.payment]
              }}</span>
            </div>
            <div class="info-text">
              <p><i class="fa fa-user"></i> Padre/Figlio</p>
              <span class="lead">{{ dealer.voce_fattura }}</span>
            </div>
            <div class="info-text">
              <p><i class="fa fa-life-ring"></i> Formazione</p>
              <span class="lead" *ngIf="!edit.formazione"
                >{{ dealer.consenso_fatture == 0 ? "No" : "Si" }}
                <span *ngIf="dealer.visibile == 0">
                  <i
                    (click)="edit.formazione = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                    *show="['master', 'agent', 'masteragent', 'operator']"
                  ></i
                ></span>
              </span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.formazione"
              ></i>

              <div
                *ngIf="edit.formazione && !this.loading.formazione"
                class="input-group input-group-sm"
              >
                <select
                  [(ngModel)]="dealer.consenso_fatture"
                  class="form-control"
                >
                  <option value="0">No</option>
                  <option value="1">Si</option>
                </select>
                <div class="input-group-append">
                  <button
                    (click)="edit.formazione = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerInfo(
                        { consenso_fatture: dealer.consenso_fatture },
                        'formazione'
                      )
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="info-text">
              <p><i class="fa fa-store"></i> Negozio/No Negozio</p>
              <span class="lead" *ngIf="!edit.negozio"
                >{{ info.is_shop == 0 ? "No Negozio" : "Negozio" }}
                <span *ngIf="dealer.visibile == 0">
                  <i
                    (click)="edit.negozio = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                    *show="['master', 'agent', 'masteragent']"
                  ></i></span
              ></span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.negozio"
              ></i>

              <div
                *ngIf="edit.negozio && !this.loading.negozio"
                class="input-group input-group-sm"
              >
                <select [(ngModel)]="info.is_shop" class="form-control">
                  <option value="0">No Negozio</option>
                  <option value="1">Negozio</option>
                </select>
                <div class="input-group-append">
                  <button
                    (click)="edit.negozio = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerInfo({ is_shop: info.is_shop }, 'negozio')
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="info-text">
              <p><i class="fa fa-wrench"></i> Partner Funzionale</p>
              <span class="lead" *ngIf="!edit.funzionale"
                >{{ info.functional == 0 ? "No" : "Si" }}
                <span *ngIf="dealer.visibile == 0">
                  <i
                    (click)="edit.funzionale = true"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                    *show="['master', 'agent', 'masteragent']"
                  ></i>
                </span>
              </span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.funzionale"
              ></i>

              <div
                *ngIf="edit.funzionale && !this.loading.funzionale"
                class="input-group input-group-sm"
              >
                <select [(ngModel)]="info.functional" class="form-control">
                  <option value="0">No</option>
                  <option value="1">Si</option>
                </select>
                <div class="input-group-append">
                  <button
                    (click)="edit.funzionale = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerInfo(
                        { functional: info.functional },
                        'funzionale'
                      )
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="info-text">
              <p><i class="fa fa-wrench"></i> Blacklist</p>
              <span class="lead" *ngIf="!edit.blacklist"
                >{{ dealer.cattivo_pagatore == 0 ? "No" : "Si" }}
                <span *ngIf="dealer.visibile == 0">
                  <i
                    (click)="edit.blacklist = true"
                    *show="['master']"
                    class="fas fa-pencil-alt cursor-hand float-right text-md"
                  ></i></span
              ></span>
              <i
                class="fas fa-circle-notch fa-spin"
                *ngIf="this.loading.blacklist"
              ></i>

              <div
                *ngIf="edit.blacklist && !this.loading.blacklist"
                class="input-group input-group-sm"
              >
                <select
                  [(ngModel)]="dealer.cattivo_pagatore"
                  class="form-control"
                >
                  <option value="0">No</option>
                  <option value="1">Si</option>
                </select>
                <div class="input-group-append">
                  <button
                    (click)="edit.blacklist = false"
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-times mr-0"></i>
                  </button>
                  <button
                    (click)="
                      saveDealerInfo(
                        { cattivo_pagatore: dealer.cattivo_pagatore },
                        'blacklist'
                      )
                    "
                    class="btn btn-light"
                    type="button"
                  >
                    <i class="fas fa-check mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="card bg-gradient-light">
              <div class="card-body p-1">
                <agm-map
                  *ngIf="data"
                  [latitude]="dealer.lat"
                  [longitude]="dealer.lon"
                  [style.height.px]="240"
                  [zoom]="13"
                  mapTypeId="roadmap"
                >
                  <agm-marker [latitude]="dealer.lat" [longitude]="dealer.lon">
                  </agm-marker>
                </agm-map>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer p-0">
        <ul
          #nav="ngbNav"
          [activeId]="1"
          class="nav nav-pills nav-fill nav-mg"
          ngbNav
        >
          <li *isVendorEnabled="['Kena Mobile']" [ngbNavItem]="1" ngbNavItem>
            <a ngbNavLink><i class="fa fa-chart-pie"></i> Kena Mobile</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Andamento Vendite</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      kenaChart.setUrl(
                        '-detail/negozio-chart-kena?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #kenaChart
                    type="line"
                    url="-detail/negozio-chart-kena?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3">
                  <app-autoload-keypoints
                    url="-detail/negozio-keypoints-kena?id={{ this.id }}"
                  ></app-autoload-keypoints>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="card bg-gradient-light">
                    <div class="card-header px-1">
                      <app-datepicker-range-popup
                        (onSelect)="
                          kenaRiepilogo.setUrl(
                            '-detail/negozio-riepilogo-kena?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          )
                        "
                        [from]="riepilogoPeriod.from"
                        [to]="riepilogoPeriod.to"
                      ></app-datepicker-range-popup>
                    </div>
                    <div class="card-body p-0">
                      <app-autoload-table-riepilogo
                        #kenaRiepilogo
                        url="-detail/negozio-riepilogo-kena?id={{ this.id }}"
                      ></app-autoload-table-riepilogo>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <app-autoload-table
                    [hideFooterDescription]="true"
                    [small]="true"
                    url="-detail/negozio-dettaglio-kena?id={{ this.id }}"
                  ></app-autoload-table>
                </div>
              </div>
            </ng-template>
          </li>
          <li *isVendorEnabled="['Eolo']" [ngbNavItem]="1" ngbNavItem>
            <a ngbNavLink><i class="fa fa-chart-pie"></i> Ordini</a>
            <ng-template ngbNavContent>
              <div class="card bg-dark">
                <div class="card-header">
                  <div class="card-title">
                    Filtro periodo per <b>Andamento Ordini</b>
                  </div>
                </div>
                <div class="card-body">
                  <app-datepicker-range-popup
                    (onSelect)="
                      eoloChart.setUrl(
                        '/partner/chart-sales?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                  ></app-datepicker-range-popup>
                </div>
              </div>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Andamento Ordini</p>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #eoloChart
                    brand="eolo"
                    type="line"
                    url="/partner/chart-sales?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="card bg-dark">
                    <div class="card-header">
                      <div class="card-title">
                        Filtro periodo per <b>Riepilogo</b> e
                        <b>Dettaglio Ordini</b>
                      </div>
                    </div>
                    <div class="card-body">
                      <app-datepicker-range-popup
                        (onSelect)="
                          eoloRiepilogo.setUrl(
                            '/partner/keypoints?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          );
                          eoloBuilderTable.setUrl(
                            'eolo/partner/sales?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          )
                        "
                        [from]="riepilogoPeriod.from"
                        [to]="riepilogoPeriod.to"
                      ></app-datepicker-range-popup>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <app-master-card
                    [height]="null"
                    image="assets/img/flats/folder.png"
                    title="Riepilogo"
                  >
                    <app-autoload-riepilogo
                      #eoloRiepilogo
                      brand="eolo"
                      urlRiepilogo="/partner/keypoints?id={{ this.id }}"
                    ></app-autoload-riepilogo>
                  </app-master-card>
                </div>
                <div class="col-12 col-lg-8">
                  <app-master-card
                    bodyClass="p-0"
                    image="assets/img/flats/ribbon.png"
                    title="Dettaglio Ordini"
                  >
                    <data-builder-table
                      #eoloBuilderTable
                      url="eolo/partner/sales?id={{ this.id }}"
                    ></data-builder-table>
                  </app-master-card>
                </div>
              </div>
            </ng-template>
          </li>
          <li *isVendorEnabled="['Tiscali Mobile']" [ngbNavItem]="2" ngbNavItem>
            <a ngbNavLink><i class="fa fa-chart-pie"></i> Tiscali Mobile</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Andamento Vendite</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      tiscaliMobileChart.setUrl(
                        '-detail/negozio-chart-tiscali-mobile?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #tiscaliMobileChart
                    type="line"
                    url="-detail/negozio-chart-tiscali-mobile?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3">
                  <app-autoload-keypoints
                    url="-detail/negozio-keypoints-tiscali-mobile?id={{
                      this.id
                    }}"
                  ></app-autoload-keypoints>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="card bg-gradient-light">
                    <div class="card-header px-1">
                      <app-datepicker-range-popup
                        (onSelect)="
                          tiscaliMobileRiepilogo.setUrl(
                            '-detail/negozio-riepilogo-tiscali-mobile?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          )
                        "
                        [from]="riepilogoPeriod.from"
                        [to]="riepilogoPeriod.to"
                      ></app-datepicker-range-popup>
                    </div>
                    <div class="card-body p-0">
                      <app-autoload-table-riepilogo
                        #tiscaliMobileRiepilogo
                        url="-detail/negozio-riepilogo-tiscali-mobile?id={{
                          this.id
                        }}"
                      ></app-autoload-table-riepilogo>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <app-autoload-table
                    [hideFooterDescription]="true"
                    [small]="true"
                    url="-detail/negozio-dettaglio-tiscali-mobile?id={{
                      this.id
                    }}"
                  ></app-autoload-table>
                </div>
              </div>
            </ng-template>
          </li>
          <li *isVendorEnabled="['Tiscali Fisso']" [ngbNavItem]="3" ngbNavItem>
            <a ngbNavLink><i class="fa fa-chart-pie"></i> Tiscali Fisso</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Andamento Vendite</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      tiscaliFissoChart.setUrl(
                        '-detail/negozio-chart-tiscali-fisso?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #tiscaliFissoChart
                    type="line"
                    url="-detail/negozio-chart-tiscali-fisso?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3">
                  <app-autoload-keypoints
                    url="-detail/negozio-keypoints-tiscali-fisso?id={{
                      this.id
                    }}"
                  ></app-autoload-keypoints>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="card bg-gradient-light">
                    <div class="card-header px-1">
                      <app-datepicker-range-popup
                        (onSelect)="
                          tiscaliFissoRiepilogo.setUrl(
                            '-detail/negozio-riepilogo-tiscali-fisso?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          )
                        "
                        [from]="riepilogoPeriod.from"
                        [to]="riepilogoPeriod.to"
                      ></app-datepicker-range-popup>
                    </div>
                    <div class="card-body p-0">
                      <app-autoload-table-riepilogo
                        #tiscaliFissoRiepilogo
                        url="-detail/negozio-riepilogo-tiscali-fisso?id={{
                          this.id
                        }}"
                      ></app-autoload-table-riepilogo>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <app-autoload-table
                    [hideFooterDescription]="true"
                    [small]="true"
                    url="-detail/negozio-dettaglio-tiscali-fisso?id={{
                      this.id
                    }}"
                  ></app-autoload-table>
                </div>
              </div>
            </ng-template>
          </li>
          <li *isVendorEnabled="['Tiscali LTE']" [ngbNavItem]="4" ngbNavItem>
            <a ngbNavLink><i class="fa fa-chart-pie"></i> Tiscali LTE</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Andamento Vendite</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      tiscaliLteChart.setUrl(
                        '-detail/negozio-chart-tiscali-lte?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #tiscaliLteChart
                    type="line"
                    url="-detail/negozio-chart-tiscali-lte?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3">
                  <app-autoload-keypoints
                    url="-detail/negozio-keypoints-tiscali-lte?id={{ this.id }}"
                  ></app-autoload-keypoints>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="card bg-gradient-light">
                    <div class="card-header px-1">
                      <app-datepicker-range-popup
                        (onSelect)="
                          tiscaliLteRiepilogo.setUrl(
                            '-detail/negozio-riepilogo-tiscali-lte?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          )
                        "
                        [from]="riepilogoPeriod.from"
                        [to]="riepilogoPeriod.to"
                      ></app-datepicker-range-popup>
                    </div>
                    <div class="card-body p-0">
                      <app-autoload-table-riepilogo
                        #tiscaliLteRiepilogo
                        url="-detail/negozio-riepilogo-tiscali-lte?id={{
                          this.id
                        }}"
                      ></app-autoload-table-riepilogo>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <app-autoload-table
                    [hideFooterDescription]="true"
                    [small]="true"
                    url="-detail/negozio-dettaglio-tiscali-lte?id={{ this.id }}"
                  ></app-autoload-table>
                </div>
              </div>
            </ng-template>
          </li>
          <li *isVendorEnabled="['Tim']" ngbNavItem>
            <a ngbNavLink><i class="fa fa-chart-pie"></i> Tim</a>
            <ng-template ngbNavContent></ng-template>
          </li>
          <li *isVendorEnabled="['Eni gas e luce']" [ngbNavItem]="6" ngbNavItem>
            <a ngbNavLink><i class="fa fa-chart-pie"></i> Eni gas e luce</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Andamento Vendite</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      eniChart.setUrl(
                        '-detail/negozio-chart-eni?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #eniChart
                    type="line"
                    url="-detail/negozio-chart-eni?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3">
                  <app-autoload-keypoints
                    url="-detail/negozio-keypoints-eni?id={{ this.id }}"
                  ></app-autoload-keypoints>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="card bg-gradient-light">
                    <div class="card-header px-1">
                      <app-datepicker-range-popup
                        (onSelect)="
                          eniRiepilogo.setUrl(
                            '-detail/negozio-riepilogo-eni?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          )
                        "
                        [from]="riepilogoPeriod.from"
                        [to]="riepilogoPeriod.to"
                      ></app-datepicker-range-popup>
                    </div>
                    <div class="card-body p-0">
                      <app-autoload-table-riepilogo
                        #eniRiepilogo
                        url="-detail/negozio-riepilogo-eni?id={{ this.id }}"
                      ></app-autoload-table-riepilogo>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <app-autoload-table
                    [hideFooterDescription]="true"
                    [small]="true"
                    url="-detail/negozio-dettaglio-eni?id={{ this.id }}"
                  ></app-autoload-table>
                </div>
              </div>
            </ng-template>
          </li>
          <li *isVendorDisabled="'Eolo'" [ngbNavItem]="8" ngbNavItem>
            <a ngbNavLink><i class="fas fa-coins"></i> Fatturato</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Fatturato</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      fatturatoChart.setUrl(
                        '-detail/negozio-chart-fatturato?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #fatturatoChart
                    type="line"
                    url="-detail/negozio-chart-fatturato?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <div class="card bg-gradient-light">
                    <div class="card-header px-1">
                      <app-datepicker-range-popup
                        (onSelect)="
                          fatturatoRiepilogo.setUrl(
                            '-detail/negozio-riepilogo-fatturato?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          );
                          fatturatoDettaglioRiepilogo.setUrl(
                            '-detail/negozio-riepilogo-fatturato-media?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          )
                        "
                        [from]="riepilogoPeriod.from"
                        [to]="riepilogoPeriod.to"
                      ></app-datepicker-range-popup>
                    </div>
                    <div class="card-body p-0">
                      <div class="row no-gutters">
                        <div class="col-12 col-lg-6">
                          <app-autoload-table-riepilogo
                            #fatturatoRiepilogo
                            url="-detail/negozio-riepilogo-fatturato?id={{
                              this.id
                            }}"
                          ></app-autoload-table-riepilogo>
                        </div>
                        <div class="col-12 col-lg-6">
                          <app-autoload-table-riepilogo
                            #fatturatoDettaglioRiepilogo
                            url="-detail/negozio-riepilogo-fatturato-media?id={{
                              this.id
                            }}"
                          ></app-autoload-table-riepilogo>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <app-autoload-table
                    [hideFooterDescription]="true"
                    [small]="true"
                    url="-detail/negozio-dettaglio-fatturato?id={{ this.id }}"
                  ></app-autoload-table>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="8" ngbNavItem>
            <a ngbNavLink><i class="fas fa-coins"></i> Provvigioni</a>
            <ng-template ngbNavContent>
              <div class="card bg-dark">
                <div class="card-header">
                  <div class="card-title">
                    Filtro periodo per <b>Provvigioni</b>
                  </div>
                </div>
                <div class="card-body">
                  <app-datepicker-range-popup
                    (onSelect)="
                      fatturatoChart.setUrl(
                        '/partner/chart-revenues?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                  ></app-datepicker-range-popup>
                </div>
              </div>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Provvigioni</p>
                  <small class="d-block"
                    >Esclusi dal conteggio i gettoni di Impresa Flex e Advance e
                    le variazioni</small
                  >
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #fatturatoChart
                    brand="eolo"
                    type="line"
                    url="/partner/chart-revenues?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div class="row">
<!--                <div class="col-12">-->
<!--                  <div class="card bg-dark">-->
<!--                    <div class="card-header">-->
<!--                      <div class="card-title">-->
<!--                        Filtro periodo per <b>Riepilogo Provvigioni</b> e-->
<!--                        <b>Dettaglio Provvigioni</b>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="card-body">-->
<!--                      <app-datepicker-range-popup-->
<!--                        (onSelect)="-->
<!--                          fatturatoRiepilogo.setUrl(-->
<!--                            '-detail/negozio-riepilogo-fatturato?id=' +-->
<!--                              this.id +-->
<!--                              '&from=' +-->
<!--                              $event.from +-->
<!--                              '&to=' +-->
<!--                              $event.to-->
<!--                          );-->
<!--                          fatturatoDettaglioRiepilogo.setUrl(-->
<!--                            '-detail/negozio-riepilogo-fatturato-media?id=' +-->
<!--                              this.id +-->
<!--                              '&from=' +-->
<!--                              $event.from +-->
<!--                              '&to=' +-->
<!--                              $event.to-->
<!--                          )-->
<!--                        "-->
<!--                        [from]="riepilogoPeriod.from"-->
<!--                        [to]="riepilogoPeriod.to"-->
<!--                      ></app-datepicker-range-popup>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <div class="col-12" *show="['master']">

                    <div class="card bg-gradient-light">
                        <div class="card-header">
                            <p class="lead d-inline-block">Riepilogo Provvigioni</p>
                        </div>
                        <div class="card-body p-0">
                            <data-builder-table url="races/revenues?id={{ this.id }}"></data-builder-table>
                        </div>
                    </div>
                </div>
<!--                <div class="col-12 col-lg-6">-->
<!--                  <div class="card bg-gradient-light">-->
<!--                    <div class="card-header">-->
<!--                      <p class="lead d-inline-block">Riepilogo Provvigioni</p>-->
<!--                    </div>-->
<!--                    <div class="card-body p-0">-->
<!--                      <div class="row no-gutters">-->
<!--                        <div class="col-12 col-md-6">-->
<!--                          <app-autoload-table-riepilogo-->
<!--                            #fatturatoRiepilogo-->
<!--                            url="-detail/negozio-riepilogo-fatturato?id={{-->
<!--                              this.id-->
<!--                            }}"-->
<!--                          ></app-autoload-table-riepilogo>-->
<!--                        </div>-->
<!--                        <div class="col-12 col-md-6">-->
<!--                          <app-autoload-table-riepilogo-->
<!--                            #fatturatoDettaglioRiepilogo-->
<!--                            url="-detail/negozio-riepilogo-fatturato-media?id={{-->
<!--                              this.id-->
<!--                            }}"-->
<!--                          ></app-autoload-table-riepilogo>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-12 col-lg-6">-->
<!--                  <app-autoload-table-->
<!--                    [hideFooterDescription]="true"-->
<!--                    [small]="true"-->
<!--                    url="-detail/negozio-dettaglio-fatturato?id={{ this.id }}"-->
<!--                  ></app-autoload-table>-->
<!--                </div>-->
              </div>
            </ng-template>
          </li>
          <li *isVendorDisabled="'Eolo'" [ngbNavItem]="9" ngbNavItem>
            <a ngbNavLink><i class="fas fa-shopping-cart"></i> Negozio</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Totale Speso</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      negozioChart.setUrl(
                        '-detail/negozio-chart-ordini?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #negozioChart
                    type="line"
                    url="-detail/negozio-chart-ordini?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-lg-3">
                  <app-autoload-keypoints
                    url="-detail/negozio-keypoints-ordini?id={{ this.id }}"
                  ></app-autoload-keypoints>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="card bg-gradient-light">
                    <div class="card-header px-1">
                      <app-datepicker-range-popup
                        (onSelect)="
                          ordiniRiepilogo.setUrl(
                            '-detail/negozio-riepilogo-ordini?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          )
                        "
                        [from]="riepilogoPeriod.from"
                        [to]="riepilogoPeriod.to"
                      ></app-datepicker-range-popup>
                    </div>
                    <div class="card-body p-0">
                      <app-autoload-table-riepilogo
                        #ordiniRiepilogo
                        url="-detail/negozio-riepilogo-ordini?id={{ this.id }}"
                      ></app-autoload-table-riepilogo>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <app-autoload-table
                    [hideFooterDescription]="true"
                    [small]="true"
                    url="-detail/negozio-dettaglio-ordini?id={{ this.id }}"
                  ></app-autoload-table>
                </div>
              </div>
            </ng-template>
          </li>
          <li *isVendorDisabled="'Eolo'" [ngbNavItem]="10" ngbNavItem>
            <a ngbNavLink><i class="fas fa-sms"></i> SMS</a>
            <ng-template ngbNavContent></ng-template>
          </li>
          <li *ngIf="false" [ngbNavItem]="11" ngbNavItem>
            <a ngbNavLink><i class="fas fa-question-circle"></i> Tickets</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Tickets</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      ticketChart.setUrl(
                        '-detail/negozio-chart-tickets?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #ticketChart
                    type="line"
                    url="-detail/negozio-chart-tickets?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="card bg-gradient-light">
                    <div class="card-header px-1">
                      <app-datepicker-range-popup
                        (onSelect)="
                          ticketsRiepilogo.setUrl(
                            '-detail/negozio-riepilogo-tickets?id=' +
                              this.id +
                              '&from=' +
                              $event.from +
                              '&to=' +
                              $event.to
                          )
                        "
                        [from]="riepilogoPeriod.from"
                        [to]="riepilogoPeriod.to"
                      ></app-datepicker-range-popup>
                    </div>
                    <div class="card-body p-0">
                      <app-autoload-table-riepilogo
                        #ticketsRiepilogo
                        url="-detail/negozio-riepilogo-tickets?id={{ this.id }}"
                      ></app-autoload-table-riepilogo>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-8">
                  <app-autoload-table
                    [hideFooterDescription]="true"
                    [small]="true"
                    url="-detail/negozio-dettaglio-tickets?id={{ this.id }}"
                  ></app-autoload-table>
                </div>
              </div>
            </ng-template>
          </li>
          <li *ngIf="false" [ngbNavItem]="12" ngbNavItem>
            <a ngbNavLink><i class="fas fa-map-marker-alt"></i> Visite</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Visite ricevute</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      ticketChart.setUrl(
                        '-detail/negozio-chart-visite?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #ticketChart
                    type="line"
                    url="-detail/negozio-chart-visite?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div style="display: inline-block; width: 100%; overflow-y: auto">
                <div class="timeline timeline-horizontal">
                  <div *ngFor="let item of timeline" class="timeline-item">
                    <div class="timeline-badge bg-gradient-success">
                      <i class="fas fa-map-marked-alt"></i>
                    </div>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <h4 class="timeline-title">{{ item.agent }}</h4>
                        <p>
                          <small class="text-muted"
                            ><i class="fas fa-clock"></i> {{ item.ora }}
                          </small>
                        </p>
                      </div>
                      <div class="timeline-body">
                        <div [innerHTML]="item.note_visita | safeHtml"></div>

                        <div
                          *ngFor="let item of item.json_data | keyvalue"
                          class="bg-gradient-white rounded p-2 mb-2 border"
                        >
                          <div class="text-md font-weight-bold">
                            {{ item.key }}
                          </div>
                          <div>{{ item.value }}</div>
                        </div>

                        <div
                          *ngIf="item.json_data && item.json_data.length === 0"
                          class="text-muted font-italic"
                        >
                          Non ci sono note
                        </div>

                        <p *ngIf="item.affiliato === '1'" class="mt-2">
                          <i class="fas fa-check-circle text-success"></i>
                          Affiliato
                        </p>
                        <p *ngIf="item.attivita_chiusa === '1'" class="mt-2">
                          <i class="fas fa-check-circle text-danger"></i>
                          Attivita chiusa
                        </p>

                        <ng-container *isVendorEnabled="['Kena Mobile']">
                          <hr />
                          <ul>
                            <li>
                              Plafond segnalato:
                              {{ item?.actual_plafond | currency : "€ " }}
                            </li>
                            <li>Sim in magazzino: {{ item?.warehouse_sim }}</li>
                          </ul>
                        </ng-container>

                        <ng-container *ngIf="item?.documents.length > 0">
                          <hr />
                          <p class="text-italic" translate>Foto del negozio:</p>
                          <ul>
                            <li
                              *ngFor="
                                let document of item?.documents;
                                let i = index
                              "
                            >
                              <app-documents-modal
                                #showModal
                                [image]="true"
                              ></app-documents-modal>
                              <div
                                (click)="
                                  showModal.showDocument(
                                    environment.baseUrl +
                                      '/visite/get-picture?visit_id=' +
                                      item.id +
                                      '&id=' +
                                      document.id,
                                    true
                                  )
                                "
                                class="link"
                              >
                                Foto {{ i + 1 }}
                              </div>
                            </li>
                          </ul>
                        </ng-container>

                        <ng-container *ngFor="let reply of item.replies">
                          <hr />
                          <div
                            [ngClass]="{
                              'text-right': reply.direction === 'right'
                            }"
                          >
                            <p class="lead font-italic">
                              {{ reply.author_name }}
                            </p>
                            <p>{{ reply.note }}</p>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="item.showReply">
                          <hr />
                          <textarea
                            [(ngModel)]="item.reply"
                            class="form-control"
                          ></textarea>
                          <button
                            (click)="sendReply(item)"
                            class="btn btn-sm btn-success mt-2"
                          >
                            Invia
                          </button>
                        </ng-container>
                      </div>
                      <div class="timeline-footer">
                        <hr />
                        <a
                          (click)="item.showReply = !item.showReply"
                          class="float-right font-italic link"
                          ><i class="fa fa-reply"></i> Rispondi</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li *ngIf="false" [ngbNavItem]="14" ngbNavItem>
            <a ngbNavLink><i class="fas fa-envelope"></i> E-Mail</a>
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">E-Mail</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      emailChart.setUrl(
                        '-detail/negozio-chart-email?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #emailChart
                    type="line"
                    url="-detail/negozio-chart-email?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <app-autoload-table
                    [hideFooterDescription]="true"
                    [small]="true"
                    url="-detail/negozio-dettaglio-email?id={{ this.id }}"
                  ></app-autoload-table>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="15" ngbNavItem>
            <a ngbNavLink
              ><i class="far fa-comments"></i>
              <span translate> Reports & Visite</span></a
            >
            <ng-template ngbNavContent>
              <div class="card bg-gradient-light">
                <div class="card-header">
                  <p class="lead d-inline-block">Visite ricevute</p>
                  <app-datepicker-range-popup
                    (onSelect)="
                      ticketChart.setUrl(
                        '-detail/negozio-chart-visite?id=' +
                          this.id +
                          '&from=' +
                          $event.from +
                          '&to=' +
                          $event.to
                      )
                    "
                    [from]="chartPeriod.from"
                    [to]="chartPeriod.to"
                    class="float-right"
                  ></app-datepicker-range-popup>
                </div>
                <div class="card-body p-1">
                  <app-autoload-chartjs
                    #ticketChart
                    type="line"
                    url="-detail/negozio-chart-visite?id={{ this.id }}"
                  ></app-autoload-chartjs>
                </div>
              </div>
              <app-report-timeline [dealerId]="id"> </app-report-timeline>
            </ng-template>
          </li>
          <li [ngbNavItem]="16" ngbNavItem>
            <a ngbNavLink
              ><i class="fas fa-exchange-alt"></i>
              <span translate> Log Cambiamenti</span></a
            >
            <ng-template ngbNavContent>
              <app-master-card
                bodyClass="p-0"
                image="assets/img/flats/new.png"
                title="Lista Cambiamenti"
              >
                <data-builder-table
                  url="dealers/logs?id={{ this.id }}"
                ></data-builder-table>
              </app-master-card>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-12"></div>
  <div class="col-12">
    <div [ngbNavOutlet]="nav"></div>
  </div>

  <!-- modale -->
  <ng-template #msgModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title clamp-title">Attenzione!!!</h4>
      <button
        (click)="modalService.dismissAll()"
        type="button"
        class="close"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <p>
        Ricordati di inviare la richiesta di modifica anche ad installatori:
        <a href="mailto:installatori-backoffice@eolo.it"
          >installatori-backoffice@eolo.it</a
        >
      </p>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-info"
        (click)="modalService.dismissAll()"
      >
        Chiudi
      </button>
    </div>
  </ng-template>
  <!--  -->
</div>
