<app-master-header
        [ngBusy]="busy"
        cardClass="bg-gradient-info"
        image="assets/img/flats/destination.png"
        title="Gestione Visite"
>
    <div class="row align-items-end">
        <div class="col-lg-4 col-12">
            <label translate>Seleziona periodo</label>
            <input
                    (selected)="setSelectedDateRange($event.start, $event.end)"
                    [(ngModel)]="filterForm.dateRange"
                    class="form-control"
                    daterangepicker
                    type="text"
            />
        </div>
    </div>
</app-master-header>

<div class="card">
    <div class="card-body">
        <div *ngIf="global" class="row">
            <div *ngIf="defaultView" class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/online-store.png"/>
                    <span class="title">{{ global.today_visits }}</span>
                    <span class="description"> Visite di oggi </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/calendar_2.png"/>
                    <span class="title">{{ global.period_visits }}</span>
                    <span class="description"> Visite nel periodo </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/percentage.png"/>
                    <span class="title">{{ global.average_period_visits }}</span>
                    <span class="description"> Media visite </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/settings_2.png"/>
                    <span class="title">{{ global.period_our_retailers }}</span>
                    <span class="description"> Visite uniche</span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/new.png"/>
                    <span class="title">{{ global.period_other_retailers }}</span>
                    <span class="description"> Visite uniche altri pdv </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/internet.png"/>
                    <span class="title">{{ global.total_shops }}</span>
                    <span class="description" translate> Negozi totali </span>
                </div>
            </div>
        </div>

        <div *ngIf="global" class="row mt-4 d-lg-flex d-none">
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/map.png"/>
                    <span class="title">{{ global.period_cities }}</span>
                    <span class="description"> Comuni visitati </span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/placeholder.png"/>
                    <span class="title">{{ global.cities_with_shops }}</span>
                    <span class="description" translate> Comuni con negozi</span>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="icon-block">
                    <img class="img-fluid" src="/assets/img/flats/global-network.png"/>
                    <span class="title">{{ global.covered_cities }}</span>
                    <span class="description">Comuni totali</span>
                </div>
            </div>

            <div class="col-12 col-md-4 col-xl-2">
                <div class="d-flex">
                    <i  style="font-size: 52px;" class="fa fa-circle text-success" aria-hidden="true"></i>
                    <div class="icon-block d-flex flex-column">
                        <span class="title ml-2">{{ global.cities_green }}</span>
                        <span class="description ml-2" translate
                        >Comuni Verdi Visitati</span
                        >
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="d-flex">
                    <i  style="font-size: 52px;" class="fa fa-circle text-warning" aria-hidden="true"></i>
                    <div class="icon-block d-flex flex-column">
                        <span class="title ml-2">{{ global.cities_yellow }}</span>
                        <span class="description ml-2" translate
                        >Comuni Gialli Visitati</span
                        >
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-xl-2">
                <div class="d-flex">
                    <i  style="font-size: 52px;" class="fa fa-circle text-danger" aria-hidden="true"></i>
                    <div class="icon-block d-flex flex-column">
                        <span class="title ml-2">{{ global.cities_red }}</span>
                        <span class="description ml-2" translate
                        >Comuni Rossi Visitati</span
                        >
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ng-template #visitFormModal class="bg-white" let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Nuova Visita</h4>
        <button
                (click)="modal.dismiss('Cross click')"
                aria-label="Close"
                class="close"
                type="button"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-spinner *ngIf="!visitFormReady" status="loading"></app-spinner>
        <form *ngIf="visitFormReady" [formGroup]="visitForm">
            <div *ngIf="visitFormMode !== 'unknown'" class="form-group">
                <label>Attività chiusa</label>
                <ng-select class="form-control-sm" formControlName="closed_activity">
                    <ng-option value="No">No</ng-option>
                    <ng-option value="Sì">Sì</ng-option>
                </ng-select>
            </div>

            <ng-container *ngIf="visitFormDataReady">
                <ng-container *ngIf="visitFormMode !== 'unknown'">
                    <div class="text-lg">Anagrafica Dealer</div>
                    <div class="bg-light border rounded-lg p-2">
                        <div class="row" formGroupName="dealerAdditional">
                            <div class="form-group col-12 col-md-6">
                                <label>Nome</label>
                                <input class="form-control form-control-sm" formControlName="name" type="text"/>
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label>Cognome</label>
                                <input class="form-control form-control-sm" formControlName="surname" type="text"/>
                            </div>
                            <div class="form-group col-12 col-md-4">
                                <label>Telefono</label>
                                <input class="form-control form-control-sm" formControlName="phone" type="text"/>
                            </div>
                            <div class="form-group col-12 col-md-4">
                                <label>Cellulare</label>
                                <input class="form-control form-control-sm" formControlName="mobile" type="text"/>
                            </div>
                            <div class="form-group col-12 col-md-4">
                                <label>Email</label>
                                <input class="form-control form-control-sm" formControlName="email" type="text"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label>Posizione</label>
                                <app-autocomplete-address (setAddress)="setAddress($event)"
                                                          [small]="true"></app-autocomplete-address>

                                <div>
                                    <span class="text-sm text-muted mr-1">Indirizzo:</span>
                                    <span>{{visitForm.controls.address.value}}</span>
                                    <span class="text-sm text-muted mx-1">Civico:</span>
                                    <span>{{visitForm.controls.address_number.value}}</span>
                                    <span class="text-sm text-muted mx-1">Comune:</span>
                                    <span>{{visitForm.controls.city_name.value}}</span>
                                    <span class="text-sm text-muted mx-1">CAP:</span>
                                    <span>{{visitForm.controls.cap.value}}</span>
                                </div>
                            </div>
                            <ng-container *ngIf="showCap">
                                <div class="form-group col-12">
                                    <small class="text-danger">Non abbiamo trovato il CAP con l'indirizzo selezionato,
                                        inseriscilo manualmente</small>
                                    <label>CAP</label>
                                    <input class="form-control form-control-sm" formControlName="cap"/>
                                    <val-errors controlName="cap">
                                        <ng-template valError="pattern">
                                            <span translate>Il CAP non è corretto</span>
                                        </ng-template>
                                    </val-errors>
                                </div>
                            </ng-container>
                        </div>
                        <div class="row" formGroupName="dealerAdditional">
                            <div class="form-group col-12">
                                <label>Giorni di chiusura</label>
                                <ng-select [multiple]="true" class="form-control-sm" formControlName="closing_days">
                                    <ng-option value="Lunedì">Lunedì</ng-option>
                                    <ng-option value="Martedì">Martedì</ng-option>
                                    <ng-option value="Mercoledì">Mercoledì</ng-option>
                                    <ng-option value="Giovedì">Giovedì</ng-option>
                                    <ng-option value="Venerdì">Venerdì</ng-option>
                                    <ng-option value="Sabato">Sabato</ng-option>
                                    <ng-option value="Domenica">Domenica</ng-option>
                                    <ng-option value="No chiusura">No chiusura</ng-option>
                                </ng-select>
                            </div>
                            <div class="form-group col-12">
                                <label>Operatori</label>
                                <ng-select [multiple]="true" class="form-control-sm"
                                           addTagText="Aggiungi altro operatore"
                                           [addTag]="true"
                                           formControlName="brands">
                                    <ng-option value="Nessun altro operatore">Nessun altro operatore</ng-option>
                                    <ng-option value="Vodafone">Vodafone</ng-option>
                                    <ng-option value="WindTre">WindTre</ng-option>
                                    <ng-option value="Tim">Tim</ng-option>
                                    <ng-option value="ILIAD">ILIAD</ng-option>
                                    <ng-option value="Tiscali">Tiscali</ng-option>
                                    <ng-option value="Fastweb">Fastweb</ng-option>
                                    <ng-option value="Linkem">Linkem</ng-option>
                                    <ng-option value="Sky WiFi">Sky WiFi</ng-option>
                                </ng-select>
                            </div>
                            <div class="form-group col-12">
                                <label>Materiale esposto</label>
                                <ng-select [multiple]="true" class="form-control-sm" formControlName="advertising">
                                    <ng-option value="Espositore da banco">Espositore da banco</ng-option>
                                    <ng-option value="Bandiera a Goccia">Bandiera a Goccia</ng-option>
                                    <ng-option value="Vetrofania Porta">Vetrofania Porta</ng-option>
                                    <ng-option value="Locandina">Locandina</ng-option>
                                    <ng-option value="Planslip">Planslip</ng-option>
                                    <ng-option value="No Materiale">No Materiale</ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="visitFormMode === 'unknown'">
                    <div class="text-lg">Negozio</div>
                    <div class="bg-light border rounded-lg p-2">
                        <div class="row">
                            <div class="form-group col-12 col-md-6">
                                <label>Ragione Sociale</label>
                                <input class="form-control form-control-sm" formControlName="shop" type="text"/>
                            </div>

                            <div class="form-group col-12">
                                <label>Posizione</label>
                                <app-autocomplete-address (setAddress)="setAddress($event)"
                                                          [small]="true"></app-autocomplete-address>
                                <div>
                                    <span class="text-sm text-muted mr-1">Indirizzo:</span>
                                    <span>{{visitForm.controls.address.value}}</span>
                                    <span class="text-sm text-muted mx-1">Civico:</span>
                                    <span>{{visitForm.controls.address_number.value}}</span>
                                    <span class="text-sm text-muted mx-1">Comune:</span>
                                    <span>{{visitForm.controls.city_name.value}}</span>
                                    <span class="text-sm text-muted mx-1">CAP:</span>
                                    <span>{{visitForm.controls.cap.value}}</span>
                                </div>
                            </div>
                            <ng-container *ngIf="showCap">
                                <div class="form-group col-12">
                                    <small class="text-danger">Non abbiamo trovato il CAP con l'indirizzo selezionato,
                                        inseriscilo manualmente</small>
                                    <label>CAP</label>
                                    <input class="form-control form-control-sm" formControlName="cap"/>
                                    <val-errors controlName="cap">
                                        <ng-template valError="pattern">
                                            <span translate>Il CAP non è corretto</span>
                                        </ng-template>
                                    </val-errors>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>


                <div *ngIf="visitFormMode !== 'unknown'" class="mt-4" formGroupName="additional">
                    <ng-container *ngFor="let item of visitFormStructure">            
                        <div class="form-group">
                            <label>{{item.label}}</label>
                            <ng-container [ngSwitch]="item.type">
                                <ng-container *ngSwitchCase="'text'">
                                    <input type="text"
                                           [formControlName]="item.name"
                                           class="form-control form-control-sm w-100"
                                    />
                                    <val-errors [controlName]="item.name"></val-errors>
                                </ng-container>
                                <ng-container *ngSwitchCase="'textarea'">
                                    <textarea
                                            [formControlName]="item.name"
                                            class="form-control w-100"
                                            rows="3"
                                    ></textarea>
                                    <val-errors [controlName]="item.name"></val-errors>
                                </ng-container>
                                <ng-container *ngSwitchCase="'select'">
                                    <ng-select [formControlName]="item.name" class="form-control-sm">
                                        <ng-option *ngFor="let option of item.options"
                                                   [value]="option.value">{{ option.label }}</ng-option>
                                    </ng-select>
                                </ng-container>
                                <ng-container *ngSwitchCase="'multiselect'">
                                    <ng-select [formControlName]="item.name" [multiple]="true" class="form-control-sm">
                                        <ng-option *ngFor="let option of item.options"
                                                   [value]="option.value">{{ option.label }}</ng-option>
                                    </ng-select>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="visitFormMode === 'unknown'" class="mt-4" formGroupName="additional">
                    <ng-container *ngFor="let item of visitFormStructureProspect">            
                        <div class="form-group">
                            <label>{{item.label}}</label>
                            <ng-container [ngSwitch]="item.type">
                                <ng-container *ngSwitchCase="'text'">
                                    <input type="text"
                                           [formControlName]="item.name"
                                           class="form-control form-control-sm w-100"
                                    />
                                    <val-errors [controlName]="item.name"></val-errors>
                                </ng-container>
                                <ng-container *ngSwitchCase="'textarea'">
                                    <textarea
                                            [formControlName]="item.name"
                                            class="form-control w-100"
                                            rows="3"
                                    ></textarea>
                                    <val-errors [controlName]="item.name"></val-errors>
                                </ng-container>
                                <ng-container *ngSwitchCase="'select'">
                                    <ng-select [formControlName]="item.name" class="form-control-sm">
                                        <ng-option *ngFor="let option of item.options"
                                                   [value]="option.value">{{ option.label }}</ng-option>
                                    </ng-select>
                                </ng-container>
                                <ng-container *ngSwitchCase="'multiselect'">
                                    <ng-select [formControlName]="item.name" [multiple]="true" class="form-control-sm">
                                        <ng-option *ngFor="let option of item.options"
                                                   [value]="option.value">{{ option.label }}</ng-option>
                                    </ng-select>
                                </ng-container>
                                <ng-container *ngSwitchCase="'checkbox'">
                                    <div *ngFor="let option of item.options"  class="form-check">
                                        <input type="checkbox" class="form-check-input" [formControlName]="item.name" [value]="option.value"/>
                                        <label class="form-check-label">{{ option.label }}</label>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

                <div class="text-lg">Foto</div>
                <div class="bg-light border rounded-lg p-2">
                    <div *ngFor="let document of visitForm?.controls?.documents?.controls; let i=index;"
                         class="mt-2">
                        <div [formGroup]="document">
                            <div class="form-group">
                                <label translate>File (<span (click)="removeDocument(visitForm, i)"
                                                             class="cursor-hand text-sm text-danger link">Rimuovi</span>)</label>
                                <div *ngIf="!document?.get('file_name')?.value" class="custom-file">
                                    <input (change)="handleFileInput($event?.target?.files[0], document)"
                                           class="custom-file-input"
                                           type="file">
                                    <label class="custom-file-label" for="file">
                                        Carica file...
                                    </label>
                                </div>
                                <div *ngIf="document?.get('file_name').value">
                                    <span class="text-break">{{document?.get('file_name')?.value}}</span>
                                    <button (click)="handleFileRemove(document)"
                                            class="btn btn-xs btn-warning float-right"
                                            type="button">
                                        <i class="fa fa-times m-0 px-1"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button (click)="addDocument(visitForm)" class="btn btn-info btn-sm" type="button">
                        <i class="fa fa-plus"></i> Aggiungi
                    </button>
                </div>
            </ng-container>

            <div class="mt-3">
                <button
                        *ngIf="!savingVisit"
                        (click)="saveVisit()"
                        [disabled]="!this.visitForm.valid"
                        class="btn btn-success btn-block"
                >
                    <i class="fa fa-check-circle mr-2"></i> Registra Visita
                </button>

                <button
                        *ngIf="savingVisit"
                        [disabled]="savingVisit"
                        class="btn btn-info btn-block"
                >
                    <i class="fa fa-comment-dots mr-2"></i> Visita in registrazione...
                </button>

            </div>
        </form>
    </div>
</ng-template>

<div class="row">
    <div class="col-12 col-xl-8">
        <div *ngIf="visiteProgrammate && visiteProgrammate.rows.length === 0" class="alert alert-info">
            <i class="fas fa-info-circle mr-2"></i>
            Non ci sono visite programmate per oggi. Se le vuoi aggiungere vai al <a
                [routerLink]="['/visite/calendario']">calendario</a>.
        </div>

        <div *ngIf="visiteProgrammate && visiteProgrammate.rows.length > 0" class="mb-3">
            <app-table
                    (onTrigger)="triggerProgrammata($event)"
                    [columns]="visiteProgrammate.columns"
                    [hideFooter]="true"
                    [rows]="visiteProgrammate.rows"
                    [small]="true"
                    title="Visite programmate per oggi"
            >
            </app-table>
        </div>

        <app-master-card
                bodyClass="p-0"
                image="assets/img/flats/map.png"
                title="Lista Dealer"
        >
            <app-table
                    (onTrigger)="trigger($event)"
                    [columns]="table.columns"
                    [hideFooterDescription]="true"
                    [rows]="table.rows"
                    [small]="true"
            >
                <p class="mt-3 ml-3">
                    Hai visitato un negozio non ancora affiliato?
                    <button (click)="triggerCustom()" class="btn btn-sm btn-success ml-3">
                        Segna la visita qui
                    </button>
                </p>
                <hr/>
            </app-table>
        </app-master-card>
    </div>
    <div class="col-12 col-xl-4">
        <h1 class="display-6 mb-0 mb-2">Timeline</h1>
        <div *ngIf="timeline.length === 0">
            <div class="alert alert-info">
                <i class="fas fa-info-circle mr-2"></i>
                Nessuna visita registrata, prova a cambiare periodo.
            </div>
        </div>
        <div class="timeline" style="max-height: 700px; overflow-y: scroll">
            <ng-container *ngFor="let visits of timeline">
                <div class="time-label lead">
                    <span class="bg-gradient-success">{{ visits.periodo }}</span>
                </div>
                <div *ngFor="let item of visits.visite">
                    <i class="fas fa-sim-card bg-gradient-info"></i>
                    <div class="timeline-item bg-gradient-light elevation-1">
                    
            <span class="time" style="display: flex; flex-direction: row-reverse; align-items: center;">

                <button type="button" class="close text-danger ml-3" aria-label="Close"
                [swal]="deleteVisitOption" *ngIf="isEdit"
                (confirm)="deleteTimelineVisit(item.id)"
                >
                    <span aria-hidden="true">&times;</span>
                </button> 
                {{ item.ora }}<i class="far fa-clock mr-1"></i>
            </span>
                        <h3 class="timeline-header">
                            <a
                                    *ngIf="item.id_dealer"
                                    [routerLink]="['/dealer', item.id_dealer]"
                            >{{ item.negozio }}</a
                            >
                            <span *ngIf="!item.id_dealer">{{ item.negozio }}</span>

                            <small *ngIf="item.id_dealer" class="d-block">Partner già affiliato</small>
                            <small *ngIf="!item.id_dealer" class="d-block">Potenziale partner</small>
                        </h3>
                       
                        <div class="timeline-body">
                            <p class="lead font-italic"></p>

                            <div [innerHTML]="item.note_visita | safeHtml"> </div>

                            <div *ngFor="let item of item.json_data | keyvalue"
                                 class="bg-gradient-white rounded p-2 mb-2 border">
                                <div class="text-md font-weight-bold">{{item.key}}</div>
                                <div>{{item.value}}</div>
                            </div>

                            <div *ngIf="item.json_data && item.json_data.length === 0" class="text-muted font-italic">
                                Non ci sono note
                            </div>

                            <button
                                (click)="showModalQuestions(item.id)" 
                                class="btn btn-primary btn-block"
                            >
                                Informazioni complete del questionario
                            </button>
                            
                            
                            <p *ngIf="item.affiliato === '1'" class="mt-2"><i
                                    class="fas fa-check-circle text-success"></i>
                                Affiliato</p>
                            <p *ngIf="item.attivita_chiusa === '1'" class="mt-2"><i
                                    class="fas fa-check-circle text-danger"></i>
                                Attivita chiusa</p>

                            <ng-container *isVendorEnabled="['Kena Mobile']">
                                <hr>
                                <ul>
                                    <li>Plafond segnalato: {{item?.actual_plafond | currency: '€ '}}</li>
                                    <li>Sim in magazzino: {{item?.warehouse_sim}}</li>
                                </ul>
                            </ng-container>

                            <ng-container *ngIf="item?.documents.length > 0">
                                <hr>
                                <p class="text-italic" translate>Foto del negozio:</p>
                                <ul>
                                    <li *ngFor="let document of item?.documents; let i = index;">
                                        <a [href]="baseAwsUrl+'/'+document?.file_path"
                                           target="_blank">Foto {{i + 1}}</a>
                                    </li>
                                </ul>
                            </ng-container>


                            <ng-container *ngFor="let reply of item.replies">
                                <hr/>
                                <div [ngClass]="{ 'text-right': reply.direction === 'right' }">
                                    <p class="text-muted text-md font-italic">{{ reply.author_name }}<span
                                            class="float-right text-sm">{{ reply.created_at | amFromUnix | amDateFormat: 'DD-MM-YYYY H:mm' }}</span>
                                    </p>
                                    <p>{{ reply.note }}</p>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="item.showReply">
                                <hr/>
                                <textarea
                                        [(ngModel)]="item.reply"
                                        class="form-control"
                                ></textarea>
                                <button
                                        (click)="sendReply(item)"
                                        class="btn btn-sm btn-success mt-2"
                                >
                                    Invia
                                </button>
                            </ng-container>
                        </div>
                        <div class="timeline-footer">
                            <em>{{ item.citta }}, {{ item.provincia }}</em>

                            <a
                                    (click)="item.showReply = !item.showReply"
                                    class="float-right font-italic link"
                            ><i class="fa fa-reply"></i> Rispondi</a
                            >
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- modale -->
    <ng-template #questionsModal let-modal>

        <div class="modal-header">
            <h4 class="modal-title clamp-title">
                Informazioni complete del questionario
            </h4>
            <button (click)="modalService.dismissAll()"
                    type="button"
                    class="close"
                    aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <app-spinner *ngIf="!dealerData" status="loading"></app-spinner>

        <div class="modal-body" *ngIf="dealerData">
            <!-- Anagrafica -->
            <div class="card my-3" *ngIf="dealerData.type === 'dealer'">
              <div class="card-header bg-info">
                <h6 class=" mb-0 font-weight-bold text-uppercase">Anagrafica Dealer</h6>
              </div>
                <div class="card-body">
                    <dl class="row mb-0">
                        <div class="col-md-6">
                            <dl class="row mb-0">
                                <dt class="col-sm-5 pr-0">Ragione Sociale:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.dealerInfo.company}}</dd>
                                <dt class="col-sm-5 pr-0">Nome:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.dealerInfo.nome}}</dd>
                                
                                <dt class="col-sm-5 pr-0">Cognome:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.dealerInfo.cognome}}</dd>
            
                                <dt class="col-sm-5 pr-0">Attività chiusa:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.is_activity_closed ? 'Si' : 'No'}}</dd>

                                <dt class="col-sm-5 pr-0">Posizione:</dt>
                                <dd class="col-sm-7 pl-0">
                                  <b>Indirizzo:</b> {{dealerData.dealerInfo.indirizzo}} <br>
                                  <b>Civico:</b> {{dealerData.dealerInfo.civico}} <br>
                                  <b>Comune:</b> {{dealerData.dealerInfo.localita}} <br>
                                  <b>CAP:</b> {{dealerData.dealerInfo.cap}}
                                </dd>
                                <dt class="col-sm-5 pr-0">Operatori:</dt>
                                <dd class="col-sm-7 pl-0">
                                    <ul class="p-0">
                                        <li *ngFor="let brand of dealerData.dealerInfo.brands">
                                            {{brand}}
                                        </li> 
                                    </ul> 
                                </dd>
                            </dl>
                        </div>
                        <div class="col-md-6">
                            <dl class="row mb-0">
                                <dt class="col-sm-5 px-0">Telefono:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.dealerInfo.telefono}}</dd>
            
                                <dt class="col-sm-5 px-0">Cellulare:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.dealerInfo.cellulare}}</dd>
            
                                <dt class="col-sm-5 px-0">Email:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.dealerInfo.email}}</dd>

                                <dt class="col-sm-5 px-0">Giorni di chiusura:</dt>
                                <dd class="col-sm-7 pl-0">
                                    <ul class="p-0">
                                        <li *ngFor="let day of dealerData.dealerInfo.closing_days">
                                            {{day}}
                                        </li>
                                    </ul>
                                </dd>
                                <dt class="col-sm-5 px-0">Materiale esposto:</dt>
                                <dd class="col-sm-7 pl-0">
                                    <ul class="p-0">
                                        <li *ngFor="let ad of dealerData.dealerInfo.material_exposed">
                                            {{ad}}
                                        </li>
                                    </ul>
                                <dd>
                            </dl>
                        </div>
                    </dl>
                </div>
            </div>
            <div class="card my-3" *ngIf="dealerData.type === 'prospect'">
              <div class="card-header bg-info">
                <h6 class=" mb-0 font-weight-bold text-uppercase">Anagrafica Dealer</h6>
              </div>
                <div class="card-body">
                    <dl class="row mb-0">
                        <div class="col-md-6">
                            <dl class="row mb-0">
                                <dt class="col-sm-5 pr-0">Ragione Sociale:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.prospectInfo.company}}</dd>

                                <dt class="col-sm-5 pr-0">Attività chiusa:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.is_activity_closed ? 'Si' : 'No'}}</dd>
                            </dl>
                        </div>
                        <div class="col-md-6">
                            <dl class="row mb-0">
                                <dt class="col-sm-5 px-0">Comune:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.prospectInfo.localita}}</dd>

                                <dt class="col-sm-5 px-0">Provincia:</dt>
                                <dd class="col-sm-7 pl-0">{{dealerData.prospectInfo.provincia}}</dd>
                            </dl>
                        </div>
                    </dl>
                </div>
            </div>

            <!-- Domande e Risposte -->
            <div class="card">
              <div class="card-header bg-info">
                <h6 class="mb-0 font-weight-bold text-uppercase">Questionario</h6>
              </div>
              <div class="card-body">
                <div class="mb-3" *ngFor="let item of dealerData.json_data | keyvalue">
                  <h6 class="font-weight-bold">{{item.key}}</h6>
                  <p>{{item.value}}</p>
                </div>
              </div>
            </div>
          </div>
          

<!--        <div class="modal-footer">-->
<!--            <button type="button" class="btn btn-info"  (click)="downloadQuestionExcel()">Scarica questionario</button>-->
<!--        </div>-->

    </ng-template>
    <!--  -->

</div>