import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {DocumentiCategoria, Documento,} from '@models/documenti';
import {DocumentsService} from '@app/services/documents.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ConfirmOptionsService} from '@app/services/confirm-options.service';
import {VendorService} from '@app/services/vendor.service';
import {DataBuilderModalService} from '@services/data-builder-modal.service';
import {environment} from '@environments/environment';

@Component({
    selector: 'app-documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent implements OnInit {
    documenti: Documento[] = [];
    filtro: string = null;
    busy: Subscription;

    disableFileInput: boolean;
    selectedCategory: DocumentiCategoria;
    selectedDocument: Documento;
    categoryModalTitle: string;
    documentModalTitle: string;
    categories: DocumentiCategoria[] = [];
    categoryFormModal: NgbModalRef;
    documentFormModal: NgbModalRef;
    busyCategoryFormModal: Subscription;
    busyDocumentFormModal: Subscription;
    categoryForm: FormGroup;
    documentForm: FormGroup;
    fileName: string;
    currentCategory: DocumentiCategoria;
    @ViewChild('categoryFormTemplate') private categoryFormTemplate;
    @ViewChild('documentFormTemplate') private documentFormTemplate;

    constructor(
        public service: DocumentsService,
        public vendorService: VendorService,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        public dbm: DataBuilderModalService
    ) {
    }

    get categoryConfirmDeleteOptions() {
        return new ConfirmOptionsService({
            title: 'Elimina questa categoria',
            text: 'Sei sicuro di voler rimuovere questo categoria?',
        });
    }

    get documentConfirmDeleteOptions() {
        return new ConfirmOptionsService({
            title: 'Elimina questo documento',
            text: 'Sei sicuro di voler rimuovere questo documento?',
        });
    }

    get documentSendOptions() {
        return new ConfirmOptionsService({
            title: 'Invia documento',
            text: 'Inserisci la mail a cui inviare il documento',
            input: 'text',
            icon: 'info',
            confirmButtonText: 'Invia',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-default',
            }
        });
    }

    get currentCategories() {
        if (this.currentCategory) {
            return this.currentCategory.subcategories;
        }

        return this.categories.filter(
            (item: DocumentiCategoria) => item.id_padre === null
        );
    }

    get breadcrumbs() {
        let crumbs = [];

        if (this.currentCategory) {
            let catIteration = this.currentCategory.id;
            while (catIteration) {
                let catSearch = this.categories.find((cat: DocumentiCategoria) => cat.id.toString() === catIteration.toString());

                crumbs.push(catSearch);

                if (catSearch.id_padre) {
                    catIteration = catSearch.id_padre;
                } else {
                    catIteration = null;
                }
            }
        }

        return crumbs.reverse();
    }

    ngOnInit() {
        this.disableFileInput = false;

        this.busy = this.service.getCategories().subscribe((data: any) => {
            this.categories = new DocumentiCategoria().fromArray(data);
        });

        this.categoryForm = this.formBuilder.group({
            id_padre: [null, []],
            id_operatore: [null, []],
            nome: [null, [Validators.required]],
        });

        this.documentForm = this.formBuilder.group({
            nome: [null, [Validators.required]],
            descrizione: [null, [Validators.required]],
            file: [null, [Validators.required]],
        });

    }

    showCreateDocumentModal(category: DocumentiCategoria) {
        this.documentForm.controls.file.setValidators(Validators.required);
        this.documentForm.controls.file.updateValueAndValidity();
        this.selectedDocument = new Documento({id_categoria: category.id});
        this.documentModalTitle = `Aggiungi documento alla categoria ${category.nome}`;
        this.documentFormModal = this.modalService.open(this.documentFormTemplate, {
            size: 'lg',
        });
        this.documentFormModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showCreateCategoryModal() {
        this.selectedCategory = new DocumentiCategoria();
        this.categoryModalTitle = 'Nuova categoria';
        this.categoryFormModal = this.modalService.open(this.categoryFormTemplate, {
            size: 'lg',
        });
        this.categoryFormModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    saveCategory(category: DocumentiCategoria) {
        const formData = new FormData();
        const properties = ['nome', 'id_operatore'];
        properties.forEach((prop) => {
            if (category && category[prop]) {
                formData.append(prop, category[prop]);
            }
        });

        if (this.currentCategory) {
            formData.append('id_padre', this.currentCategory.id.toString());
        }

        let isNew = category.id ? false : true;
        let request = isNew
            ? this.service.saveCategory(formData)
            : this.service.updateCategory(category.id, formData);

        this.busyCategoryFormModal = request.subscribe(
            (data: any) => {
                let savedCategory = new DocumentiCategoria(data);
                savedCategory.isNew = isNew;

                this.updateLocalStateCategory(savedCategory);
                this.categoryFormModal.close();
                this.toastr.success(
                    `Categoria ${isNew ? 'creata' : 'aggiornata'} con successo`,
                    'Notifica'
                );
            },
            (err: any) =>
                this.toastr.error(
                    `Si è verificato un errore durante la ${
                        isNew ? 'creazione' : 'aggiornamento'
                    } della categoria`,
                    'Errore'
                )
        );
    }

    saveDocument(document: Documento) {
        const formData = new FormData();
        const properties = ['id_categoria', 'nome', 'descrizione', 'file'];
        properties.forEach(prop => {
            if (document && document[prop]) {
                formData.append(prop, document[prop]);
            }
        });

        let isNew = document.id ? false : true;
        let request = isNew
            ? this.service.saveDocument(formData)
            : this.service.updateDocument(document.id, formData);

        this.busyDocumentFormModal = request.subscribe((data: any) => {
            let savedDocument = new Documento(data);
            savedDocument.isNew = isNew;

            this.updateLocalStateWithDocument(savedDocument);
            this.documentFormModal.close();
        });
    }

    deleteLocalStateDocument(document: Documento) {
        let categoryIndex = this.categories.findIndex(function(o) {
            return o.id === document.id_categoria;
        });

        if (categoryIndex !== -1) {
            let documentIndex = this.categories[categoryIndex].documents.findIndex(
                function(o) {
                    return o.id === document.id;
                }
            );

            if (documentIndex !== -1) {
                this.categories[categoryIndex].documents_count--;
                this.categories[categoryIndex].documents.splice(documentIndex, 1);
            }
        }
    }

    updateLocalStateWithDocument(document: Documento) {
        for (let i in this.categories) {
            if (this.categories[i].id == document.id_categoria) {
                if (document.isNew) {
                    let hadNoVideos = this.categories[i].documents_count == 0;
                    let hadLoadedVideos =
                        this.categories[i].documents_count > 0 &&
                        this.categories[i].documents.length > 0;
                    if (hadNoVideos || hadLoadedVideos) {
                        this.categories[i].documents.unshift(document);
                    }
                    this.categories[i].documents_count++;
                } else {
                    for (let j in this.categories[i].documents) {
                        if (this.categories[i].documents[j].id == document.id) {
                            this.categories[i].documents[j] = document;
                            return;
                        }
                    }
                }
                return;
            }
        }
    }

    updateLocalStateCategory(category: DocumentiCategoria) {
        for (let i in this.categories) {
            if (this.categories[i].id == category.id) {
                this.categories[i] = category;
                return;
            }
        }
        let newCategory = this.categories.find((c) => {
            return c.id !== category.id;
        });
        if (newCategory || this.categories.length === 0) {
            this.categories.push(category);
        }
    }

    deleteLocalStateCategory(category: DocumentiCategoria) {
        let index = this.categories.findIndex(function(o) {
            return o.id === category.id;
        });
        if (index !== -1) {
            this.categories.splice(index, 1);
        }
    }

    showUpdateDocumentModal(document: Documento) {
        this.documentForm.controls.file.clearValidators();
        this.documentForm.controls.file.updateValueAndValidity();
        this.selectedDocument = new Documento(document);
        this.documentModalTitle = `Aggiorna il documento ${document.nome}`;
        this.documentFormModal = this.modalService.open(this.documentFormTemplate, {
            size: 'lg',
        });
        this.documentFormModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    showUpdateCategoryModal(category: DocumentiCategoria) {
        this.selectedCategory = new DocumentiCategoria(category);
        this.categoryModalTitle = `Aggiorna la categoria ${category.nome}`;
        this.categoryFormModal = this.modalService.open(this.categoryFormTemplate, {
            size: 'lg',
        });
        this.categoryFormModal.result.then((res) => {
        }).catch((res) => {
        });
    }

    deleteCategory(category: DocumentiCategoria) {
        let request = this.service.deleteCategory(category.id);

        this.busyCategoryFormModal = request.subscribe(
            (data: any) => {
                if (data === null) {
                    this.deleteLocalStateCategory(category);
                    this.toastr.success('Categoria eliminata con successo', 'Notifica');
                }
            },
            (err: any) =>
                this.toastr.error(
                    'Si è verificato un errore durante l\'eliminazione della categoria',
                    'Errore'
                )
        );
    }

    deleteDocument(document: Documento) {
        let request = this.service.deleteDocument(document.id);

        this.busyDocumentFormModal = request.subscribe(
            (data: any) => {
                if (data === null) {
                    this.deleteLocalStateDocument(document);
                    this.toastr.success('Documento eliminata con successo', 'Notifica');
                }
            },
            (err: any) =>
                this.toastr.error(
                    'Si è verificato un errore durante l\'eliminazione del documento',
                    'Errore'
                )
        );
    }

    sendDocument($event: any, document: Documento) {
        this.service.sendDocument(document.id, $event).subscribe((data: any) => {
            this.toastr.success('Documento inviato con successo', 'Notifica');
        }, (err: any) => {
            this.toastr.error('Si è verificato un errore durante l\'invio del documento', 'Errore');
        });
    }

    selectCategory(category: DocumentiCategoria) {
        console.log(category);
        this.categories.find((cat: DocumentiCategoria) => cat.id.toString() === category.id.toString());
        this.currentCategory = this.categories.find((cat: DocumentiCategoria) => cat.id.toString() === category.id.toString());
    }

    handleFileInput(files: FileList) {
        console.log('__________', files);
        if (files && files.item(0)) {
            const logoFile = files.item(0);
            // const isPdf = logoFile.type.includes("pdf") ? true : false;
            // if (!isPdf) {
            //   this.toastr.error("tipo di file non supportato", "Errore");
            //   return;
            // } else {
            this.fileName = logoFile.name;
            this.selectedDocument.file = logoFile;
            // }
        }
    }

    goBack() {
        if (this.currentCategory) {
            this.currentCategory = this.categories.find((item: DocumentiCategoria) => item.id === this.currentCategory.id_padre);
        } else {
            this.currentCategory = null;
        }
    }

    showLogs() {
        this.dbm.show(`documents/logs`, 'Logs');
    }

    goToLink(file_path: any) {
        window.open(`${file_path}`, '_blank');
    }
}
